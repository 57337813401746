import { useState, useEffect } from "react";
import Header from "../../components/header/header";
import CustomSpinner from "../../components/spinner/spinner";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hook";
import '../scss/addRole.scss'
import {
    createUserAsync,
} from "../../store/Slices/UserManagermentSlice";
import { useLocation } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import { createAdminAsync, resetAdmin } from "../../store/Slices/AdminManagementSlice";

const AdminAddUser = () => {
    const dispatch = useAppDispatch();
    const { message, statusCode, manageAdminloading } = useAppSelector((state): any => state?.AdminManagement);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [organization, setOrganization] = useState("");
    const [domain, setDomain] = useState("")
    const [userError, setUserError] = useState({
        name: "",
        email: "",
        organization: "",
        domain: "",
    });

    const validate = () => {
        const regex = /[!@#$%^&*()+=]/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const pattern = /[\d\W_]/g;

        let valid = true;
        let Validations = {
            name: "",
            email: "",
            organization: "",
            domain: "",
            password: "",
            confirm_password: "",
        };
        if (name == "") {
            Validations.name = "Please enter name";
            valid = false;
        }
        if (regex.test(domain)) {
            Validations.domain = "Special Symbols are not allowed";
            valid = false;
        }
        if (!emailRegex.test(email)) {
            Validations.email = "Please enter valid email";
            valid = false;
        }
        if (email == "") {
            Validations.email = "Please enter email";
            valid = false;
        }
        if (organization == "") {
            Validations.organization = "Please enter Company Name";
            valid = false;
        }
        if (domain == "") {
            Validations.domain = "Please enter Domain Name";
            valid = false;
        }
        if (domain.match(pattern)) {
            Validations.domain = "Please enter Valid Domain Name";
            valid = false;
        }
        setUserError(Validations);

        return valid;
    };


    const handleSubmit = async () => {
        if (validate()) {
            await dispatch(
                createAdminAsync({
                    name: name,
                    email: email,
                    company_name: organization,
                    organization: domain.trim()
                })
            );
        }
    };

    useEffect(() => {
        if (message.trim() == "Email sent to admin" && statusCode == 200) {
            dispatch(resetAdmin())
            navigate("/admin/user-management");
        }
    }, [message, statusCode])


    return (
        <>
            {manageAdminloading && (
                <div className="custom-spinner-container">
                    <CustomSpinner />
                </div>
            )}
            <div className="dashboard-main">
                <Container>
                    <div className="addUser">
                        <div className="my-5 ">
                            <div className="">
                                <h3>
                                    <b>Add User</b>
                                </h3>
                            </div>
                        </div>
                        <Row>
                            <Col lg={6}>
                                <div>
                                    <label className="text-dark mb-2 d-block">Name</label>
                                    <input
                                        className="border border-2 rounded mb-2 py-2 ps-2 w-100"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <p className="text-danger">{userError?.name}</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div>
                                    <label className="text-dark mb-2 d-block">Email</label>
                                    <input
                                        className="border border-2 rounded mb-2 py-2 ps-2 w-100"
                                        type="text"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <p className="text-danger">{userError?.email}</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <label className="text-dark mb-2 d-block">Add Domain Name</label>
                                <input
                                    className="border border-2 rounded mb-2 py-2 ps-2 w-100"
                                    type="text"
                                    placeholder="Enter Domain Name"
                                    value={domain}
                                    onChange={(e) => setDomain(e.target.value.trim())}
                                />
                                <p className="text-danger">{userError?.domain}</p>
                            </Col>
                            <Col lg={6}>
                                <label className="text-dark mb-2 d-block">Add Company Name</label>
                                <input
                                    className="border border-2 rounded mb-2 py-2 ps-2 w-100"
                                    type="text"
                                    placeholder="Enter Company Name"
                                    value={organization}
                                    onChange={(e) => setOrganization(e.target.value)}
                                />
                                <p className="text-danger">{userError?.organization}</p>
                            </Col>
                        </Row>

                        <div className="d-flex w-100 justify-content-end mt-3">
                            <button
                                className="custom-btn custom-primary me-3"
                                onClick={handleSubmit}
                            >
                                Create
                            </button>
                            <button
                                className="custom-btn custom-primary me-3"
                                onClick={() => navigate("/admin/user-management")}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default AdminAddUser;