import { useEffect, useState } from "react";
import "../pages/scss/login.scss";
import Logo from "../images/logo.png";
import Customlabel from "../components/label/label";
import CustomHeading from "../components/heading/heading";
import {
  PasswordIcon,
} from "../components/svgicon/svg";
import { changePasswordAsync } from "../store/Slices/changePasswordSlice";
import Form from "react-bootstrap/Form";
import CustomButton from "../components/button/button";
import CustomSubmitButton from '../components/button/btnSubmit'
import { useAppDispatch, useAppSelector } from "../hook";
import { useNavigate, useLocation, useSearchParams, useParams } from "react-router-dom";



const ChangePassword = () => {
  const domain = window.location.hostname
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const token: any = searchParams.get('token')
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [formErr, setFormErr] = useState({ password: "", confirmPassword: "" });
  const [confirmPassword, setConfirmPassword] = useState("");
  const { status, success } = useAppSelector((state: any) => state.auth);



  const validations = (val: any) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let valid = true
    const err = {
      confirmPassword: "",
      password: ""
    };
    if (!val.confirmPassword.length) {
      err.confirmPassword = "required field !";
      valid = false;
    }
    if (val.password.length > 0 && !regex.test(val.password)) {
      err.password = "This is not a valid password format!";
      valid = false;
    }
    if (val.confirmPassword.length > 0 && !regex.test(val.confirmPassword)) {
      err.confirmPassword = "This is not a valid password format!";
      valid = false;
    }
    if (!val.password) {
      err.password = "required field !";
      valid = false;
    }
    if (val.password && val.confirmPassword && val.password !== val.confirmPassword) {
      err.confirmPassword = "does not match !";
      valid = false;
    }
    setFormErr(err);
    if (err.password !== "" || err.confirmPassword !== "") {
      valid = false
    }
    return valid
  };

  const userData = {
    password: password ? password : "",
    confirmPassword: confirmPassword ? confirmPassword : "",
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    if (validations(userData)) {
      dispatch(
        changePasswordAsync({
          password: password,
          token: token,
          host: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0]
        })
      );
      setPassword("")
      setConfirmPassword("")
    }
  };


  return (
    <div className="main-login">
      <div className="login-inner">
        <div className="logo-outer">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="text-inner">
            <CustomHeading text="AI" level={1} />
            <CustomHeading text="At Your Service" level={3} />
            <CustomHeading text="Navigate. Simplify. Understand." level={4} />
          </div>
        </div>
        <div className="welcome-outer">
          <CustomHeading text="Change Password" level={3} />
          <Form onSubmit={(e) => handleClick(e)}>
            <div className="input-outer">
              <Customlabel text={"Password*"} />
              <div className="input-inner">
                <PasswordIcon />
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <p className="text-danger">{formErr?.password}</p>
            </div>
            <div className="input-outer">
              <Customlabel text={"Confirm Password*"} />
              <div className="input-inner">
                <PasswordIcon />

                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <p className="text-danger">{formErr?.confirmPassword}</p>
            </div>
            <div className="remeber-inner">
              <Form.Group
                className="forgot-inner"
                controlId="formBasicCheckbox"
              >
              </Form.Group>
            </div>
            <div className="submit-btn">
              <CustomSubmitButton
                typ="submit"
                classNam="btn custom-primary"
                text="Submit"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
