import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProfileApi, updateProfileApi, updateProfilePicApi } from "../service";

const initialState = {
  getProfileDetails: constants.NULL,
  postProfileDetails: constants.NULL,
  status: constants.IDLE,
  signErr: constants.NULL,
  manageAdminloading: constants.FALSE,
  error1: "",
  image: constants.NULL
};

export const getProfileAsync = createAsyncThunk(
  "profileData/getProfile",
  async () => {
    const response = await getProfileApi();
    return response
  }
);

export const postProfileAsync = createAsyncThunk(
  "postprofileData/postProfile",
  async (data: any) => {
    const response = await updateProfileApi(data);
    return response
  }
);

export const updateProfilePicAsync = createAsyncThunk(
  "put/updateProfilePic",
  async (data: any) => {
    const response = await updateProfilePicApi(data);
    return response
  }
);



export const profileDetailSlice = createSlice({
  name: "adminData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getProfileAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.getProfileDetails = action.payload;
      })
      .addCase(getProfileAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // Post Profile
      .addCase(postProfileAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(postProfileAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.postProfileDetails = action.payload;
      })
      .addCase(postProfileAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // update Profile Pic
      .addCase(updateProfilePicAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(updateProfilePicAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.image = action.payload;
      })
      .addCase(updateProfilePicAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
  },
});

export default profileDetailSlice.reducer;
