import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import Header from "../components/header/header"
import { SearchIcon, ArrowIcon } from "../components/svgicon/svg"
import { useNavigate, Link } from "react-router-dom"
import DataTable from "react-data-table-component"
import {
    Add, View
    , Edit, Remove
} from "../assets/icons"
import { useAppDispatch, useAppSelector } from "../hook"
import { deleteUserAsync, getUserDetailAsync } from "../store/Slices/UserManagermentSlice"
import { SelectContainer } from "react-select/dist/declarations/src/components/containers"
import CustomSpinner from "../components/spinner/spinner"
import ConfirmModal from "../components/confirmModal"
import { toast } from "react-toastify";


interface Permission {
    add: boolean,
    create: boolean,
    delete: boolean,
    edit: boolean,
    name: "string",
    view: boolean
}



const UserList = () => {
    const Admin = localStorage.getItem('is_admin')
    const { userList, manageAdminloading } = useAppSelector((state): any => state?.userManagement)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [permissions, setPermissions] = useState<Permission>()
    const [deleteId, setDeleteId] = useState(null)
    const [searchIn, setSearchIn] = useState("")
    const [userData, setUserData] = useState([])

    useEffect(() => {
        dispatch(getUserDetailAsync(searchIn))
    }, [searchIn])

    useEffect(() => {
        setUserData(userList)
    }, [userList])
    const columns = [{
        name: "UserName",
        selector: (row: any) => row?.username ? row?.username : "data unavailable"
    },
    {
        name: "Email",
        selector: (row: any) => row?.email ? row?.email : "data unavailable"
    },
    {
        name: "Role",
        selector: (row: any) => row?.role_name ? row?.role_name : "Unassigned"
    },
    {
        name: "",
        selector: (row: any) => <span>
            <button className="bg-transparent border border-0" onClick={() => Admin == 'true' ? navigate('/edit-user', {
                state: {
                    id: row?.id,
                    action: 'edit'
                }
            }) : permissions?.edit ? navigate('/edit-user', {
                state: {
                    id: row?.id,
                    action: 'edit'
                }
            }) : toast.error("User doesn't have permission")}>{Edit}</button>
            <button className="bg-transparent border border-0" onClick={() => Admin == 'true' ? navigate('/view-user', {
                state: {
                    id: row?.id,
                    action: 'view'
                }
            }) : permissions?.view ? navigate('/view-user', {
                state: {
                    id: row?.id,
                    action: 'view'
                }
            }) : toast.error("User doesn't have permission")}>{View}</button>
            <button className="bg-transparent border border-0" onClick={() => Admin == 'true' ? setDeleteId(row?.id) : permissions?.delete ? setDeleteId(row?.id) : toast.error("User doesn't have permission")}>{Remove}</button>
        </span>
    }
    ];

    const handleConfirm = async () => {
        await dispatch(deleteUserAsync(deleteId))
        await setDeleteId(null)
    }


    useEffect(() => {
        if (Admin && Admin !== "true") {
            JSON.parse(localStorage.getItem('roleAuth') || "")?.map((elem, ind) => {
                if (elem.name == "User Management") {
                    setPermissions(elem)
                }
            })
        }
    }, [Admin])

    return (
        <>
            {manageAdminloading && (
                <div className="custom-spinner-container">
                    <CustomSpinner />
                </div>
            )}
            <div className="dashboard-main">
                <ConfirmModal message="Are you sure you want to delete this user" show={deleteId ? true : false} cancel={() => setDeleteId(null)} confirm={handleConfirm} />
                <Container>
                    <div className="my-5 d-flex w-100 justify-content-between ">
                        <div className="">
                            <h3><b>User Management</b></h3>
                        </div>
                        <div className="w-50 d-flex align-items-center searchIn">
                            <SearchIcon />
                            <input className="w-100 " type="text" placeholder="search User" value={searchIn} onChange={(e) => setSearchIn(e.target.value)} />
                        </div>
                        {Admin == "true" ? <button
                            className="custom-btn custom-primary"
                            onClick={() => navigate('/add-user')}
                        >
                            Add User
                        </button> : permissions?.add ? <button
                            className="custom-btn custom-primary"
                            onClick={() => navigate('/add-user')}
                        >
                            Add User
                        </button> : null}
                    </div>
                    <div className="data-table custom-datatable">
                        {userData ?
                            <DataTable
                                columns={columns}
                                data={userData}
                                pagination
                            />
                            : "No data available"}
                    </div>
                    <button className="custom-btn custom-primary mx-auto" onClick={() => navigate('/profile-detail')}>Back</button>
                </Container >
            </div >
        </>
    )

}

export default UserList