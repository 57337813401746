import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postSimplified, highLight, ChatBotQuesAnsApi, createChatHistoryApi, getClauseApi, getOriginalClause, chatApiPerticular } from "../service";

const initialState = {
    simplifiedData: constants.NULL,
    quesAns: constants.NULL,
    status: constants.IDLE,
    isLoading: constants.FALSE,
    message: "",
    highLightData: constants.NULL,
    chatHistory: constants.NULL,
    clauseDetail: constants.NULL,
    linkData: constants.NULL,
    definitionData: constants.NULL
}


export const postSimplifiedAsync = createAsyncThunk("post/postSimplifiedData", async (data: any) => {
    const response: any = await postSimplified(data);
    return response
}
);

export const questionAnswerAsync = createAsyncThunk("post/postQuestionAnswerData", async (data: any) => {
    const response: any = await ChatBotQuesAnsApi(data);
    return response
}
);

export const questionAnswerPerticularAsync = createAsyncThunk("post/postPerticularQuestion", async (data: any) => {
    const response: any = await chatApiPerticular(data);
    return response
}
);

export const postQueryAsync = createAsyncThunk("post/postQuery", async (data: any) => {
    const response: any = data.Answer ? await createChatHistoryApi(data) : null;
    return response
}
);

export const highLightAsync = createAsyncThunk("get/highLightData", async (data: any) => {
    const response: any = await highLight(data);
    return response
})

export const getClauseAsync = createAsyncThunk('get/clause', async (data: any) => {
    const response: any = await getClauseApi(data);
    return response

})

export const getOriginalContentAsync = createAsyncThunk("get/editOriginalContract", async (data: any) => {
    const response = await getOriginalClause(data);
    return response
}
);


export const clauseDetailSlice = createSlice({
    name: "clauseDetails",
    initialState,
    reducers: {
        reset: (state) => {
            state.message = ""
            state.quesAns = constants.NULL
            state.simplifiedData = constants.NULL
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postSimplifiedAsync.pending, (state) => {
                state.isLoading = constants.TRUE;
            })
            .addCase(postSimplifiedAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.simplifiedData = action.payload
            })
            .addCase(postSimplifiedAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.message = constants.INVALID_CREDENTIAL;
            })
            // Chatbot
            .addCase(questionAnswerAsync.pending, (state) => {
                state.isLoading = constants.TRUE;
            })
            .addCase(questionAnswerAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.quesAns = action.payload
            })
            .addCase(questionAnswerAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.message = constants.INVALID_CREDENTIAL;
            })
            // Chatbot for perticular contract
            .addCase(questionAnswerPerticularAsync.pending, (state) => {
                state.isLoading = constants.TRUE;
            })
            .addCase(questionAnswerPerticularAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.quesAns = action.payload
            })
            .addCase(questionAnswerPerticularAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.message = constants.INVALID_CREDENTIAL;
            })
            // create Chat history
            .addCase(postQueryAsync.pending, (state) => {
                state.isLoading = constants.TRUE;
            })
            .addCase(postQueryAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.chatHistory = action.payload;
                state.quesAns = constants.NULL;
            })
            .addCase(postQueryAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.message = constants.INVALID_CREDENTIAL;
            })
            // highLight Content
            .addCase(highLightAsync.pending, (state) => {
                state.isLoading = constants.TRUE;
            })
            .addCase(highLightAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.highLightData = action.payload
            })
            .addCase(highLightAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.message = constants.INVALID_CREDENTIAL;
            })
            // get Clause
            .addCase(getClauseAsync.pending, (state) => {
                state.isLoading = constants.TRUE;
            })
            .addCase(getClauseAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.clauseDetail = action.payload.data
                state.linkData = action.payload.link
                state.definitionData = action.payload?.defination
            })
            .addCase(getClauseAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.message = constants.INVALID_CREDENTIAL;
            })
            // get Original Clause
            .addCase(getOriginalContentAsync.pending, (state) => {
                state.isLoading = constants.TRUE;
            })
            .addCase(getOriginalContentAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.clauseDetail = action.payload
            })
            .addCase(getOriginalContentAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.isLoading = constants.FALSE;
                state.message = constants.INVALID_CREDENTIAL;
            })
    },
});

export const { reset } = clauseDetailSlice.actions
export default clauseDetailSlice.reducer;

