import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createUserApi, getUserListingApi, getSingleUserApi, updateUserApi, deletUserApi } from '../service'


const initialState = {
    getAggrementDetails: constants.NULL,
    status: constants.IDLE,
    signErr: constants.NULL,
    manageAdminloading: constants.FALSE,
    error1: "",
    userList: constants.NULL,
    userDetail: constants.NULL,
    message: "",
    statusCode: constants.NULL
};

export const getUserDetailAsync = createAsyncThunk("get/allUserListing", async (data: string) => {
    const response: any = await getUserListingApi(data);
    return response
}
);
export const getSingleUserAsync = createAsyncThunk("get/singleUserListing", async (id: number) => {
    const response: any = await getSingleUserApi(id);
    return response
}
);

export const createUserAsync = createAsyncThunk("create/user", async (data: any) => {
    const response: any = await createUserApi(data);
    return response
}
);
export const updateUserAsync = createAsyncThunk("put/user", async (data: any) => {
    const response: any = await updateUserApi(data);
    return response
}
);
export const deleteUserAsync = createAsyncThunk("delete/user", async (id: any) => {
    const response: any = await deletUserApi(id);
    return response
}
);

export const userSlice = createSlice({
    name: "Index Data",
    initialState,
    reducers: {
        reset: (state) => {
            state.message = ""
            state.statusCode = constants.NULL
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetailAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(getUserDetailAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.userList = action.payload;
            })
            .addCase(getUserDetailAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            .addCase(getSingleUserAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(getSingleUserAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.userDetail = action.payload;
            })
            .addCase(getSingleUserAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            .addCase(createUserAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(createUserAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.message = action.payload.message;
                state.statusCode = action.payload.statusCode
            })
            .addCase(createUserAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            .addCase(updateUserAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(updateUserAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.message = action.payload.message;
                state.statusCode = action.payload.statusCode
            })
            .addCase(updateUserAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            // Delete User
            .addCase(deleteUserAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(deleteUserAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
            })
            .addCase(deleteUserAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
    },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;