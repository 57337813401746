import axios from "axios";
import { toast } from "react-toastify";
export const role = localStorage.getItem("auth");

const isAuthorised = (error: any) => {
  if (error?.response?.data?.message?.trim() == "Access token has beed expired") { localStorage.clear() }
}


export function loginApi(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}users/login`, data)
      .then(function (response: any) {
        if (response?.data?.statusCode === 200 && response?.data?.token) {
          localStorage.setItem("auth", JSON.stringify(response?.data?.token));
          localStorage.setItem("is_admin", JSON.stringify(response?.data?.is_admin));
          localStorage.setItem('roleAuth', response?.data?.roleAuthority)
          toast.success(response.data.message)
        }
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error);
      })
  );
}


//admin login api
export function adminloginApi(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_ADMIN_URL}users/super-admin/login`, data)
      .then(function (response: any) {
        if (response?.data?.statusCode === 200 && response?.data?.token) {
          localStorage.setItem("auth", JSON.stringify(response?.data?.token));
          localStorage.setItem("is_admin", JSON.stringify(response?.data?.is_super_admin));
          toast.success(response.data.message)
        }
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error);
      })
  );
}


// Contract Hub API
export function getContractHubApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}contracts/dashboard`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        error.response &&
          error.response.data &&
          reject(error.response.data.error);
      })
  );
}

// Post Contract Hub API
export function postContractHubApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  let form = new FormData();
  form.append("file", data.pdfUpload);
  form.append("name", data.name);
  form.append("description", data.description);
  form.append("date", data.date);
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}contracts/upload`, form, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Profile API
export function getProfileApi() {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}users/profile`, config)
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Post Profile API
export function updateProfileApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };


  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}users/updateProfile`, data, config)
      .then(function (response: any) {
        toast.success("Updated successfully");
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Post Profile API
export function updateProfilePicApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  let form = new FormData();
  form.append("image", data.image);
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}users/assets`, form, config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Aggrement API
export function getAggrementApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_API_URL}contracts/allcontract/${data}`,
        config
      )
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error?.response?.data.error);
      })
  );
}

// get Clause details//  Aggrement API
export function getAgreementClauseApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_API_URL}clause?contract_id=${data}`,
        config
      )
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        // toast.error(error?.response?.data?.message);
        reject(error?.response?.data.error);
      })
  );
}


// get clause Only for agreementDetails page
export function getClauseOnlyApi(data: any) {
  localStorage.setItem('data', data)
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_API_URL}clause/${data}`,
        config
      )
      .then(function (response: any) {
        resolve({ response: response?.data?.data, id: data });
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error?.response?.data.error);
      })
  );
}


// get Clause details//  Aggrement API
export function getOriginalClauseApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_API_URL}clause/original/${data}`,
        config
      )
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        // toast.error(error?.response?.data?.message);
        reject(error?.response?.data.error);
      })
  );
}

//  Original Contract API
export function getOriginalAgreementApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}contracts/originalpdf`,
        data,
        config
      )
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Add Comment API
export function postCommentApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}comments/add`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data?.message);
      })
  );
}

// Edit Comment API
export function putCommentApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}comments/`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data?.message);
      })
  );
}

// Edit Comment API
export function deleteCommentApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .delete(`${process.env.REACT_APP_API_URL}comments/${data}`, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data?.message);
      })
  );
}

// get Comments API
export function getCommentsApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}comments`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error?.response?.data?.error);
      })
  );
}

//  Add Content API
export function postContentApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}contracts/addcontract`,
        data,
        config
      )
      .then(function (response: any) {
        if (response?.data?.statusCode == 201) {
          toast.success(response?.data?.message);
        }
        resolve(response);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


//
export function editContentApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}contracts`, data, config)
      .then(function (response: any) {

        resolve(response.data.statusCode);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//
export function addClauseApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}clause`, data, config)
      .then(function (response: any) {
        resolve(response.data.statusCode);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//
export function getOriginalClause(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}clause?section_clause_id=${data}`, config)
      .then(function (response: any) {

        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


///
export function deletePermanentApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .delete(`${process.env.REACT_APP_API_URL}clause/${data}`, config)
      .then(function (response: any) {
        resolve(response.data.statusCode);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


// Api for add Clause
export function postClauseApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}clause`, data, config)
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Api for add Clause in up to date
export function postClauseUptoDateApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}clause/add`, data, config)
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


// Api for add Clause in up to date
export function getSectionDataApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}clause/clause-details/${data}`, config)
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Api for add Clause in up to date
export function changeClauseNumberApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}clause/update-number`, data, config)
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


// Api for add Clause
export function changeClausePositionApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}clause/update-position`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


// Api for section position change
export function changeSectionPositionApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}clause/update-position`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


//  Index API
export function getIndexApi(id: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}contracts/index/${id}`, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Forgot Password
export const ForgotPasswordApi = (data: any) => {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}users/forgotpassword`,
        data
      )
      .then(function (response: any) {
        toast.success(response?.data?.message);

        resolve(response);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
};

// Change Password
export function changePasswordApi(data: any) {
  const config = {
    headers: {
      token: data.token,
    },
  };
  const postData = {
    password: data.password,
    host: data?.host
  };
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}users/changePassword`,
        postData,
        config
      )
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data.message);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error?.response?.data?.error);
      })
  );
}

// favorite contract
export function favoriteApi(data: any) {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}contracts/favorite`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response.data.message);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// contract History
export function contractHistoryApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}contracts/history`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Notes API
export function contractNotesApi() {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}notes`, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error?.response?.data?.error);
      })
  );
}

// Post History
export function contractPostNotesApi(data: any) {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}notes`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Edit Notes
export function contractEditNotesApi(data: any) {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}notes`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Delete Section
export function deleteSectionApi(data) {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}contracts/delete`, data, config)
      .then(function (response: any) {

        if (response?.data?.statusCode == 200) {
          toast.success("Successfully deleted contract");
        }
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Delete Notes
export function contractDeleteNotesApi() {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .delete(`${process.env.REACT_APP_API_URL}notes`, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// simplified version of text
export function postSimplified(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_AI_Url}simply_language`, data)
      .then(function (response: any) {
        toast.success("Text simplified");
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.message);
        reject(error?.message);
      })
  );
}

//HighLight text
export function highLight(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_AI_Url}highlight`, data)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        reject(error?.message);
      })
  );
}

// Chatbot Api
export function ChatBotQuesAnsApi(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_AI_Url}question_answer`, data)
      .then(function (response: any) {
        toast.success(response.data.message)

        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.message);
        reject(error.message);
      })
  );
}

// Chatbot Api for perticular contract
export function chatApiPerticular(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_AI_Url}particular_qa_file/`, data)
      .then(function (response: any) {
        toast.success(response.data.message)
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.message);
        reject(error.message);
      })
  );
}

// Upload pdf to ML dataBase
export function postToMLApi(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_AI_Url}vector_stores`, data)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        reject(error.message);
      })
  );
}

// Delete pdf from vector dataBase
export function deleteFromMLApi(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_AI_Url}delete`, data)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        reject(error.message);
      })
  );
}

export function getChatHistoryApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_API_URL}chat-history/${data.searchString}`,
        config
      )
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

export function getChatBotHistoryApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${process.env.REACT_APP_API_URL}chat-history/${data.searchString}`,
        config
      )
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//search ChatHistory
export function searchChatHistoryApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  if (data.searchstring !== "") {
    return new Promise((resolve, reject) =>
      axios
        .post(
          `${process.env.REACT_APP_API_URL}chat-history/search?sort=true`,
          data,
          config
        )
        .then(function (response: any) {
          resolve(response?.data);
        })
        .catch(function (error: any) {
          isAuthorised(error)
          toast.error(error?.response?.data?.message);
          reject(error?.response?.data?.error);
        })
    );
  } else {
    return new Promise((resolve, reject) =>
      axios
        .get(`${process.env.REACT_APP_API_URL}chat-history?sort=true`, config)
        .then(function (response: any) {
          resolve(response?.data);
        })
        .catch(function (error: any) {
          isAuthorised(error)
          toast.error(error?.response?.data?.message);
          reject(error?.response?.data?.error);
        })
    );
  }
}

// Create chat history Api
export function createChatHistoryApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}chat-history`, data, config)
      .then(function (response: any) {
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        error.response &&
          error.response.data &&
          reject(error?.response?.data?.error);
      })
  );
}

// Delete chat history Api
export function deleteChatHistoryApi(data: any) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}chat-history/delete`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        error.response &&
          error.response.data &&
          reject(error.response.data.error);
      })
  );
}

// Delete chat history Api
export function deleteAllChatApi() {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .delete(`${process.env.REACT_APP_API_URL}chat-history`, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        error.response &&
          error.response.data &&
          reject(error.response.data.error);
      })
  );
}

// Update Contract Apif
export function updateContractApi(data) {
  const config: any = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  let form = new FormData();
  form.append("title", data?.title);
  form.append("file", data?.pdfUpload);
  form.append("date", data?.date);
  form.append("description", data?.description);
  form.append("contract_id", data?.id);


  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}contracts/update`, form, config)
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        error.response &&
          error.response.data &&
          reject(error.response.data.error);
      })
  );
}

//  Delete Section
export function deleteContractApi(data) {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}contracts/delete-contract`,
        data,
        config
      )
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Get Defination
export function getDefinationApi(id: any) {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}defination/${id}`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        reject(error.response.data.error);
      })
  );
}

// Post Defination
export function postDefinationApi(data) {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}defination`, data, config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Post Links
export function postLinksApi(data) {
  const config: any = {
    headers: {
      token: JSON.parse(localStorage.getItem("auth") || ""),
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}links`, data, config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  getContract API
export function getClauseApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}clause?clause_id=${data.clause_id}&links=true&definations=true`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        // toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  get link API
export function getLinkApi(id: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}links/${id}`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  get All user API
export function getUserListingApi(search: string) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}users?searchString=${search}`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  get All user for admin API
export function getAllAdminListingApi(search: string) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_ADMIN_URL}users?searchString=${search}`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  get Single User API
export function getSingleUserApi(id) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}users/${id}`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}



//  get link API
export function createUserApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}users`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  get link API
export function updateUserApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}users`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


//  delete User API
export function deletUserApi(id: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .delete(`${process.env.REACT_APP_API_URL}users/${id}`, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}



//  get Roles API
export function getRoles() {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}role`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  get Authorities API
export function getAuthoritiesApi() {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}authority`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  get perticular role API
export function particularRole(id: number) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_URL}role/${id}`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Create role API
export function createRoleApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}role`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Update role API
export function updateRoleApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_URL}role`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

//  Delete role API
export function deleteRoleApi(id: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .delete(`${process.env.REACT_APP_API_URL}role/${id}`, config)
      .then(function (response: any) {
        toast.success(response?.data?.
          message)
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}



//  get Admin Profile API
export function getAdminProfileApi() {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_ADMIN_URL}users/profile`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

export function AdminloginApi(data: any) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_ADMIN_URL}users/login`, data)
      .then(function (response: any) {
        if (response?.data?.statusCode === 200 && response?.data?.token) {
          localStorage.setItem("auth", JSON.stringify(response?.data?.token));
          localStorage.setItem("is_admin", JSON.stringify(response?.data?.is_admin));
        }
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error);
      })
  );
}


//  get Single Admin API
export function getSingleAdminApi(id) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_API_ADMIN_URL}users/${id}`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


//  Create Admin API
export function createAdminApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_ADMIN_URL}users`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


export function updateAdminApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_ADMIN_URL}users`, data, config)
      .then(function (response: any) {
        toast.success(response?.data?.message)
        resolve(response?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}


//  delete Admin API
export function deletAdminApi(id: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };

  return new Promise((resolve, reject) =>
    axios
      .delete(`${process.env.REACT_APP_API_ADMIN_URL}users/${id}`, config)
      .then(function (response: any) {
        resolve(response?.data?.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Post Profile API
export function updateAdminProfileApi(data: any) {
  const config = {
    headers: {
      token: `${JSON.parse(localStorage.getItem("auth") || "")}`,
    },
  };


  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_API_ADMIN_URL}users/updateProfile`, data, config)
      .then(function (response: any) {
        toast.success("Updated successfully");
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
}

// Post Profile API
export function domainCheckApi(data: any) {

  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_ADMIN_URL}users/checkdomain`, data)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error.response.data.message);
        reject(error.response.data);
      })
  );
}

//  Forgot Password
export const adminForgotPasswordApi = (data: any) => {
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_API_ADMIN_URL}users/forgotpassword`,
        data
      )
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error.response.data.error);
      })
  );
};


export function changeAdminPasswordApi(data: any) {
  const config = {
    headers: {
      token: data.token,
    },
  };
  const postData = {
    password: data.password,
  };
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${process.env.REACT_APP_API_ADMIN_URL}users/changePassword`,
        postData,
        config
      )
      .then(function (response: any) {
        toast.success(response?.data?.message);
        resolve(response?.data.message);
      })
      .catch(function (error: any) {
        isAuthorised(error)
        toast.error(error?.response?.data?.message);
        reject(error?.response?.data?.error);
      })
  );
}