import React from 'react'
import { Button } from 'react-bootstrap'

interface Btn {
    classNam: string;
    text: string
}

const CustomButton = ({ classNam, text }: Btn): JSX.Element => {
    return (
        <Button className={classNam} >
            {text}
        </Button>
    )
}

export default CustomButton