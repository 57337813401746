import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../pages/scss/dashboard.scss";
import Form from "react-bootstrap/Form";
import CustomButton from "../components/button/button";
import CustomP from "../components/paragraph/paragraph";
import CustomHeading from "../components/heading/heading";
import {
  SearchIcon,
  ChatIcon,
  DeleteIcon,
  StarIcon,
  TableStarIconFilled,
} from "../components/svgicon/svg";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteNotesAsync,
  getContractHubAsync,
  getNotesAsync,
  postNotesAsync,
  putNotesAsync,
  deleteChatHistoryAsync,
  clearChatHistoryAsync,
  searchChatHistoryAsync,
  reset
} from "../store/Slices/contractHubSlice";
import moment from "moment";
import { useAppSelector, useAppDispatch } from "../hook";
import ChatButton from "../components/chatbox/chatIcon";
import Chatbot from "../components/chatbox/chatBot/chatBot";
import { Add, Remove, SaveIcon } from "../assets/icons";
import { postQueryAsync } from "../store/Slices/clauseSlice";
import CustomSpinner from "../components/spinner/spinner";
import ConfirmModal from "../components/confirmModal";
import { setTimeout } from "timers/promises";



const Dashboard = () => {
  const {
    getContractDetails,
    getNotes,
    dashBoardLoading,
    chatHistory,
    message,
  } = useAppSelector((state: any) => state?.getAllContracts);
  const { quesAns } = useAppSelector((state: any) => state?.clauseDetails);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [noteIn, setNoteIn] = useState("");
  const [textareaError, setTextareaError] = useState("");
  const [chatShow, setChatShow] = useState(false);
  const [deleteChatId, setDeleteChatId] = useState(null);
  const [confirmShow, setConfirmShow] = useState(false);
  const [clearShow, setClearShow] = useState(false);
  const [count, setCount] = useState(0)
  const handleChatShow = () => setChatShow(!chatShow);

  const initialCall = () => {
    if (!getContractDetails) {
      dispatch(getContractHubAsync({
        isFavorite: 1 ? 1 : 0,
        limit: 10,
        page: 1,
        searchstring: search
      }))
    }
  }


  useEffect(() => {
    Promise.all([
      dispatch(getNotesAsync()),
      // dispatch(searchChatHistoryAsync({ searchstring: search })),
      dispatch(
        getContractHubAsync({
          isFavorite: 1 ? 1 : 0,
          limit: 10,
          page: 1,
          searchstring: search,
        })
      )
    ]).then(() => {
      initialCall()
    })
  }, []);



  useEffect(() => {
    setNoteIn(getNotes?.data[0]?.text);
  }, [getNotes]);

  useEffect(() => {
    // if (search) {
    dispatch(searchChatHistoryAsync({ searchstring: search }))
    // }
  }, [search])

  useEffect(() => {
    if (quesAns && quesAns?.status == 200) {
      dispatch(
        postQueryAsync({
          Question: quesAns?.details?.query,
          Answer: quesAns?.details?.result,
        })
      );
    }
  }, [quesAns]);



  useEffect(() => {
    if (deleteChatId) {
      setConfirmShow(true);
    } else {
      setConfirmShow(false);
    }
  }, [deleteChatId]);


  const newText = noteIn;

  const textChange = async (e: any) => {
    if (!newText || newText.trim() === "") {
      setTextareaError("Please enter a valid note.");
    } else {
      setTextareaError("");
      await dispatch(postNotesAsync({ text: newText }));
      await dispatch(getNotesAsync());
    }
  };

  const editTextChange = async () => {
    if (!newText || newText.trim() === "") {
      setTextareaError("Please enter a valid note.");
    } else {
      setTextareaError("");
      await dispatch(putNotesAsync({ text: newText }));
      await dispatch(getNotesAsync());
    }
  };
  const handleDelete = async () => {
    await dispatch(deleteNotesAsync());
    await setNoteIn("");
    await dispatch(getNotesAsync());
  };


  const handleConfirm = async () => {
    await dispatch(deleteChatHistoryAsync({ id: deleteChatId }));
    await setDeleteChatId(null);
  };
  const clearState = () => {

  }

  useEffect(() => {
    if (message && message.trim() == "Successfully deleted all ChatHistory") {
      dispatch(searchChatHistoryAsync({ searchstring: search }))
      dispatch(reset())
    }
  }, [message])


  const getTime = (time: any) => {
    if (
      moment(time)?.format("DD MMMM YYYY") ==
      moment(new Date())?.format("MMMM DD YYYY")
    ) {
      return "Today";
    } else {
      return moment(time).format("DD MMM YYYY");
    }
  };


  const handleClear = () => {
    dispatch(clearChatHistoryAsync());
    setClearShow(false);
  };


  function formatTimeDifference(createdAt) {
    const now = moment();
    const createdAtMoment = moment(createdAt);
    const timeDifferenceInMinutes = now.diff(createdAtMoment, 'minutes');

    if (timeDifferenceInMinutes < 60) {
      return `${timeDifferenceInMinutes} minutes ago`;
    } else if (timeDifferenceInMinutes < 1440) {
      const hours = Math.floor(timeDifferenceInMinutes / 60);
      return `${hours} hours ago`;
    } else if (timeDifferenceInMinutes < 43200) {
      const days = Math.floor(timeDifferenceInMinutes / 1440);
      return `${days} days ago`;
    } else if (timeDifferenceInMinutes < 525600) {
      const months = Math.floor(timeDifferenceInMinutes / 43200);
      return `${months} months ago`;
    } else {
      const years = Math.floor(timeDifferenceInMinutes / 525600);
      return `${years} years ago`;
    }
  }



  return (
    <div className="dashboard-main">
      {dashBoardLoading && (
        <div className="custom-spinner-container">
          <CustomSpinner />
        </div>
      )}
      <ConfirmModal
        show={confirmShow}
        message={"Are you sure you want to delete this history"}
        cancel={() => setDeleteChatId(null)}
        confirm={handleConfirm}
      />
      <ConfirmModal
        show={clearShow}
        message={"Are you sure you want clear all chat"}
        cancel={() => setClearShow(false)}
        confirm={handleClear}
      />
      <Container>
        <Row className="mt-5">
          <Col lg={6} md={12}>
            <div className="link-history contract-hub">
              <div className="histor-inner">
                <span className="text-contract">Starred Contract</span>

                <Link style={{ textDecoration: "none" }} to="/contract-list">
                  <CustomButton
                    text="Go to ContractHub"
                    classNam="custom-btn custom-primary"
                  />
                </Link>
              </div>

              <div className="main-listing">
                {getContractDetails?.data?.map((ele: any, index: any) => {
                  return (
                    <div className="sharred-isting" key={index}>
                      <div className="single-list">
                        <p
                          onClick={() => {
                            navigate(`/agreement-details/${ele?.id}`, {
                              state: {
                                contract_id: ele?.id
                              }
                            });
                          }}
                        >
                          {ele?.name}
                        </p>

                        <p className="ms-1">{moment(ele?.date).format("DD MMM YYYY")}</p>
                        {ele?.isFavorite ? (
                          <TableStarIconFilled />
                        ) : (
                          <StarIcon />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="note-reminder ">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="histor-inner">
                    <CustomHeading text="Notes & Reminders" level={4} />
                  </div>
                  <div className="d-flex justify-content-end cursoor" >
                    <div
                      title="Add"
                      style={{ width: "25px" }}
                      onClick={textChange}
                      className={`${getNotes?.data[0]?.text?.length > 0 ? "d-none" : ""
                        }`}
                    >
                      {Add}
                    </div>
                    <div
                      title="Save"
                      style={{ width: "25px" }}
                      onClick={editTextChange}
                      className={`${getNotes?.data[0]?.text?.length > 0 ? "" : "d-none"
                        }`}
                    >
                      {SaveIcon}
                    </div>
                    <div
                      title="Delete"
                      style={{ width: "25px" }}
                      onClick={handleDelete}
                      className={`${getNotes?.data[0]?.text?.length > 0 ? "" : "d-none"
                        }`}
                    >
                      {Remove}
                    </div>
                  </div>
                </div>

                <Form.Group
                  className="custom-textarea mt-2"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    className="textarea"
                    as="textarea"
                    rows={5}
                    placeholder="Write any useful notes/reminders here."
                    value={noteIn}
                    onChange={(e) => setNoteIn(e.target.value)}
                  />
                  {textareaError && (
                    <div className="text-danger">{textareaError}</div>
                  )}
                </Form.Group>
              </div>
              <Row>
                <Col md={6}>
                  <div className="histor-inner btm-btn" onClick={() => window.open('https://www.legallinkai.co.uk/how-to', '_blank')}>
                    <CustomButton
                      classNam="custom-btn custom-primary"
                      text="How To Use LegalLinkAI"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="histor-inner btm-btn"
                    onClick={() => window.open('mailto:Support@legal-link.ai', '_blank')}
                  // onClick={() => { window.location.href('mailto:Support@legal-link.ai') }}
                  >
                    <CustomButton
                      classNam="custom-btn border-btn"
                      text="Need some help?"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="common-search search-outer dashboard-search dashboards customd">
              <span className="left-icon">
                <SearchIcon />
              </span>
              <input
                type="text"
                placeholder="Search your 'Ask Link' history"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />

              <div className="histor-inner">

                <div onClick={() => setClearShow(true)}>
                  <CustomButton classNam="custom-btn border-btn" text="Clear" />
                </div>
              </div>
            </div>
            <div className="link-history">
              <div className="scroll-outer">
                {chatHistory
                  ? chatHistory?.data?.map((ele: any, indexx: any) => {
                    return (
                      <div key={indexx} className="single-box ">
                        <h5>
                          <span>{getTime(ele?.createdAt)}</span>
                        </h5>
                        <div className="d-flex justify-content-around position-relative">
                          <div className="small-logo">
                            <ChatIcon />
                          </div>
                          <div
                            className="center-div"
                            style={{ width: "86%" }}
                          >
                            <div className="d-flex justify-content-between chatHeader text-start">
                              <b><CustomP
                                text={` ${ele?.Question} `}
                              /></b>
                              <CustomP text={
                                formatTimeDifference(ele?.createdAt)
                              } />
                            </div>
                            <p className="text-start fs-5 historyText">
                              {ele?.Answer.split(/\n|\n\n|\n\n1|\n\nn/g).map(
                                (elem: any, ind: number) => {
                                  return (
                                    <span key={ind} className="historySize">
                                      {elem}
                                      <br />
                                    </span>
                                  );
                                }
                              )}
                            </p>
                          </div>
                          <div
                            className="delete-box"
                            style={{
                              position: "absolute",
                              right: "10px",
                              bottom: "10px",
                            }}
                          >
                            <div
                              className="delete-icon"
                              onClick={() => setDeleteChatId(ele.id)}
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : "No chat history available"}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {chatShow == true ? <Chatbot contract_id={'all'} /> : ""}
      <ChatButton Click={handleChatShow} show={chatShow} />
    </div>
  );
};


export default Dashboard;