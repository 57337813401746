import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../scss/profiledetails.scss'
import {
    updateProfilePicAsync,
} from "../../store/Slices/profileSlice";
import CustomHeading from "../../components/heading/heading";
import {
    UserPhoto,
    EditPhoto,
    NamePicon,
    EmailPicon,
    PasswordPicon,
} from "../../components/svgicon/svg";
import Form from "react-bootstrap/Form";
import Customlabel from "../../components/label/label";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hook";
import CustomSpinner from "../../components/spinner/spinner";
import { Nav } from "react-bootstrap";
import { getAdminProfileAsync, postAdminProfileAsync } from "../../store/Slices/AdminManagementSlice";

interface Permission {
    add: boolean;
    create: boolean;
    delete: boolean;
    edit: boolean;
    name: "string";
    view: boolean;
}

const AdminProfiledetails = () => {
    const role = localStorage.getItem("role");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [name, setName] = useState("");
    const [repeat, setRepeat] = useState("");
    const [formErr, setFormErr] = useState({ name: "" });
    const [formErr1, setFormErr1] = useState({
        oldPassword: "",
        newPassword: "",
        repeat: "",
    });
    const [disable, setDisable] = useState(true);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const {
        profileDetail,
        postProfileDetails,
        manageAdminloading,
    } = useAppSelector((state: any) => state?.AdminManagement);

    const {
        image
    } = useAppSelector((state: any) => state?.profile);

    useEffect(() => {
        dispatch(getAdminProfileAsync());
    }, []);

    useEffect(() => {
        if (profileDetail) {
            setName(profileDetail?.name);
        }
    }, [profileDetail]);

    useEffect(() => {
        if (postProfileDetails) {
            dispatch(getAdminProfileAsync());
        }
    }, [postProfileDetails]);

    const validations = (val: any) => {
        let valid = true;
        const err = { name: "" };
        if (val.name.length == 0) {
            err.name = "Name is required!";
            valid = false;
        }
        setFormErr(err);
        return valid;
    };

    const changePasswordValidations = (val: any) => {
        const err = { oldPassword: "", newPassword: "", repeat: "" };
        if (!oldPassword) {
            err.oldPassword = "Old Password is required!";
        }
        if (!newPassword) {
            err.newPassword = "New Password is required!";
        }
        if (!repeat) {
            err.repeat = "Re-Enter Password is required!";
        }
        if (newPassword !== repeat) {
            err.repeat = "Password doesn't match";
        }
        return err;
    };

    const handleClick = async () => {
        await validations({ name: name });
        dispatch(postAdminProfileAsync({ name: name, profile_pic: image?.path }));
    };

    const handleChangePassword = async () => {
        setFormErr1({ oldPassword: "", newPassword: "", repeat: "" });
        const validationErrors = changePasswordValidations({
            oldPassword,
            newPassword,
            repeat,
        });
        const hasErrors = Object.values(validationErrors).some((error) => !!error);

        if (!hasErrors) {
            try {
                await dispatch(postAdminProfileAsync({ oldPassword, newPassword }));
                dispatch(getAdminProfileAsync());
                setOldPassword("");
                setNewPassword("");
                setRepeat("");
            } catch (error) {
                return error;
            }
        } else {
            setFormErr1(validationErrors);
        }
    };

    const handleImageUpload = (event: any) => {
        const image = event.target.files[0];
        setSelectedImage(image);
        dispatch(updateProfilePicAsync({ image: image }));
    };

    const removeImageUpload = (event: any) => {
        setSelectedImage(null);
    };

    return (
        <>
            {manageAdminloading && (
                <div className="custom-spinner-container">
                    <CustomSpinner />
                </div>
            )}
            <div className="account-outer">
                <Container>
                    <div className=" d-flex justify-content-start my-5 custom_heading">
                        <h3>My Account</h3>
                        <Nav className="justify-content-center tabs ms-4" activeKey="/home">
                            <Nav.Item>
                                <Nav.Link onClick={() => navigate("/admin/user-management")}>
                                    User Management
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <Row>
                        <Col lg={6}>
                            <div className="profile-details">
                                <CustomHeading text={"Profile Details"} level={4} />
                                <button
                                    onClick={() => setDisable(false)}
                                    className="custom-btn border-btn"
                                >
                                    Edit
                                </button>
                            </div>
                            <div className="profile-inner">
                                <CustomHeading text={"Profile Pic"} level={5} />
                                <div className="upload-inner">
                                    <div className="edit-photo">
                                        <EditPhoto />
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        placeholder="upload your image here"
                                        onInput={handleImageUpload}
                                    />
                                    {selectedImage ? (
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            alt="Selected"
                                        />
                                    ) : profileDetail?.profile_pic ? (
                                        <img
                                            src={profileDetail?.profile_pic}
                                            alt="Uploaded"
                                        />
                                    ) : (
                                        <UserPhoto />
                                    )}
                                </div>
                                <h4>
                                    <a href="#" onClick={removeImageUpload}>
                                        Remove Picture
                                    </a>
                                </h4>
                                <div className="form-inner">
                                    <div className="label-outer">
                                        <NamePicon />
                                        <Customlabel text={"Name"} />
                                    </div>

                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        name="name"
                                        value={name}
                                        maxLength={20}
                                        onChange={(e) => setName(e.target.value)}
                                        disabled={disable}
                                        size="lg"
                                    />
                                    <p className="text-danger">{formErr.name}</p>
                                </div>
                                <div className="form-inner">
                                    <div className="label-outer">
                                        <EmailPicon />
                                        <Customlabel text={"Email"} />
                                    </div>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={profileDetail?.email}
                                        placeholder="Enter Email"
                                        size="lg"
                                    />
                                </div>

                                <div className="save-btn">
                                    <button
                                        onClick={handleClick}
                                        className={`custom-btn custom-primary  ${disable ? "disabled" : ""
                                            }`}
                                        disabled={disable}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="profile-details">
                                <CustomHeading text={"Change Password"} level={4} />
                            </div>
                            <div className="profile-inner">
                                <div className="form-inner">
                                    <div className="label-outer">
                                        <PasswordPicon />
                                        <Customlabel text={"Old Password*"} />
                                    </div>
                                    <Form.Control
                                        type="password"
                                        name="oldPassword"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        placeholder="****************"
                                        size="lg"
                                    />
                                    <p className="text-danger">{formErr1?.oldPassword}</p>
                                </div>
                                <div className="form-inner">
                                    <div className="label-outer">
                                        <PasswordPicon />
                                        <Customlabel text={"New Password*"} />
                                    </div>
                                    <Form.Control
                                        type="password"
                                        placeholder="****************"
                                        name="newPassword"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        size="lg"
                                    />
                                    <p className="text-danger">{formErr1?.newPassword}</p>
                                </div>
                                <div className="form-inner">
                                    <div className="label-outer">
                                        <PasswordPicon />
                                        <Customlabel text={"Repeat Password*"} />
                                    </div>
                                    <Form.Control
                                        type="password"
                                        value={repeat}
                                        onChange={(e) => setRepeat(e.target.value)}
                                        placeholder="****************"
                                        name="repeatPassword"
                                        size="lg"
                                    />
                                    <p className="text-danger">{formErr1?.repeat}</p>
                                </div>
                                <div className="save-btn">
                                    <button
                                        onClick={handleChangePassword}
                                        className="custom-btn custom-primary"
                                    >
                                        {" "}
                                        Change Password
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <div className="peofile-details">
                            <h3>Profile Detail</h3>
                            <div className="name-box">
                                <p>
                                    <b>Name : </b>
                                    {name}
                                </p>
                                <p>
                                    <b>Email : </b>
                                    {profileDetail?.email}
                                </p>
                                <p>
                                    <b>Email Verified : </b>
                                    {profileDetail?.isEmailVerified == "0"
                                        ? "No"
                                        : "Yes"}
                                </p>
                                <p>
                                    <b>Registered Type : </b>
                                    {profileDetail?.registeredmethod}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default AdminProfiledetails;