import { useState, useEffect } from "react"
import '../scss/addRole.scss'
import CustomSpinner from "../../components/spinner/spinner"
import { Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hook"
import { useLocation } from "react-router-dom"
import { getSingleAdminAsync, updateAdminAsync, resetAdmin } from "../../store/Slices/AdminManagementSlice"





const AdminEditUserPage = () => {
    const dispatch = useAppDispatch()
    const { userDetail, message, statusCode } = useAppSelector((state): any => state?.AdminManagement)
    const navigate = useNavigate()
    const { state } = useLocation()
    const [userAuthority, setUserAuthority] = useState()
    const [currentRole, setCurrentRole] = useState({})
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [email, setEmail] = useState("")
    const [company_name, setCompany_name] = useState("")
    const [roleId, setRoleId] = useState(null)
    const [userError, setUserError] = useState({
        name: "",
        email: "",
        organization: ""
    })

    const [roleOption, setRoleOption] = useState([])
    useEffect(() => {
        dispatch(getSingleAdminAsync(state?.id))
    }, [state])

    useEffect(() => {
        userDetail?.map((elem: any) => {
            setName(elem?.name)
            setRoleId(elem?.role_id)
            setCurrentRole({ label: elem?.role_name, value: elem?.role_id })
            setEmail(elem?.email)
            setCompany(elem?.host)
            setCompany_name(elem?.company_name)
        })
    }, [userDetail])


    const validate = () => {
        let valid = true
        let Validations = {
            name: "",
            email: "",
            organization: ""
        }
        if (name == "") {
            Validations.name = "Field can not be empty"
            valid = false
        }
        if (email == "") {
            Validations.email = "Field can not be empty"
            valid = false
        }
        if (!currentRole) {
            Validations.organization = "Field can not be empty"
            valid = false
        }
        setUserError({ ...Validations })
        return valid

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validate()) {
            await dispatch(updateAdminAsync({
                name: name,
                id: state?.id,
                email: email,
                company_name: company_name,
            }))
        }
    }

    useEffect(() => {
        if (message.trim() == "Successfully updated user profile" && statusCode == 200) {
            dispatch(resetAdmin())
            navigate("/admin/user-management");
        }
    }, [message, statusCode])

    const handleSelect = (e) => {
        setCurrentRole({ label: e.label, value: e.value })
        setRoleId(e.value)
    }


    return (
        <>
            <div className="dashboard-main">
                <Container>
                    <div className="my-5 ">
                        <div className="">
                            <h3><b>{state?.aciton == 'view' ? 'View' : 'Edit'} User</b></h3>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-48 d-flex flex-column ">
                            <label className="text-dark mb-2">Name</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="name" type="text" placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} disabled={state?.action == "view" ? true : false} />
                            <p className="text-danger">{userError?.name}</p>
                        </div>
                        <div className="w-48 d-flex flex-column mb-3">
                            <label className="text-dark mb-2">Email</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="email" type="text" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={state?.action == "view" ? true : false} />
                            <p className="text-danger">{userError?.email}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-48 d-flex flex-column mb-3">
                            <label className="text-dark mb-2">Host</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="email" type="text" placeholder="Enter Host" value={company} onChange={(e) => setCompany(e.target.value)} disabled={true} />
                            <p className="text-danger">{userError?.organization}</p>
                        </div>
                        <div className="w-48 d-flex flex-column ">
                            <label className="text-dark mb-2">Company</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="last_name" type="text" placeholder="Enter Company" value={company_name} onChange={(e) => setCompany_name(e.target.value)} disabled={state?.action == "view" ? true : false} />
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-center mt-5">
                        {state?.action == 'edit' ? <button className="custom-btn custom-primary me-3"
                            onClick={handleSubmit}
                        >Submit</button> : null}
                        <button className="custom-btn custom-primary me-3" onClick={() => navigate('/admin/user-management')}>Back</button>
                    </div>
                </Container >
            </div >
        </>
    )
}

export default AdminEditUserPage