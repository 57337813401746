import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { editContentApi, getDefinationApi, postDefinationApi, postLinksApi, getLinkApi, postClauseUptoDateApi, postClauseApi, changeSectionPositionApi, changeClausePositionApi, addClauseApi, deletePermanentApi, getOriginalClause } from "../service";

const initialState = {
  editContentData: constants.NULL,
  getDefinationData: constants.NULL,
  postDefinationData: constants.NULL,
  postLinksData: constants.NULL,
  status: constants.IDLE,
  signErr: constants.NULL,
  manageAdminloading: constants.FALSE,
  error1: "",
  getLinksData: constants.NULL,
  message: ""
};

export const editContentAsync = createAsyncThunk("editData/editContract", async (data: any) => {
  const response = await addClauseApi(data);
  return response
}
);


export const deleteClauseAsync = createAsyncThunk("delete/permanentClause", async (data: any) => {
  const response = await deletePermanentApi(data);
  return response
}
);

export const editTitleAsync = createAsyncThunk("editTitle/editContract", async (data: any) => {
  const response = await editContentApi(data);
  return response
}
);

export const postClauseAsync = createAsyncThunk("post/clause", async (data: any) => {
  const response = await postClauseApi(data);
  return response
}
);

export const addClauseUptoDateAsync = createAsyncThunk("post/clauseUpToDate", async (data: any) => {
  const response = await postClauseUptoDateApi(data);
  return response
}
);

export const changeClausePositionAsync = createAsyncThunk("changePosition/clause", async (data: any) => {
  const response = await changeClausePositionApi(data);
  return response
}
);

export const changeSectionPositionAsync = createAsyncThunk("changePosition/section", async (data: any) => {
  const response = await changeSectionPositionApi(data);
  return response
}
);

export const getDefinationAsync = createAsyncThunk("get/getDefination", async (id: any) => {
  const response = await getDefinationApi(id);
  return response
}
);

export const postDefinationAsync = createAsyncThunk("post/postDefination", async (data: any) => {
  const response = await postDefinationApi(data);
  return response
}
);

export const postLinksAsync = createAsyncThunk("link/postLinks", async (data: any) => {
  const response = await postLinksApi(data);
  return response
}
);

export const getLinksAsync = createAsyncThunk("link/getLinks", async (data: any) => {
  const response = await getLinkApi(data);
  return response
}
);

export const editContractSlice = createSlice({
  name: "Add Contract",
  initialState,
  reducers: {
    clearAllState: (state) => {
      state.editContentData = constants.NULL
      state.message = ""
    }
  },
  extraReducers: (builder) => {
    builder
      // edit
      .addCase(editContentAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(editContentAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.editContentData = action.payload;
        state.message = "Clause edited successfully"
      })
      .addCase(editContentAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })

      // delete
      .addCase(deleteClauseAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(deleteClauseAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.editContentData = action.payload;
      })
      .addCase(deleteClauseAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      .addCase(editTitleAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(editTitleAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.editContentData = action.payload;
      })
      .addCase(editTitleAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      //post Clause
      .addCase(postClauseAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(postClauseAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.editContentData = action.payload;
      })
      .addCase(postClauseAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      //post Clause up to date
      .addCase(addClauseUptoDateAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(addClauseUptoDateAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.editContentData = action.payload;
      })
      .addCase(addClauseUptoDateAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })

      //change Clause Position
      .addCase(changeClausePositionAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(changeClausePositionAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        // state.editContentData = action.payload;
      })
      .addCase(changeClausePositionAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      //change Section Position
      .addCase(changeSectionPositionAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(changeSectionPositionAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        // state.editContentData = action.payload;
      })
      .addCase(changeSectionPositionAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // getDefination
      .addCase(getDefinationAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getDefinationAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.getDefinationData = action.payload;
      })
      .addCase(getDefinationAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // postDefination
      .addCase(postDefinationAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(postDefinationAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.postDefinationData = action.payload;
      })
      .addCase(postDefinationAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // postLinks
      .addCase(postLinksAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(postLinksAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.postLinksData = action.payload;
      })
      .addCase(postLinksAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // postLinks
      .addCase(getLinksAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getLinksAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.getLinksData = action.payload;
      })
      .addCase(getLinksAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
  },
});

export default editContractSlice.reducer;
export const { clearAllState } = editContractSlice.actions
