import "../../pages/scss/login.scss";
import Logo from "../../images/logo.png";
import { EmailIcon, ForgotIcon } from "../../components/svgicon/svg";
import Form from "react-bootstrap/Form";
import { adminForgotPasswordAsync, forgotPasswordAsync } from "../../store/Slices/ForgotPasswordSlice";
import { useState } from "react";
import Customlabel from "../../components/label/label";
import CustomHeading from "../../components/heading/heading";
import { useAppDispatch } from "../../hook";
import { useNavigate } from "react-router-dom";

const AdminForgotPassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [email, setEmail] = useState("");
    const [formErr, setFormErr] = useState({ email: "" });

    const validations = (val) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const errors = { email: "" };
        if (!val.email.trim()) {
            errors.email = "Email is required!";
        } else if (!regex.test(val.email)) {
            errors.email = "This is not a valid email format!";
        }
        return errors;
    };

    const userData = {
        email: email ? email : "",
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validations(userData);
        setFormErr(errors);

        if (Object.values(errors).every((error) => !error)) {
            dispatch(adminForgotPasswordAsync({ email: email }));
        }
    };

    const backToLogin = () => {
        navigate("/")
    }

    return (
        <>
            <div className="main-login forgot-outer">
                <div className="login-inner">
                    <div className="logo-outer">
                        <div className="logo">
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="text-inner">
                            <CustomHeading text="AI" level={1} />

                            <CustomHeading text="At Your Service" level={3} />
                            <CustomHeading text="Navigate. Simplify. Understand." level={4} />
                        </div>
                    </div>
                    <div className="welcome-outer">
                        <CustomHeading text="Forgot Password" level={3} />
                        <div className="subtitle forgot-icon">
                            <ForgotIcon />
                            <CustomHeading
                                text="Enter the email address associated with your LegalLinkAI
                account."
                                level={6}
                            />
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <div className="input-outer">
                                <Customlabel text={"Email*"} />
                                <div className="input-inner">
                                    <EmailIcon />
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <p className="text-danger">{formErr?.email}</p>
                            </div>
                            <div className="submit-btn mt-5">
                                <button type="submit" className="btn custom-primary">Send Email</button>
                            </div>
                            <div onClick={backToLogin} className="backtologin mt-2 subtitle d-flex justify-content-center">
                                Login
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AdminForgotPassword;