import { Chat, Close } from "../../assets/icons"
import './chatBot/chatBot.scss'
// import cross from "../../images/cross.png"

interface ChatBtn {
    Click: () => void
    show: boolean
}

const ChatButton = ({ Click, show }: ChatBtn): JSX.Element => {


    return (
        <div className="chat-btn" onClick={Click}>
           {show == false ?Chat: Close}
        </div>
    )
}

export default ChatButton