import Container from "react-bootstrap/Container";
import "../pages/scss/agreementdetails.scss";
import Form from "react-bootstrap/Form";
import CustomHeading from "../components/heading/heading";
import CustomP from "../components/paragraph/paragraph";
import { SearchIcon, PdfIcon, MessageIcon } from "../components/svgicon/svg";
import { Remove, Add, Comment, Forward, Edit, edit, AddSend } from "../assets/icons";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import CustomButton from "../components/button/button";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  getAggrementAsync,
  getIndexAsync,
  postCommentAsync,
  getCommentsAsync,
  deleteSectionAsync,
  getAggrementClauseAsync,
  getOriginalClauseAsync,
  putCommentAsync,
  deleteCommentAsync
} from "../store/Slices/AggrementSlice";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { postContentAsync } from "../store/Slices/PostContentSlice";
import { editContentAsync, clearAllState, editTitleAsync } from "../store/Slices/editContentSlice";
import { useAppSelector, useAppDispatch } from "../hook";
import ChatButton from "../components/chatbox/chatIcon";
import Chatbot from "../components/chatbox/chatBot/chatBot";
import ConfirmModal from "../components/confirmModal";
import moment from "moment";
import { toast } from "react-toastify";
import { CloseBtn, ToggleArrow, Plus } from "../assets/icons";
import { Marker } from 'react-mark.js'
import { Permission, EditComment } from "../Interface";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const AgreementDetails = (...props: any) => {
  const { id } = useParams()
  const Admin = localStorage.getItem('is_admin')
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getIndex, comments, getAggrementClauseDetails, clauseOnlyData, statusMessage } = useAppSelector(
    (state: any) => state?.getAggrement
  );
  const Role: string | null = localStorage.getItem("is_admin");
  const [checkedValue, setCheckedValue] = useState("originalpdf")
  const [isOpen, setIsOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [searchClause, setSearchClause] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [showEditContentModal, setShowEditContentModal] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [select, setSelect] = useState(false);
  const [ind, setInd] = useState(null);
  const [Cid, setCid] = useState(null);
  const [sectionId, setSectionId] = useState(null)
  const [comment, setComment] = useState("");
  const [chatShow, setChatShow] = useState(false);
  const [remove, setRemove] = useState(null);
  const [formErr, setFormErr] = useState({ title: "" });
  const [commentModal, setCommentModal] = useState(false);
  const [commentErr, setCommentErr] = useState("");
  const [newPdfLinks, setNewPdfLinks] = useState("");
  const [holdData, setHoldData] = useState<any>([]);
  const [holdEditData, setHoldEditData] = useState("");
  const [holdMetaData, setHoldMetaData] = useState("");
  const [holdMetaId, setHoldMetaId] = useState("");
  const [deleteCommentId, setDeleteCommentId] = useState(null)
  const [commentText, setCommentText] = useState("")
  const [sectionPermission, setSectionPermission] = useState<Permission>()
  const [clausePermission, setClausePermission] = useState<Permission>()
  const [color, setColor] = useState("orange")
  const [editComment, setEditComment] = useState<EditComment>({
    clause_id: null,
    colour: "",
    comment: "",
    contract_id: "",
    createdAt: "",
    creater_id: null,
    id: null,
    section_id: null,
    username: "",
    word: ""
  })

  function wrapWordsInHTML(htmlString, wordsArray) {
    // Extract the words from the array of objects
    const words = wordsArray.map(item => item.word);

    const sortedWords = words?.sort((a, b) => b?.word?.length - a?.word?.length)
    // Convert the array of words to a regular expression pattern
    const pattern = new RegExp(`\\b(${sortedWords.join('|')})\\b`, 'gi');

    // Replace the matched words with the span wrapper
    const wrappedHTML = htmlString.replace(pattern, '<span class="ABC021212">$1</span>');

    return wrappedHTML;
  }

  const wrap_defWord_link = (AgreementData) => {
    let obj;
    setHoldData(
      AgreementData?.map((elem) => {
        let innerObj
        return obj = {
          ...elem, clauses: elem?.clauses?.map((ele) => {
            if (ele?.definations != null && ele?.definations?.length != 0 && !ele?.links) {
              console.log("case 1")
              // let text = ele?.clause
              let text = wrapWordsInHTML(ele?.clause, ele?.definations)
              // ele?.definations?.map((linkObj) => {
              //   text = text?.replaceAll(linkObj?.word, `<span class="ABC021212">${linkObj?.word}</span>`)
              //   return text
              // })
              return innerObj = {
                ...ele, clause: text
              }
            } else if (ele?.links != null && ele?.links?.length != 0 && !ele?.definations) {
              console.log("case 2")

              let text = ele?.clause
              ele?.definations?.map((linkObj) => {
                return text = text?.replaceAll(linkObj?.word, `<span class="ABC021212">${linkObj?.word}</span>`)
              })
              return innerObj = {
                ...ele, clause: text
              }
            }
            else if (ele?.links != null && ele?.links?.length != 0 && ele?.definations != null && ele?.definations?.length != 0) {
              console.log("case 3")
              let text = ele?.clause
              ele?.definations?.map((linkObj) => {
                return text = text?.replaceAll(linkObj?.word, `<span class="ABC021212">${linkObj?.word}</span>`)
              })
              ele?.links?.forEach((ele) => {
                if (ele?.word?.trim() != "") {
                  if (text.includes(`<span class="ABC021212">${ele?.word}</span>`)) {
                    const link = `<span class="ABC021212">${ele?.word}</span>`
                    text = text.split(ele.word).join(link)
                  }
                  else {
                    const link = `<span class="ABC021212">${ele?.word}</span>`
                    text = text.split(ele.word).join(link)
                  }
                }
              });
              return innerObj = {
                ...ele, clause: text
              }
            }
            else {
              return innerObj = { ...ele }
            }
          })
        }
      }))
  }

  const handleClose = () => {
    wrap_defWord_link(getAggrementClauseDetails?.data);
    setShow(false)
  };
  const handleChatShow = () => setChatShow(!chatShow);


  useEffect(() => {
    getIndex?.pdfData?.map((ele) => {
      return setNewPdfLinks(ele?.pdf_url);
    });
  }, [getIndex]);

  const openLinks = () => {
    window.open(newPdfLinks);
  };


  useEffect(() => {
    if (state?.contract_id && checkedValue == "originalpdf") {
      dispatch(getIndexAsync(id));
      dispatch(getOriginalClauseAsync(id));
    } else {
      dispatch(getIndexAsync(id));
      dispatch(getAggrementClauseAsync(id))
    }
    if (Admin != "true") {
      const roleAuth = JSON.parse(localStorage.getItem('roleAuth') || "")
      roleAuth.map((elem) => {
        if (elem?.name.toLowerCase() == "section management") {
          setSectionPermission(elem)
        }
        if (elem?.name.toLowerCase() == "clause management") {
          setClausePermission(elem)
        }
      })
    }
  }, [id, checkedValue]);


  const handleCloseModal = async () => {
    await setShowModal(false);
    await setFormErr({ title: "" });
  };

  const handleShowModal = () => setShowModal(true);
  const handleEditShowModal = () => setShowEditContentModal(true);

  const handleEditCloseModal = async () => {
    setShowEditContentModal(false);
  };

  const scrollToElement = (clause_id: any) => {
    const container = document.getElementById(clause_id);
    container?.scrollIntoView({ behavior: "smooth" });
  };

  const handleTrigger = (clause_id: any) => {
    setIsOpen(!isOpen);
    scrollToElement(clause_id);
  };

  const handleEditChange = (title: any, ele: any) => {
    setHoldEditData(ele?.title);
    setHoldMetaData(ele?.metadata);
    setHoldMetaId(ele?.id);
  };

  const returEle = (elem) => {
    toast.error("Comment is on up to date contract")
    return elem
  }

  const messageShow = () => {
    setShow(true)
    setIsOpen(!isOpen);
    dispatch(getCommentsAsync({ contract_id: (state?.contract_id) }))
  };


  useEffect(() => {
    wrap_defWord_link(getAggrementClauseDetails?.data)
  }, [getAggrementClauseDetails]);

  const handleScrollTrigger = (id: any, word: any) => {
    let obj;
    setHoldData(getAggrementClauseDetails?.data?.map((elem) => {
      if (elem?.id == word?.section_id) {
        let innerObj
        return obj = {
          ...elem, clauses: typeof (elem?.clauses) == "string" ? JSON.parse(elem?.clauses).map((ele) => {
            if (ele?.clause_id == word?.clause_id) {
              return innerObj = {
                ...ele, clause: ele?.clause?.split(word?.word?.trim()).length >= 2 ? ele?.clause?.split(word?.word?.trim())
                  ?.map((part, index) => {
                    return index == 0
                      ? `${part} <span id="scroll" key=${index} class= ${word?.colour == "green" ? "bg-blue" : "bg-yellow"}>${word?.word}</span>`
                      : index == ele?.clause?.split(word?.word?.trim()).length - 1 ? `${part} ` : `${part} ${word?.word?.trim()}`;
                  })
                  .join("")
                  : returEle(ele?.clause)
              }
            } else {
              return innerObj = { ...ele }
            }
          }) : elem?.clauses?.map((ele) => {
            if (ele?.clause_id == word?.clause_id) {
              return innerObj = {
                ...ele, clause: ele?.clause?.split(word?.word?.trim()).length >= 2 ?
                  ele?.clause?.split(word?.word?.trim())
                    ?.map((part, index) => {
                      return index == 0
                        ? `${part} <span id="scroll" key=${index} class= ${word?.colour == "green" ? "bg-blue" : "bg-yellow"}>${word?.word}</span>`
                        : index == ele?.clause?.split(word?.word?.trim()).length - 1 ? `${part} ` : `${part} ${word?.word?.trim()}`;
                    })
                    .join("")
                  : returEle(ele?.clause)
              }
            } else {
              return innerObj = { ...ele }
            }
          })
        }
      } else {
        setTimeout(() => {
          let elem = document.getElementById("scroll")
          elem?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }, 200)
        return obj = { ...elem }
      }
    }))
  }



  useEffect(() => {
    if (selectedText.length == 0) {
      setCommentModal(false)
      setComment("")
      setTitle("")
    }
  }, [selectedText])



  const EditClause = (clause_id: any) => {
    navigate(`/editClauses/${state?.contract_id}`, {
      state: {
        state: "Amendment",
        contract_id: state?.contract_id,
        clause_id: clause_id,
        section_id: sectionId,
        checkedValue: checkedValue
      },
    });
  };

  const addClauseUptoDate = (section_id) => {
    navigate(`/addClauses/${state?.contract_id}`, {
      state: {
        status: "Add Up To Date",
        section_id: section_id,
        contract_id: state?.contract_id,
        title: holdData?.map((elem) => {
          if (elem?.id == section_id) {
            return elem?.title
          }
        })
      }
    })
  }

  const AddClause = (ele: any) => {
    navigate(`/addClauses/${state?.contract_id}`, {
      state: {
        status: "Add",
        contract_id: state?.contract_id,
        Cid: ele?.id,
        title: ele?.title
      },
    });
  };

  const validations = (val) => {
    const errors = { title: "" };
    if (!val.title?.trim()) {
      errors.title = "Title is required!";
    }
    return errors;
  };

  const userData = {
    title: title ? title : "",
  };

  useEffect(() => {
    if (selectedText) {
      const range = window.getSelection()?.getRangeAt(0);
      if (range) {
        const rect = range.getBoundingClientRect();
        const position = {
          top: rect?.bottom + window.scrollY,
          left: rect?.left + window.scrollX + rect?.width / 2,
        };
      }
    }
  }, [selectedText]);

  const textChange = async (e: any) => {
    e.preventDefault();
    setCommentModal(false)
    const errors = validations(userData);
    setFormErr(errors);
    if (Object.values(errors).every((error) => !error)) {
      await dispatch(
        postContentAsync({ title: title, metaData: message, contract_id: state?.contract_id })
      );
      handleCloseModal();
      if (checkedValue === "uptodatePdf") {
        await dispatch(getAggrementClauseAsync(state?.contract_id));
      } else {
        await dispatch(getOriginalClauseAsync(state?.contract_id));
      }
      await dispatch(getIndexAsync(state?.contract_id));
      setTitle("");
    }
  };


  function copySelectedHTML() {
    var selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      var range = selection.getRangeAt(0);
      var container = document.createElement('div');
      var clonedContents = range.cloneContents();

      // Copy computed styles to the cloned elements
      Array.from(clonedContents.querySelectorAll('*')).forEach(copyComputedStyle);

      container.appendChild(clonedContents);
      var htmlContent = container.innerHTML;

      console.log(htmlContent, "_________________htmlContent");

      if (htmlContent) {
        setSelectedText(htmlContent); // Pass the HTML string to the function
      }
    } else {
      setSectionId(null);
      setCid(null);
    }
  }

  function copyComputedStyle(element) {
    var computedStyle = getComputedStyle(element);
    for (var i = 0; i < computedStyle.length; i++) {
      var key = computedStyle[i];
      element.style[key] = computedStyle.getPropertyValue(key);
    }
  }




  const handleMouseUp = (e) => {
    setSectionId(e);
    setComment("")
    copySelectedHTML()
    setCommentText(`${window.getSelection()?.toString()}`)
    setSelect(true);
  };
  const handleMouseDown = (id) => {
    setCid(id)
  }


  const duration = (date1) => {
    const date2: any = new Date();
    const timeDifference = date2 - date1;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
  };


  const handleConfirm = async () => {
    await dispatch(deleteSectionAsync({ contract_detail_id: remove }));
    await dispatch(getOriginalClauseAsync(state?.contract_id));
    await dispatch(getAggrementAsync(state?.contract_id));
    dispatch(getIndexAsync(state?.contract_id));
    setRemove(null);
  };

  const submitComment = async () => {
    if (comment) {
      await dispatch(
        postCommentAsync({
          contract_details_id: ind,
          contract_id: state?.contract_id,
          clause_id: Cid,
          comment: comment?.trim(),
          word: commentText?.replaceAll('  ', '&nbsp;&nbsp;'),
          colour: color
        })
      );
      await setColor('orange')
      await dispatch(getCommentsAsync({ contract_id: Number(state?.contract_id) }));
      await setCid(null);
      await setCommentModal(false);
      await setComment("");
      setCommentErr("");
    } else {
      setCommentErr("required!!");
    }
  };

  const handleEditUpdateData = async () => {
    await dispatch(
      editTitleAsync({
        title: holdEditData,
        metaData: holdMetaData,
        contract_details_id: holdMetaId,
        contract_id: state?.contract_id,
      })
    );
    await dispatch(clearAllState())
    await handleEditCloseModal();
    await dispatch(getAggrementAsync(state?.contract_id));
    await dispatch(getIndexAsync(state?.contract_id));
    await dispatch(getOriginalClauseAsync(state?.contract_id));
  };

  const addCommentModal = (id: number) => {
    holdData?.map((ele) => {
      ele.clauses?.map((elem) => {
        if (elem?.clause_id == id)
          setInd(ele?.id)
      })
    })
    setCommentModal(true);
  };

  const moveForward = (clause_id, section_id, contract_name, section_title) => {
    if (select) {
      navigate(`/clause-details/${id}`, {
        state: {
          text: selectedText,
          clause_id: clause_id,
          contract_id: id,
          section_id: section_id,
          contract_name: contract_name,
          section_name: section_title
        }
      });
    }
  };
  const getTime = (time: any) => {
    if (
      moment(time)?.format("MMMM Do YYYY") ==
      moment(new Date())?.format("MMMM Do YYYY")
    ) {
      return "Today";
    } else {
      return moment(time).format("DD MMM YYYY");
    }
  };

  const handleOriginal = () => {
    setCheckedValue(checkedValue == "originalpdf" ? "uptodatePdf" : "originalpdf")
    setHoldData(getAggrementClauseDetails?.data)
    setCommentModal(false)
    setSelectedText("")
  }

  const dangerFun = (obj) => {
    const mergedArray: any = [];
    let text4 = obj?.clause?.replaceAll("  ", '&nbsp;&nbsp;')
    let text1 = text4?.replaceAll('<span&nbsp;', '<span ')
    let text2 = text1?.replaceAll('<a&nbsp;', '<a ')
    let text = text2?.replaceAll("<strong&nbsp;", "<strong ")
    if (obj?.links?.length !== 0 && obj?.definations?.length !== 0) {
      obj?.definations?.forEach(item1 => {
        const matchedItem = obj?.links?.find(item2 => item1?.word?.trim() === item2?.word?.trim());
        if (matchedItem) {
          mergedArray?.push({
            word: item1.word?.trim(),
            defination: item1?.defination?.trim() || "",
            link: matchedItem?.link?.trim() || ""
          });
        } else {
          mergedArray.push({
            word: item1?.word?.trim(),
            defination: item1?.defination?.trim() || "",
            link: ""
          });
        }
      });

      obj?.links?.forEach(item2 => {
        const matchedItem = obj?.definations?.find(item1 => item2?.word?.trim() === item1?.word?.trim());
        if (!matchedItem) {
          mergedArray.push({
            word: item2?.word?.trim(),
            defination: "",
            link: item2?.link?.trim() || ""
          });
        }
      });
    }

    if (mergedArray?.length !== 0) {
      mergedArray?.map((ele, ind) => {
        let link
        let def = ele?.defination
        if (ele?.link != "" && ele?.defination != "") {
          link = `<overlay class="custom-tooltip" title="${def}"><span class="ABC021212"><a href="${!ele?.link.includes('https') ? 'https://' + ele?.link : ele?.link}" target="_blank">${ele?.word}</a></span></overlay>`
          text = text.split(`<span class="ABC021212">${ele?.word}</span>`).join(link);
        }
        else if (ele?.link != "" && ele?.defination == "") {
          link = `<a href="${!ele?.link.includes('https') ? 'https://' + ele?.link : ele?.link}" target="_blank">${ele?.word}</a>`
          text = text.split(ele?.word).join(link);
        }
        else if (ele?.link == "" && ele?.defination != "") {
          link = `<overlay class="custom-tooltip" title="${def}"><span class="ABC021212">${ele?.word}</span></overlay>`
          text = text?.split(`<span class="ABC021212">${ele?.word}</span>`).join(link);
        }
        else {
          link = ""
          text = text.split(`<span class="ABC021212">${ele?.word}</span>`).join(link);
        }
        // const link = `<overlay class="custom-tooltip" title="${def}"><span class="ABC021212"><a href="${!ele?.link.includes('https') ? 'https://' + ele?.link : ele?.link}" target="_blank">${ele?.word}</a></span></overlay>`
        // text = text.split(`<span class="ABC021212">${ele?.word}</span>`).join(link);
      });
    }
    return <div><div dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>;
  };

  const handleChange = (e) => {
    e.preventDefault()
    setEditComment({ ...editComment, comment: e.target.value })
  }

  const submitEditComment = async () => {
    await dispatch(putCommentAsync({
      comment_id: editComment?.id,
      comment: editComment?.comment?.trim(),
      word: editComment?.word,
      colour: editComment?.colour
    }))
  }

  const handleDeleteComment = async () => {
    await dispatch(deleteCommentAsync(deleteCommentId))
    setDeleteCommentId(null)
    await dispatch(getCommentsAsync({ contract_id: Number(state?.contract_id) }));
  }

  useEffect(() => {
    if (statusMessage == "Successfully updated comment") {
      setEditComment({
        clause_id: null,
        colour: "",
        comment: "",
        contract_id: "",
        createdAt: "",
        creater_id: null,
        id: null,
        section_id: null,
        username: "",
        word: ""
      })
    }
    if (statusMessage == "successfully deleted comment") {
      setDeleteCommentId(null)
    }
    dispatch(getCommentsAsync({ contract_id: Number(state?.contract_id) }))
  }, [statusMessage])



  return (
    <div className="custom-model">

      <ConfirmModal
        show={remove ? true : false}
        message="Are you sure you want to delete this section"
        cancel={() => setRemove(null)}
        confirm={handleConfirm}
      />

      <ConfirmModal
        show={deleteCommentId ? true : false}
        message="Are you sure you want to delete this Comment"
        cancel={() => setDeleteCommentId(null)}
        confirm={handleDeleteComment}
      />

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header className="border-0 text-center" closeButton>
          <Modal.Title>Add Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
            </Form>
            <div className="text-danger">{formErr.title}</div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="custom-btn custom-primary"
            onClick={async (e) => {
              await textChange(e);
            }}
          >
            Submit
          </Button>
          <Button className="btn btn-custom-btn custom-primary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditContentModal} onHide={handleEditCloseModal} centered>
        <Modal.Header className="border-0 text-center" closeButton>
          <Modal.Title>Edit Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  value={holdEditData}
                  onChange={(e) => setHoldEditData(e.target.value)}
                />
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="btn btn-custom-btn custom-primary"
            onClick={
              handleEditUpdateData
            }
          >
            Submit
          </Button>
          <Button className="btn btn-custom-btn custom-primary" onClick={handleEditCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editComment?.comment ? true : false} centered>
        <Modal.Body >
          <div className="commentBox"
          >
            <button className="close border border-0 bg-transparent" onClick={() => setEditComment({
              clause_id: null,
              colour: "",
              comment: "",
              contract_id: "",
              createdAt: "",
              creater_id: null,
              id: null,
              section_id: null,
              username: "",
              word: ""
            })}>{CloseBtn}</button>
            <div>
              <textarea rows={4} value={editComment?.comment} name="comment" onChange={handleChange} placeholder="Write something" className="w-100 border border-1 rounded ps-2 py-2 " ></textarea>
            </div>
            <p>Highlight Color</p>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <div className="d-flex ">
                  <button className={`${editComment?.colour == "green" ? "green_dot_select" : "green_dot"} me-2`} onClick={() => setEditComment({ ...editComment, colour: 'green' })}></button>
                  <button className={`${editComment?.colour == "orange" ? "orange_dot_select" : "orange_dot"} me-2`} onClick={() => setEditComment({ ...editComment, colour: 'orange' })}></button>
                </div>
                <button className="addSend" onClick={submitEditComment}>{AddSend}</button> </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="dashboard-main agreement-mains">
        <div className="message-icon" onClick={messageShow}>
          <MessageIcon />
        </div>
        {show && (
          <div className="custom-sidebar custom-canvas ">
            <div
              className="sidebar-header offcanvas-header"
              onClick={handleClose}
            >
              <h5 className="offcanvas-title">All Comments</h5>
              <svg
                className="btn-close cursoor"
                width="25px"
                height="25px"
                viewBox="0 -0.5 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
                  fill="#000000"
                />
              </svg>
            </div>
            <div className="offcanvas-body cursoor">
              {comments &&
                comments.map((elem: any, ind: any) => {
                  {
                    duration(elem.createdAt);
                  }
                  return (
                    <div
                      className="body-iiner"
                      key={ind}
                    >
                      <div className="d-flex justify-content-between">
                        <CustomHeading text={elem.username} level={4} />
                        <div>
                          <button className="bg-transparent border border-0" onClick={() => {
                            setEditComment(elem);
                          }
                          }>{Edit}</button>
                          <button className="bg-transparent border border-0" onClick={() => setDeleteCommentId(elem?.id)}>{Remove}</button>
                        </div>
                      </div>
                      <p
                        onClick={() =>
                          handleScrollTrigger(elem?.clause_id, elem)
                        }
                        className="d-flex text-start"
                        dangerouslySetInnerHTML={{ __html: elem?.comment }}
                      />
                      <CustomP text={getTime(elem.createdAt)} />
                      <hr />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div className={show ? "show_container" : "hide_container"}>
          <Container style={{ width: "78%" }} >
            <div className="search-outer agreement-search">
              <div className="hub-outer">
                <Link style={{ textDecoration: "none" }} to="/contract-list">
                  <CustomButton
                    classNam="custom-btn custom-primary"
                    text="Go to ContractHub"
                  />
                </Link>
              </div>
              <div className="common-search hub-search">
                <span className="left-icon">
                  <SearchIcon />
                </span>
                <input type="text" value={searchClause} onChange={(e) => setSearchClause(e.target.value)} placeholder="Search your keyword here " />
                <Link to="/search">
                  <span className="right-icon">{/* <ArrowIcon /> */}</span>
                </Link>
              </div>

              <div className="hub-outer add-contract">
                {Role == "true" ?
                  <button
                    className="custom-btn custom-primary"
                    onClick={handleShowModal}
                  >
                    Add Section
                  </button>
                  : sectionPermission?.add ? <button
                    className="custom-btn custom-primary"
                    onClick={handleShowModal}
                  >
                    Add Section
                  </button> : null}
              </div>
            </div>
            <div className="contract-pdf">
              <CustomHeading text="Contract" level={2} />
              <div className="right-pdficon">
                <div className="swich-outer">
                  <span>Original</span>
                  <Form>
                    <Form.Check
                      type="switch"
                      className="cursoor"
                      id="custom-switch"
                      label="Up to date"
                      value={checkedValue}
                      onClick={handleOriginal}
                    />
                  </Form>
                </div>
                <PdfIcon />
                <p onClick={openLinks}>Download PDF</p>
              </div>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="custom-row">
                <div className="custom-leftbar">
                  <div className="leftScroll">
                    <Nav
                      variant="pills"
                      className="flex-column agreement-main trigger pe-auto"
                    >
                      {holdData && holdData.length ? holdData?.map((ele: any, index: any) => {
                        return (
                          <div
                            key={index}
                            className="d-flex w-100 justify-content-between py-auto doted-border"
                          >
                            <p
                              className="py-2 my-auto cursoor"
                              onClick={() => handleTrigger(ele?.id)}
                            >
                              {ele?.title}
                            </p>

                            <div className="d-flex  justify-content-end">
                              {Admin == "true" ? <button
                                title=""
                                className="border border-0 bg bg-transparent"
                                onClick={() => Role == "true" ? navigate(`/clause-position-page/${ele?.id}`, {
                                  state: {
                                    target: "sectionClause",
                                    contract_id: state?.contract_id,
                                    section_id: ele?.id
                                  }
                                }) : null}
                              >
                                {ToggleArrow}
                              </button> : null}
                              {Admin == "true" ? <button
                                title="Add"
                                className="border border-0 bg bg-transparent"
                                onClick={() => AddClause(ele)}
                              >
                                {Add}
                              </button> : clausePermission?.add == true ? <button
                                title="Add"
                                className="border border-0 bg bg-transparent"
                                onClick={() => AddClause(ele)}
                              >
                                {Add}
                              </button> : null}
                              {Admin == "true" || sectionPermission?.edit ? <button
                                title="Edit"
                                className="border border-0 bg bg-transparent"
                                onClick={() => {
                                  handleEditShowModal();
                                  handleEditChange(ele?.title, ele);
                                }}
                              >
                                {edit}
                              </button> : null}
                              {Admin == "true" || sectionPermission?.delete ? < button
                                title="Delete"
                                className="border border-0 bg bg-transparent delete-icon"
                                onClick={() => setRemove(ele?.id)}
                              >
                                {Remove}
                              </button> : null}
                            </div>

                          </div>
                        );
                      })?.sort((a: any, b: any) => { return a?.id - b?.id }) : "No data available"}
                    </Nav>
                  </div>
                </div>
                <div className={`${isOpen ? "custom-row pdf-rightouter" : "onclick-add pdf-rightouter"}`} >
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="first-outer"
                      >
                        <CustomHeading
                          text={getIndex?.pdfData?.map((name) => {
                            return name?.name
                          })}
                          level={2}
                        />
                      </div>
                      <div id="testId" className="element">
                        <div className="pdf-outer" id="pdf-outer">
                          {holdData && holdData.length ?
                            holdData?.map((ele: any, index: any) => {
                              return (
                                <div
                                  key={index}
                                >
                                  <div
                                    id={`heading${index}`}
                                    className="pdf-inner"
                                  >
                                    <b id={ele?.id}
                                    >
                                      {ele?.title}
                                    </b>
                                    <div className="clause"
                                    >
                                      {ele?.clauses && typeof (ele?.clauses) == "object" ? ele?.clauses?.map((elem, ind) => {
                                        return (
                                          <span className="d-flex mb-2">
                                            {elem?.status ? <div className={elem?.status == 1 ? "line_orange" : elem?.status == 2 ? "line_green" : elem?.status == 3 ? "line_red" : "line-black"}></div> : null}
                                            <div className={`d-flex w-100 marker ${Cid == elem?.clause_id && selectedText != '""' && !commentModal ? "selected" : ""}`}>

                                              <Modal show={Cid == elem?.clause_id && selectedText && commentModal ? true : false} centered>
                                                <Modal.Body >
                                                  <div className="commentBox"
                                                  >
                                                    <button className="close border border-0 bg-transparent" onClick={() => setSelectedText("")}>{CloseBtn}</button>
                                                    <div>
                                                      <textarea rows={4} value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Write something" className="w-100 border border-1 rounded ps-2 py-2 " ></textarea>
                                                    </div>
                                                    <span className="text-danger">{commentErr}</span>
                                                    <p>Highlight Color</p>
                                                    <div className="d-flex flex-column">
                                                      <div className="d-flex justify-content-between">
                                                        <div className="d-flex ">
                                                          <button className={`${color == "green" ? "green_dot_select" : "green_dot"} me-2`} onClick={() => setColor('green')}></button>
                                                          <button className={`${color == "orange" ? "orange_dot_select" : "orange_dot"} me-2`} onClick={() => setColor('orange')}></button>
                                                        </div>
                                                        <button className="addSend" onClick={submitComment}>{AddSend}</button> </div>
                                                    </div>
                                                  </div>
                                                </Modal.Body>
                                              </Modal>


                                              <Marker mark={searchClause}>
                                                <div className="d-flex align-item-center gap-1 ">
                                                  {elem?.number ? <p className="mt-0 me-2 ">{elem?.number}</p> : null}
                                                  <p
                                                    className="customClause"
                                                    onMouseDown={() => { handleMouseDown(elem?.clause_id) }}
                                                    onMouseUp={() => handleMouseUp(ele?.id)}
                                                    key={elem?.clause_id}
                                                  >
                                                    {dangerFun(elem)}
                                                  </p>
                                                </div>
                                              </Marker>

                                              {Cid == elem?.clause_id && selectedText != '' && !commentModal ? (
                                                <div
                                                  style={{
                                                    margin: "0",
                                                    backgroundColor: "#ffffff",
                                                    padding: "5px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "4px",
                                                    zIndex: 1000,
                                                    width: "143px",
                                                  }}
                                                  className="popover my-auto"
                                                >
                                                  <button
                                                    className="border border-0 bg-transparent"
                                                    onClick={() =>
                                                      addCommentModal(elem?.clause_id)
                                                    }
                                                  >
                                                    {Comment}
                                                  </button>
                                                  {Role == "true" ? <button
                                                    className="border border-0 bg-transparent"
                                                    onClick={() => EditClause(elem?.clause_id)}
                                                  >
                                                    {Edit}
                                                  </button> : clausePermission?.edit ? <button
                                                    className="border border-0 bg-transparent"
                                                    onClick={() => EditClause(elem?.clause_id)}
                                                  >
                                                    {Edit}
                                                  </button> : null}
                                                  {Role == "true" && checkedValue == "uptodatePdf" ? <button
                                                    className="border border-0 bg-transparent"
                                                    onClick={() => addClauseUptoDate(ele?.id)}
                                                  >
                                                    {Plus}
                                                  </button> : clausePermission?.add && checkedValue == "uptodatePdf" ? <button
                                                    className="border border-0 bg-transparent"
                                                    onClick={() => addClauseUptoDate(ele?.id)}
                                                  >
                                                    {Plus}
                                                  </button> : null}
                                                  <button
                                                    className="border border-0 bg-transparent"
                                                    onClick={() => moveForward(elem?.clause_id, ele?.id, getIndex?.pdfData[0]?.name, ele?.title)}
                                                  >
                                                    {Forward}
                                                  </button>
                                                </div>
                                              ) : null}

                                            </div>
                                          </span>
                                        )
                                      }) : ""}
                                    </div>

                                  </div>
                                </div>
                              );
                            }
                            )
                            : null}
                        </div>
                      </div>
                      <div className="right-pdficon pdf-outer">
                        <PdfIcon />
                        <p onClick={openLinks}>Download PDF</p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </Container>
        </div >
      </div >
      {chatShow == true ? <Chatbot contract_id={state?.contract_id} /> : ""}
      <ChatButton Click={handleChatShow} show={chatShow} />
    </div >
  );
};
export default AgreementDetails;