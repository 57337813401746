import { useState, useEffect } from "react"
import Header from "../components/header/header"
import CustomSpinner from "../components/spinner/spinner"
import { Container } from "react-bootstrap"
import { SearchIcon, ArrowIcon } from "../components/svgicon/svg"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../hook"
import { getRolesAsync } from "../store/Slices/RoleManagementSlice"
import DataTable from "react-data-table-component"
import '../pages/scss/addRole.scss'
import { createUserAsync, getUserDetailAsync, getSingleUserAsync, updateUserAsync, reset } from "../store/Slices/UserManagermentSlice"
import { Form } from "react-bootstrap"
import CommonSelector from "../components/ReactSelector/CommonSelector"
import { useLocation } from "react-router-dom"
import Select, { StylesConfig } from 'react-select';





const EditUserPage = () => {
    const dispatch = useAppDispatch()
    const { userDetail, message, statusCode } = useAppSelector((state): any => state?.userManagement)
    const { roles } = useAppSelector((state): any => state?.RoleManagement)
    const navigate = useNavigate()
    const { state } = useLocation()
    const [userAuthority, setUserAuthority] = useState()
    const [currentRole, setCurrentRole] = useState({})
    const [first_name, setFirst_name] = useState("")
    const [last_name, setLast_name] = useState("")
    const [email, setEmail] = useState("")
    const [roleId, setRoleId] = useState(null)
    const [userError, setUserError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        select: ""
    })


    const [roleOption, setRoleOption] = useState([])
    useEffect(() => {
        dispatch(getSingleUserAsync(state?.id))
        dispatch(getRolesAsync())
    }, [state])

    useEffect(() => {
        setRoleOption(roles?.map((elem) => {
            return {
                value: elem.id,
                label: elem.name
            }
        }))
    }, [roles])
    useEffect(() => {
        userDetail?.map((elem) => {
            setFirst_name(elem?.firstname)
            setLast_name(elem?.lastname)
            setRoleId(elem?.role_id)
            setCurrentRole({ label: elem?.role_name, value: elem?.role_id })
            setEmail(elem?.email)
        })
    }, [userDetail])


    const validate = () => {
        let valid = true
        let Validations = {
            first_name: "",
            last_name: "",
            email: "",
            select: ""
        }
        if (first_name == "") {
            Validations.first_name = "Field can not be empty"
            valid = false
        }
        if (last_name == "") {
            Validations.last_name = "Field can not be empty"
            valid = false
        }
        if (email == "") {
            Validations.email = "Field can not be empty"
            valid = false
        }
        if (!currentRole) {
            Validations.select = "Field can not be empty"
            valid = false
        }
        setUserError({ ...Validations })
        return valid

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await dispatch(updateUserAsync({
            role: roleId,
            id: state?.id
        }))
    }

    useEffect(() => {
        if (message.trim() == "successfully updated role" && statusCode == 200) {
            dispatch(reset())
            navigate('/user-management')
        }
    }, [message, statusCode])

    const handleSelect = (e) => {
        setCurrentRole({ label: e.label, value: e.value })
        setRoleId(e.value)
    }


    return (
        <>

            <div className="dashboard-main">
                <Container>
                    <div className="my-5 ">
                        <div className="">
                            <h3><b>{state?.action == 'view' ? 'View' : 'Edit'} User</b></h3>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-48 d-flex flex-column ">
                            <label className="text-dark mb-2">First Name</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="first_name" type="text" placeholder="Enter first Name" value={first_name} onChange={(e) => setFirst_name(e.target.value)} disabled={state?.action == "view" ? true : false} />
                            <p className="text-danger">{userError?.first_name}</p>
                        </div>
                        <div className="w-48 d-flex flex-column ">
                            <label className="text-dark mb-2">Last Name</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="last_name" type="text" placeholder="Enter last Name" value={last_name} onChange={(e) => setLast_name(e.target.value)} disabled={state?.action == "view" ? true : false} />
                            <p className="text-danger">{userError?.last_name}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-48 d-flex flex-column mb-3">
                            <label className="text-dark mb-2">Email</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="email" type="text" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={state?.action == 'view' ? true : false} />
                            <p className="text-danger">{userError?.email}</p>
                        </div>
                        <div className={state?.action == 'view' ? "w-48 d-flex flex-column mb-3 justify-content-center" : "d-flex flex-column w-48 "} >
                            <label className="mb-2">User Role</label>
                            {state?.action == "edit" ? <Select options={roleOption} onChange={handleSelect} value={currentRole} /> : <Select options={roleOption} value={currentRole} />}
                            <p className="text-danger">{userError?.select}</p>
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-center mt-5">
                        {state?.action == 'edit' ? <button className="custom-btn custom-primary me-3" onClick={handleSubmit}>Submit</button> : null}
                        <button className="custom-btn custom-primary me-3" onClick={() => navigate('/user-management')}>Back</button>
                    </div>
                </Container >
            </div >
        </>
    )
}

export default EditUserPage