import React, { useEffect } from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../pages/scss/contractlist.scss";
import {
  getContractHubAsync,
  postContractHubAsync,
  favoriteAsync,
  reset,
  updateContractAsync,
  deleteContractAsync,
  deleteFromML,
} from "../store/Slices/contractHubSlice";
import {
  SearchIcon,
  SortIcon,
  TableStarIcon,
  TableStarIconFilled,
} from "../components/svgicon/svg";
import { Edit, Remove } from "../assets/icons";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../hook";
import ChatButton from "../components/chatbox/chatIcon";
import Chatbot from "../components/chatbox/chatBot/chatBot";
import CustomSpinner from "../components/spinner/spinner";
import ConfirmModal from "../components/confirmModal";
import SearchToggleIcon from '../assets/ArrowIconBlue.png'
import { Permission } from "../Interface";







const ContractList = () => {
  const domain = window.location.hostname
  const Admin = localStorage.getItem('is_admin')
  const Role: any = localStorage.getItem("role");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const [contract, setContract] = useState({
    name: "",
    description: "",
    date: "",
  });
  const [image, setImage] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [editPdf, setEditPdf] = useState("");
  const [editPdfName, setEditPdfName] = useState("");
  const [sort, setSort] = useState("");
  const [chatShow, setChatShow] = useState(false)
  const [remove, setRemove] = useState(null);
  const [validations, setValidations] = useState({
    name: "",
    description: "",
    date: "",
    pdfUpload: "",
  });
  const [textareaError, setTextareaError] = useState("");
  const [editShow, setEditShow] = useState(false);
  const [permissions, setPermissions] = useState<Permission>()
  const [editDetails, setEditDetails] = useState({
    createdAt: "",
    creater_id: null,
    date: "",
    pdf_url: "",
    description: "",
    id: "",
    isFavorite: null,
    name: "",
    updatedAt: "",
  });

  const [err, setErr] = useState({
    name: "",
    description: "",
    pdfUpload: "",
  });

  const [submit, setSubmit] = useState(false);
  const handleClose = () => {
    setShow(false);
    setContract({
      name: "",
      description: "",
      date: "",
    });
    setValidations({
      name: "",
      description: "",
      date: "",
      pdfUpload: "",
    });
    setImage("");
    setPdfName("");
  };
  const handleChatShow = () => setChatShow(!chatShow);
  const handleShow = () => setShow(true);
  const { getContractDetails, message, manageAdminloading, postContractDetails } = useAppSelector(
    (state: any) => state?.getAllContracts
  );

  const handleFiles = (img) => {
    if (img[0].type !== "application/pdf") {
      setErr({ ...err, pdfUpload: "Unsupported file type..." });
      setImage("");
      setPdfName("");
    } else {
      setPdfName(img[0]);
      setImage(URL.createObjectURL(img[0]));
      setErr({
        name: "",
        description: "",
        pdfUpload: "",
      });
    }
  };



  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    preventDefaults(event);
    handleFiles(event.dataTransfer.files);
  };

  const preventDefaults = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnterOrOver = (event: React.DragEvent<HTMLDivElement>) => {
    preventDefaults(event);
  };

  const handleEditFiles = (img) => {
    if (img[0].type !== "application/pdf") {
      setErr({ ...err, pdfUpload: "Unsupported file type..." });
      setEditPdf("");
      setEditPdfName("");
    } else {
      setEditPdfName(img[0]);
      setEditPdf(URL.createObjectURL(img[0]));
    }
  };



  const handleEditDrop = (event: React.DragEvent<HTMLDivElement>) => {
    preventDefaults(event);
    handleEditFiles(event.dataTransfer.files);
  };

  useEffect(() => {
    dispatch(
      getContractHubAsync({
        searchstring: search,
        limit: perPage,
        page: page,
        sort: sort,
      })
    );

  }, [search, perPage, page, sort, message]);

  useEffect(() => {
    if (Admin != "true") {
      const roleAuth = JSON.parse(localStorage.getItem('roleAuth') || "")
      roleAuth.map((elem) => {
        if (elem?.name.toLowerCase() == "contract hub") {
          setPermissions(elem)
        }
      })
    }
  }, [Admin])


  const handleEdit = (data: any) => {
    setEditDetails(data);
    setEditShow(true);
  };

  const submitConfirm = async () => {
    await dispatch(
      updateContractAsync({
        title: editDetails?.name,
        description: editDetails?.description,
        date: editDetails?.date,
        pdfUpload: editPdfName,
        id: editDetails?.id,
      })
    );
    await dispatch(
      getContractHubAsync({
        searchstring: search,
        limit: 10,
        page: 1,
        sort: sort,
      })
    );
  };

  const textChange = async () => {
    if (validation(contract)) {
      await dispatch(
        postContractHubAsync({
          name: contract.name,
          description: contract.description,
          pdfUpload: pdfName,
          date: contract?.date,
        })
      );
      await dispatch(
        getContractHubAsync({
          searchstring: search,
          limit: 10,
          page: 1,
          sort: sort,
        })
      );
      setContract({
        name: "",
        description: "",
        date: "",
      });
      setValidations({
        name: "",
        description: "",
        date: "",
        pdfUpload: "",
      });
      setImage("");
      setPdfName("");
      handleClose();
    }
  };

  useEffect(() => {
    if (getContractDetails?.statusCode == 200) {
      setEditDetails({
        createdAt: "",
        creater_id: null,
        date: "",
        pdf_url: "",
        description: "",
        id: "",
        isFavorite: null,
        name: "",
        updatedAt: "",
      });
      setEditShow(false);
    }
    setTimeout(() => {
      dispatch(getContractHubAsync);
    }, 300);
  }, [getContractDetails]);

  const validation = (value) => {
    let valid = true;
    const validationError = {
      name: "",
      description: "",
      date: "",
      pdfUpload: "",
    };
    if (value.name == "") {
      validationError.name = "Enter Title";
      valid = false;
    }
    if (value.description == "") {
      validationError.description = "Enter Parties";
      valid = false;
    }
    if (value.date == "") {
      validationError.date = "Select Date";
      valid = false;
    }
    if (!valid) {
      setValidations(validationError);
    }
    return valid;
  };

  const columns: any = [
    {
      name: "Contract",
      selector: (row: any) =>
        (
          <span
            onClick={() => {
              navigate(`/agreement-details/${row?.id}`, {
                state: {
                  contract_id: row?.id
                }
              });
            }}
          >
            {row.name}
          </span>
        ) || "N/A",
      sortable: false,
      sortField: "contract",
    },
    {
      name: "Date",
      selector: (row: any) =>
        moment(row?.date).format("DD MMM YYYY") || "N/A",
      sortable: true
    },
    {
      name: "Parties",
      selector: (row: any) =>
        (
          <div className="d-flex">
            <span
              dangerouslySetInnerHTML={{
                __html: `${row?.description?.substr(0, 135)}`,
              }}
            ></span>
          </div>
        ) || "N/A",
      sortable: false,
      sortField: "parties",
    },

    {
      name: "",
      selector: (row: any) =>

        <div className="d-flex justify-content-between">
          <button
            onClick={() => {
              dispatch(reset());
              dispatch(
                favoriteAsync({
                  contract_id: row?.id,
                  isFavorite: row?.isFavorite ? 0 : 1,
                })
              );
            }}
            className="staricon bg bg-transparent border border-0"
          >
            {row?.isFavorite ? <TableStarIconFilled /> : <TableStarIcon />}
          </button>
          {permissions && permissions?.edit ? <button
            className=" bg bg-transparent border border-0"
            onClick={() => handleEdit(row)}
          >
            {Edit}
          </button> : Admin == 'true' ? <button
            className=" bg bg-transparent border border-0"
            onClick={() => handleEdit(row)}
          >
            {Edit}
          </button> : null}
          {permissions && permissions?.delete ? <button
            className=" bg bg-transparent border border-0"
            onClick={() => setRemove(row.id)}
          >
            {Remove}
          </button> : Admin == 'true' ? <button
            className=" bg bg-transparent border border-0"
            onClick={() => setRemove(row.id)}
          >
            {Remove}
          </button> : null}
        </div>
      ,
      sortable: true,
      sortField: "",
    },
  ];

  const Tablepagination = (page: any) => {
    setPage(page);
  };

  const handlePerRowsChange = async (per_page: any) => {
    setPerPage(per_page);
  };
  const handleChange = (e: any) => {
    setDescription(e.target.value);
  };
  const handleConfirm = async () => {
    await dispatch(deleteContractAsync({ contract_id: remove }));
    await dispatch(deleteFromML({
      database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0],
      id: remove
    }))
    await setRemove(null);
    await dispatch(
      getContractHubAsync({
        searchstring: search,
        limit: perPage,
        page: page,
        sort: sort,
      })
    );
  };

  const closeEditModal = () => {
    setEditShow(false);
    setEditPdf("");
    setEditPdfName("");
  };


  return (
    <>
      {manageAdminloading && (
        <div className="custom-spinner-container">
          <CustomSpinner />
        </div>
      )}
      <ConfirmModal
        show={remove ? true : false}
        message="Are you sure you want to delete this Contract"
        cancel={() => setRemove(null)}
        confirm={handleConfirm}
      />
      <Modal show={editShow} onHide={closeEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Enter Title"
                  value={editDetails?.name}
                  onChange={(e) =>
                    setEditDetails({ ...editDetails, name: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
            {textareaError && (
              <div className="text-danger">{textareaError}</div>
            )}
          </div>
          <div>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Parties</Form.Label>
                <Form.Control
                  name="description"
                  type="text"
                  placeholder="Enter Description"
                  value={editDetails?.description}
                  onChange={(e) =>
                    setEditDetails({
                      ...editDetails,
                      description: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
            {textareaError && (
              <div className="text-danger">{textareaError}</div>
            )}
          </div>
          <div>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Date</Form.Label>
                <Form.Control
                  name="createdAt"
                  type="date"
                  placeholder={editDetails?.date}
                  value={moment(editDetails?.date).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setEditDetails({
                      ...editDetails,
                      date: moment(e.target.value).format("YYYY-MM-DD"),
                    })
                  }
                />
              </Form.Group>
            </Form>
            {textareaError && (
              <div className="text-danger">{textareaError}</div>
            )}
          </div>
          <div className="my-4">
            <p>Upload Pdf</p>
            <div
              id="drop-area"
              onDragOver={handleDragEnterOrOver}
              onDragEnter={handleDragEnterOrOver}
              onDragLeave={handleDragEnterOrOver}
              onDrop={handleEditDrop}
            >
              <input
                type="file"
                id="fileElem"
                accept="pdf/*"
                onChange={(e) => {
                  handleEditFiles(e.target.files);
                }}
              />
              <label className="upload-label" htmlFor="fileElem">
                <div className="upload-text">
                  Drag pdf here or click to upload(optional)
                </div>
                <span className="text-danger">{err.pdfUpload}</span>
              </label>
              {editPdf ? (
                <div className="imageContainer">
                  <embed
                    src={editPdf}
                    className="pdf"
                    onClick={() => setImage("")}
                  />
                </div>
              ) : editDetails?.pdf_url ? (
                <div className="imageContainer">
                  <embed
                    src={editDetails?.pdf_url}
                    className="pdf"
                    onClick={() => setImage("")}
                  />
                </div>
              ) : null}
            </div>
            <div className="text-danger">{validations.pdfUpload}</div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Button className="custom-btn custom-primary btn btn-primary w-25" onClick={submitConfirm}>Save</Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  name="title"
                  type="text"
                  placeholder="Enter Title"
                  value={contract.name}
                  onChange={(e) =>
                    setContract({ ...contract, name: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
            <div className="text-danger">{validations?.name}</div>
          </div>
          <div className="my-4">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Parties</Form.Label>
                <Form.Control
                  name="description"
                  type="text"
                  placeholder="Enter Parties"
                  value={contract?.description}
                  onChange={(e) =>
                    setContract({ ...contract, description: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
            <div className="text-danger">{validations?.description}</div>
          </div>
          {/* dragArea start*/}

          <div>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Date</Form.Label>
                <Form.Control
                  name="createdAt"
                  type="date"
                  placeholder="Select Date"
                  value={moment(contract?.date).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setContract({
                      ...contract,
                      date: moment(e.target.value).format("YYYY-MM-DD"),
                    })
                  }
                // onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                />
              </Form.Group>
            </Form>
            <div className="text-danger">{validations.date}</div>
          </div>

          <div className="my-4">
            <p>Upload Pdf</p>
            <div
              id="drop-area"
              onDragOver={handleDragEnterOrOver}
              onDragEnter={handleDragEnterOrOver}
              onDragLeave={handleDragEnterOrOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="fileElem"
                accept="pdf/*"
                onChange={(e) => {
                  handleFiles(e.target.files);
                }}
              />
              <label className="upload-label" htmlFor="fileElem">
                <div className="upload-text">
                  Drag pdf here or click to upload(Optional)
                </div>
                <span className="text-danger">{err.pdfUpload}</span>
              </label>
              {image ? (
                <div className="imageContainer">
                  <embed
                    src={image}
                    className="pdf"
                    onClick={() => setImage("")}
                  />
                </div>
              ) : null}
            </div>
            <div className="text-danger">{validations.pdfUpload}</div>
          </div>

          {/*DragArea end  */}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="custom-btn custom-primary"
            onClick={textChange}>
            Add
          </Button>
          <Button className="custom-btn custom-primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="dashboard-main">
        <Container>
          <div className="search-outer contrast-list">
            <div className="common-search">
              <span className="left-icon">
                <SearchIcon />
              </span>
              <input
                type="text"
                placeholder="Search your document here"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Link to="/search">
                <span className="right-icon"><img src={SearchToggleIcon} alt="searchArrowIcon" className="searchArrow" /></span>
              </Link>
            </div>
            <div className=" hub-outer">
              {Admin == 'true' || (permissions && permissions?.add) ? <button
                className="custom-btn custom-primary"
                onClick={handleShow}
              >
                Add Contract
              </button> : null}
            </div>
            <div className="custom-drop">
              <SortIcon />
              <NavDropdown title="Sort By" id="basic-nav-dropdown">
                <NavDropdown.Item href="#" onClick={() => setSort("ASC")}>
                  A-Z
                </NavDropdown.Item>
                <NavDropdown.Item href="#" onClick={() => setSort("DESC")}>
                  Z-A
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
          <div className="data-table custom-datatable">
            <DataTable
              columns={columns}
              data={getContractDetails?.data}
              sortServer={false}
              pagination
              paginationServer
              paginationTotalRows={getContractDetails?.TotalData}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
              onChangePage={Tablepagination}
            />
          </div>
        </Container >
        {chatShow == true ? <Chatbot contract_id={'all'} /> : ""}
        <ChatButton Click={handleChatShow} show={chatShow} />
      </div >
    </>
  );
};
export default ContractList;