import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAdminApi, deletAdminApi, getAdminProfileApi, getAllAdminListingApi, getSingleAdminApi, updateAdminApi, updateAdminProfileApi } from '../service'
import * as constants from "../../Constants";
import { reset } from "./contractHubSlice";

const initialState = {
    getAggrementDetails: constants.NULL,
    status: constants.IDLE,
    signErr: constants.NULL,
    manageAdminloading: constants.FALSE,
    error1: "",
    userList: constants.NULL,
    userDetail: constants.NULL,
    profileDetail: constants.NULL,
    statusCode: constants.NULL,
    message: ""
};

export const getAdminDetailAsync = createAsyncThunk("get/allUserListing", async (data: any) => {
    const response: any = await getAllAdminListingApi(data);
    return response
}
);

export const getSingleAdminAsync = createAsyncThunk("get/singleUserListing", async (id: number) => {
    const response: any = await getSingleAdminApi(id);
    return response
}
);

export const createAdminAsync = createAsyncThunk("create/user", async (data: any) => {
    const response: any = await createAdminApi(data);
    return response
}
);

export const updateAdminAsync = createAsyncThunk("put/user", async (data: any) => {
    const response: any = await updateAdminApi(data);
    return response
}
);

export const deleteAdminAsync = createAsyncThunk("delete/user", async (id: any) => {
    const response: any = await deletAdminApi(id);
    return response
}
);

export const getAdminProfileAsync = createAsyncThunk("get/Adminprofile", async () => {
    const response: any = await getAdminProfileApi();
    return response
}
);

export const postAdminProfileAsync = createAsyncThunk(
    "postprofileData/postProfile",
    async (data: any) => {
        const response = await updateAdminProfileApi(data);
        return response
    }
);


export const userSlice = createSlice({
    name: "Index Data",
    initialState,
    reducers: {
        resetAdmin: (state) => {
            state.message = ""
            state.statusCode = constants.NULL
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdminDetailAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(getAdminDetailAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.userList = action.payload;
            })
            .addCase(getAdminDetailAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            .addCase(getSingleAdminAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(getSingleAdminAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.userDetail = action.payload;
            })
            .addCase(getSingleAdminAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            .addCase(createAdminAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(createAdminAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.statusCode = action.payload.statusCode;
                state.message = action.payload.message
            })
            .addCase(createAdminAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            .addCase(updateAdminAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(updateAdminAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.message = action.payload.message;
                state.statusCode = action.payload.statusCode
            })
            .addCase(updateAdminAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            // Delete User
            .addCase(deleteAdminAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(deleteAdminAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
            })
            .addCase(deleteAdminAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            // get Admin Profile
            .addCase(getAdminProfileAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(getAdminProfileAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.profileDetail = action.payload;
            })
            .addCase(getAdminProfileAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
    },
});

export const { resetAdmin } = userSlice.actions;
export default userSlice.reducer;