import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getContractHubApi,
  postContractHubApi,
  favoriteApi,
  contractHistoryApi,
  contractNotesApi,
  contractPostNotesApi,
  contractEditNotesApi,
  contractDeleteNotesApi,
  getChatHistoryApi,
  deleteChatHistoryApi,
  deleteAllChatApi,
  updateContractApi,
  deleteContractApi,
  searchChatHistoryApi,
  postToMLApi,
  deleteFromMLApi,
  getChatBotHistoryApi
} from "../service";

const initialState = {
  getContractDetails: constants.NULL,
  postContractDetails: constants.NULL,
  getNotes: constants.NULL,
  postNotes: constants.NULL,
  putNotes: constants.NULL,
  status: constants.IDLE,
  signErr: constants.NULL,
  dashBoardLoading: constants.FALSE,
  error1: "",
  message: "",
  chatHistory: constants.NULL,
  chatBotHistory: constants.NULL,
  dataDeletedMesage: ""
};

export const getContractHubAsync = createAsyncThunk(
  "Data/manageContract",
  async (data: any) => {
    const response: any = await getContractHubApi(data);
    return response;
  }
);

export const postContractHubAsync = createAsyncThunk(
  "postData/postManageContract",
  async (data: any) => {
    const response = await postContractHubApi(data);
    return response;
  }
);

export const postPdfToMLAsync = createAsyncThunk(
  "post/toMlDatabase",
  async (data: any) => {
    const response = await postToMLApi(data);
    return response;
  }
);
export const deleteFromML = createAsyncThunk(
  "delete/fromMlDB",
  async (data: any) => {
    const response = await deleteFromMLApi(data);
    return response;
  }
);


export const favoriteAsync = createAsyncThunk(
  "set/favorite",
  async (data: any) => {
    const response = await favoriteApi(data);
    return response;
  }
);

export const contractHistoryAsync = createAsyncThunk(
  "get/contractHistory",
  async (data: any) => {
    const response = await contractHistoryApi(data);
    return response;
  }
);

export const getNotesAsync = createAsyncThunk("get/contractNotes", async () => {
  const response = await contractNotesApi();
  return response;
});

export const postNotesAsync = createAsyncThunk(
  "post/contractNotes",
  async (data: any) => {
    const response = await contractPostNotesApi(data);
    return response;
  }
);

export const putNotesAsync = createAsyncThunk(
  "put/contractNotes",
  async (data: any) => {
    const response = await contractEditNotesApi(data);
    return response;
  }
);

export const deleteNotesAsync = createAsyncThunk(
  "put/contractNotes",
  async () => {
    const response = await contractDeleteNotesApi();
    return response;
  }
);

export const searchChatHistoryAsync = createAsyncThunk(
  "search/chatHistory",
  async (data: any) => {
    const response: any = await searchChatHistoryApi(data);
    return response;
  }
);

export const getChatHistoryAsync = createAsyncThunk(
  "get/chatHistory",
  async (data: any) => {
    const response: any = await getChatHistoryApi(data);
    return response;
  }
);

export const getChatBotHistoryAsync = createAsyncThunk(
  "get/chatBotHistory",
  async (data: any) => {
    const response: any = await getChatBotHistoryApi(data);
    return response;
  }
);

export const deleteChatHistoryAsync = createAsyncThunk(
  "delete/chatHistory",
  async (data: any) => {
    const response: any = await deleteChatHistoryApi(data);
    return response;
  }
);

export const clearChatHistoryAsync = createAsyncThunk(
  "clear/chatHistory",
  async () => {
    const response: any = await deleteAllChatApi();
    return response;
  }
);

export const updateContractAsync = createAsyncThunk(
  "update/ContractHub",
  async (data: any) => {
    const response: any = await updateContractApi(data);
    return response;
  }
);

export const deleteContractAsync = createAsyncThunk(
  "delete/ContractHub",
  async (data: any) => {
    const response: any = await deleteContractApi(data);
    return response;
  }
);

export const contractHubSlice = createSlice({
  name: "adminData",
  initialState,
  reducers: {
    reset: (state) => {
      state.message = "";
      state.dataDeletedMesage = ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractHubAsync.pending, (state) => {
        // state.dashBoardLoading = constants.TRUE;
      })
      .addCase(getContractHubAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.getContractDetails = action.payload;
      })
      .addCase(getContractHubAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // delete Contract
      .addCase(deleteFromML.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(deleteFromML.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dataDeletedMesage = action.payload.message;
      })
      .addCase(deleteFromML.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // post Contract
      .addCase(postContractHubAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(postContractHubAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.postContractDetails = action.payload;
      })
      .addCase(postContractHubAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // favorite Contract
      .addCase(favoriteAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(favoriteAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        // state.postContractDetails = action.payload;
        state.message = action.payload;
      })
      .addCase(favoriteAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      .addCase(contractHistoryAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(contractHistoryAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.postContractDetails = action.payload;
        state.message = action.payload.message;
      })
      .addCase(contractHistoryAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })

      // getNotes
      .addCase(getNotesAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(getNotesAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.getNotes = action.payload;
        state.message = action.payload.message;
      })
      .addCase(getNotesAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })

      // PostNotes
      .addCase(postNotesAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(postNotesAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.postNotes = action.payload;
        state.message = action.payload.message;
      })
      .addCase(postNotesAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // EditNotes
      .addCase(putNotesAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(putNotesAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.putNotes = action.payload;
        state.message = action.payload.message;
      })
      .addCase(putNotesAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // getChatHistory
      .addCase(getChatHistoryAsync.pending, (state) => {
        // state.dashBoardLoading = constants.TRUE;
      })
      .addCase(getChatHistoryAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.chatHistory = action.payload;
        // state.message = action.payload.message
      })
      .addCase(getChatHistoryAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // getChatBotHistory
      .addCase(getChatBotHistoryAsync.pending, (state) => {
        // state.dashBoardLoading = constants.TRUE;
      })
      .addCase(getChatBotHistoryAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.chatBotHistory = action.payload;
        // state.message = action.payload.message
      })
      .addCase(getChatBotHistoryAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // searchChatHistoryApi// getChatHistory
      .addCase(searchChatHistoryAsync.pending, (state) => {
        // state.dashBoardLoading = constants.TRUE;
      })
      .addCase(searchChatHistoryAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.chatHistory = action.payload;
        // state.message = action.payload.message
      })
      .addCase(searchChatHistoryAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // delete Chat History
      .addCase(deleteChatHistoryAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(deleteChatHistoryAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.message = action.payload.message;
      })
      .addCase(deleteChatHistoryAsync.rejected, (state) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // clear Chat History
      .addCase(clearChatHistoryAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(clearChatHistoryAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.message = action.payload.message;
      })
      .addCase(clearChatHistoryAsync.rejected, (state) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      .addCase(updateContractAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(updateContractAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.message = action.payload.message;
      })
      .addCase(updateContractAsync.rejected, (state) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      .addCase(deleteContractAsync.pending, (state) => {
        state.dashBoardLoading = constants.TRUE;
      })
      .addCase(deleteContractAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.message = action.payload.message;
      })
      .addCase(deleteContractAsync.rejected, (state) => {
        state.status = constants.IDLE;
        state.dashBoardLoading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      });
  },
});

export const { reset } = contractHubSlice.actions;
export default contractHubSlice.reducer;
