import React from "react";
const CustomHeading = (props :{level:Number,text:String}) => {
  
  
    const Header:any = `h${props.level}`

  return (
  <Header >{props.text}</Header>
  )
};
export default CustomHeading;
