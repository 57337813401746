import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminForgotPasswordApi, ForgotPasswordApi } from "../service";

const initialState = {
  postForgotpassword: null,
  status: constants.IDLE,
  signErr: null,
  manageAdminloading: constants.FALSE,
  error1: "",
};

export const forgotPasswordAsync = createAsyncThunk("forgotData/forgot", async (data: any, thunkapi) => {
  const response = await ForgotPasswordApi(data);
  return response
}
);

export const adminForgotPasswordAsync = createAsyncThunk("admin/forgot", async (data: any, thunkapi) => {
  const response = await adminForgotPasswordApi(data);
  return response
}
);

export const forgotPasswordSlice = createSlice({
  name: "forgotPasswordData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(forgotPasswordAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.postForgotpassword = action.payload;
      })
      .addCase(forgotPasswordAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      .addCase(adminForgotPasswordAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(adminForgotPasswordAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.postForgotpassword = action.payload;
      })
      .addCase(adminForgotPasswordAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      });
  },
});

export default forgotPasswordSlice.reducer;