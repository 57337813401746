import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./chatBot.scss";
import { questionAnswerAsync, questionAnswerPerticularAsync, postQueryAsync, reset } from "../../../store/Slices/clauseSlice";
import { useAppDispatch, useAppSelector } from "../../../hook";
// import Spinner from "../../spinner/BotSpinner";
// import CustomSpinner from "../../spinner/spinner";
import { Spinner } from "react-bootstrap";
import { getChatBotHistoryAsync } from "../../../store/Slices/contractHubSlice";

const Chatbot = (props: any) => {
  const domain = window.location.hostname
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState("");
  const [textareaError, setTextareaError] = useState("");
  const [width, setWidth] = useState(550); // Initial width of the window
  const [height, setHeight] = useState(550); // Initial height of the window
  const [search, setSearch] = useState("");
  const [onSendMessage, setOnSendMessage] = useState(false)
  const ref = useRef<HTMLDivElement>(null);
  const { quesAns, isLoading } = useAppSelector(
    (state: any) => state?.clauseDetails
  );

  const { chatBotHistory } = useAppSelector(
    (state: any) => state?.getAllContracts
  );
  const { chatHistory } = useAppSelector(
    (state: any) => state?.clauseDetails
  );

  // expand chatbot code start here
  const handleMouseDown = (event) => {
    event.preventDefault();
    const initialX = event.clientX;
    const initialY = event.clientY;

    const handleMouseMove = (event) => {
      const deltaX = initialX - event.clientX;
      const deltaY = initialY - event.clientY;
      setWidth(width + deltaX);
      setHeight(height + deltaY);
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "end", });
      setTimeout(() => {
        setOnSendMessage(false)
      }, 300)
    }, 400);
  }, [chatBotHistory, onSendMessage])

  const textChange = async () => {
    if (!inputValue || inputValue.trim() === "") {
      setTextareaError("Message is required!");
    } else {
      setTextareaError("");
      if (props.contract_id != 'all') {
        await dispatch(questionAnswerPerticularAsync({
          query: inputValue,
          database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0],
          name_space: `${props?.contract_id}`
        }))
      } else {
        dispatch(questionAnswerAsync({
          database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0],
          query: inputValue
        }));
        dispatch(getChatBotHistoryAsync({ searchString: search }));
      }
      setInputValue("");
    }
    // ref.current?.scrollIntoView({ behavior: "smooth", block: "end", });
    setOnSendMessage(true)
  };

  useEffect(() => {
    dispatch(getChatBotHistoryAsync({ searchString: search }));
    setTimeout(() => {
      scrollToElement();
    }, 200);
  }, []);

  const scrollToElement = () => {
    ref.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    if (quesAns && quesAns?.result && quesAns?.query) {
      dispatch(postQueryAsync({
        "Question": quesAns?.query,
        "Answer": quesAns?.result
      }))
      setTimeout(() => {
        dispatch(getChatBotHistoryAsync({ searchString: search }));
      }, 700)
      // dispatch(reset())
    }
  }, [quesAns]);


  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
    setTextareaError(""); // Clear the validation error when the user starts typing
  };

  return (
    <section className="chatbot"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        // border: '1px solid black',
        // resize: 'both', // This enables resizing cursor
        // overflow: 'auto', // This ensures scrollbars appear if content overflows the window
      }}
    >
      <div
        style={{
          height: `${height}px`
        }}
        className="py-5 position-relative h-100">
        <div className="chatbot-inner">
          <div className="card h-100" id="chat1" style={{ borderRadius: "15px" }}>
            <div
              onMouseDown={handleMouseDown}
              className="card-header d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
              style={{
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              }}
            >
              <i className="fas fa-angle-left"></i>
              <p className="mb-0 fw-bold">Live chat</p>
              <i className="fas fa-times"></i>
            </div>
            <div className="card-body">
              {chatBotHistory
                ? chatBotHistory.data.map((ele: any, indexx: any) => {
                  return (
                    <>
                      <div
                        key={indexx}
                        className="d-flex flex-row justify-content-end mb-4 card-body-inner"
                      >
                        <div
                          className="p-3 me-3 border"
                          style={{
                            borderRadius: "15px",
                            backgroundColor: "#fbfbfb",
                          }}
                        >
                          <p className="small mb-0">{ele?.Question}</p>
                        </div>
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                          alt="avatar 1"
                        />
                      </div>
                      <div
                        className="d-flex flex-row justify-content-start mb-4 card-body-inner"
                      >
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                          alt="avatar 1"
                        />
                        <div
                          id={ele.id}
                          ref={ref}
                          className="p-3 ms-3"
                          style={{
                            borderRadius: "15px",
                            backgroundColor: "rgb(0 151 208)",
                            color: "#fff",
                          }}
                        >
                          <p className="text-start">
                            {ele?.Answer.split(/\n|\n\n|\n\n1|\n\nn/g).map(
                              (elem: any, ind: number) => {
                                return (
                                  <div key={ind} id={elem.id}>
                                    <span>
                                      {elem}
                                      <br />
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })
                : "No chat history available"}
              {isLoading && (
                <div className="d-flex justify-content-center loader">
                  <Spinner
                    animation="grow"
                    role="status"
                    style={{ width: "5rem", height: "5rem", color: "#00AEEF" }}
                    className="color"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
            <div className="form-outline">
              <input
                type="text"
                placeholder="Type Here"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    textChange();
                  }
                }}
              />
              {textareaError && (
                <div className="text-danger">{textareaError}</div>
              )}
              <svg
                enableBackground="new 0 0 24 24"
                height="512"
                viewBox="0 0 24 24"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
                className={`${isLoading ? "d-none" : ""}`}
                onClick={textChange}
              >
                <path d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z" />
                <path d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chatbot;