import React from 'react'
import { Button } from 'react-bootstrap'

const CustomSubmitButton = (props:{classNam:any,typ:any,text:string}) =>{
    return (
    <Button className = {props.classNam} type = {props.typ}>
        {props.text}
    </Button>
    )
}

export default CustomSubmitButton