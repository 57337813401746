import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRoles, particularRole, createRoleApi, updateRoleApi, deleteRoleApi, getAuthoritiesApi } from '../service'


const initialState = {
    getAggrementDetails: constants.NULL,
    status: constants.IDLE,
    signErr: constants.NULL,
    manageAdminloading: constants.FALSE,
    error1: "",
    roles: constants.NULL,
    particularRole: constants.NULL,
    authoritiesData: constants.NULL,
    message: "",
    statusCode: constants.NULL
};

export const getRolesAsync = createAsyncThunk("get/allRoles", async () => {
    const response: any = await getRoles();
    return response
}
);

export const getAuthoritiesAsync = createAsyncThunk("get/authorities", async () => {
    const response: any = await getAuthoritiesApi();
    return response
}
);

export const getParticularAsync = createAsyncThunk("get/particularRole", async (id: number) => {
    const response: any = await particularRole(id);
    return response
}
);

export const createRoleAsync = createAsyncThunk("post/userRole", async (data: any) => {
    const response: any = await createRoleApi(data);
    return response
}
);

export const updateRoleAsync = createAsyncThunk("put/userRole", async (data: any) => {
    const response: any = await updateRoleApi(data);
    return response
}
);

export const deleteRoleAsync = createAsyncThunk("delete/userRole", async (data: any) => {
    const response: any = await deleteRoleApi(data);
    return response
}
);

export const RoleManagementSlice = createSlice({
    name: "Index Data",
    initialState,
    reducers: {
        reset: (state) => {
            state.message = ""
            state.statusCode = constants.NULL
        },
    },

    extraReducers: (builder) => {
        builder
            // get all roles
            .addCase(getRolesAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(getRolesAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.roles = action.payload;
            })
            .addCase(getRolesAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            // get all roles
            .addCase(getAuthoritiesAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(getAuthoritiesAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.authoritiesData = action.payload;
            })
            .addCase(getAuthoritiesAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            // get one role
            .addCase(getParticularAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(getParticularAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.particularRole = action.payload
            })
            .addCase(getParticularAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            // role update
            .addCase(updateRoleAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(updateRoleAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.message = action.payload.message;
                state.statusCode = action.payload?.statusCode
            })
            .addCase(updateRoleAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
            // role update
            .addCase(createRoleAsync.pending, (state) => {
                state.manageAdminloading = constants.TRUE;
            })
            .addCase(createRoleAsync.fulfilled, (state, action: any) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.statusCode = action.payload?.statusCode;
                state.message = action.payload.message
            })
            .addCase(createRoleAsync.rejected, (state, action) => {
                state.status = constants.IDLE;
                state.manageAdminloading = constants.FALSE;
                state.error1 = constants.INVALID_CREDENTIAL;
            })
    },
});

export const { reset } = RoleManagementSlice.actions
export default RoleManagementSlice.reducer;