import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../src/pages/scss/profiledetails.scss";
import {
  getProfileAsync,
  postProfileAsync,
  updateProfilePicAsync,
} from "../store/Slices/profileSlice";
import CustomHeading from "../components/heading/heading";
import {
  UserPhoto,
  EditPhoto,
  NamePicon,
  EmailPicon,
  PasswordPicon,
} from "../components/svgicon/svg";
import Form from "react-bootstrap/Form";
import Customlabel from "../components/label/label";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../hook";
import CustomSpinner from "../components/spinner/spinner";
import { Nav } from "react-bootstrap";


interface Permission {
  add: boolean,
  create: boolean,
  delete: boolean,
  edit: boolean,
  name: "string",
  view: boolean
}

const Profiledetails = () => {
  const Admin = localStorage.getItem('is_admin')
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("");
  const [repeat, setRepeat] = useState("");
  const [formErr, setFormErr] = useState({ name: "" });
  const [formErr1, setFormErr1] = useState({
    oldpassword: "",
    password: "",
    repeat: "",
  });
  const [disable, setDisable] = useState(true);
  const [oldpassword, setoldpassword] = useState("");
  const [permissions, setPermissions] = useState<Permission>()
  const [permissionsRole, setPermissionRole] = useState<Permission>()
  const [password, setpassword] = useState("");
  const {
    getProfileDetails,
    postProfileDetails,
    manageAdminloading,
    image,
  } = useAppSelector((state: any) => state?.profile);

  useEffect(() => {
    dispatch(getProfileAsync());
    if (Admin != "true") {
      const auth = localStorage.getItem('roleAuth') || ""
      const roleAuth = JSON.parse(auth)
      roleAuth?.map((elem) => {
        if (elem?.name == "User Management") {
          setPermissions(elem)
        }
        if (elem?.name == "Role Management") {
          setPermissionRole(elem)
        }
      })
    }
  }, []);


  useEffect(() => {
    if (getProfileDetails) {
      setName(getProfileDetails?.data?.name);
    }
  }, [getProfileDetails]);

  useEffect(() => {
    if (postProfileDetails) {
      dispatch(getProfileAsync());
    }
  }, [postProfileDetails]);

  const validations = (val: any) => {
    let valid = true;
    const err = { name: "" };
    if (val.name.length == 0) {
      err.name = "Name is required!";
      valid = false;
    }
    setFormErr(err);
    return valid;
  };

  const changePasswordValidations = (val: any) => {
    const err = { oldpassword: "", password: "", repeat: "" };
    if (!oldpassword) {
      err.oldpassword = "Old Password is required!";
    }
    if (!password) {
      err.password = "New Password is required!";
    }
    if (!repeat) {
      err.repeat = "Re-Enter Password is required!";
    }
    if (password !== repeat) {
      err.repeat = "Password doesn't match";
    }
    return err;
  };

  const handleClick = async () => {
    await validations({ name: name });
    dispatch(postProfileAsync({ name: name, profile_pic: image?.path }));
  };

  const handleChangePassword = async () => {
    setFormErr1({ oldpassword: "", password: "", repeat: "" });

    const validationErrors = changePasswordValidations({
      oldpassword,
      password,
      repeat,
    });

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        await dispatch(postProfileAsync({ oldpassword, password }));
        dispatch(getProfileAsync());
        setoldpassword("");
        setpassword("");
        setRepeat("");
      } catch (error) {
        return error
      }
    } else {
      setFormErr1(validationErrors);
    }
  };

  const handleImageUpload = (event: any) => {
    const image = event.target.files[0];
    setSelectedImage(image);
    dispatch(updateProfilePicAsync({ image: image }));
  };

  const removeImageUpload = (event: any) => {
    setSelectedImage(null);
  };

  return (
    <>
      {manageAdminloading && (
        <div className="custom-spinner-container">
          <CustomSpinner />
        </div>
      )}
      <div className="account-outer">
        <Container>
          <div className=" d-flex justify-content-start my-5 custom_heading">
            <h3>My Account</h3>
            <Nav className="justify-content-center tabs ms-4" activeKey="/home">
              {Admin == "true" ? <Nav.Item>
                <Nav.Link onClick={() => navigate('/user-management')}>User Management</Nav.Link>
              </Nav.Item> : permissions?.add || permissions?.view || permissions?.edit || permissions?.delete ? <Nav.Item>
                <Nav.Link onClick={() => navigate('/user-management')}>User Management</Nav.Link>
              </Nav.Item> : null}
              {Admin == "true" ? <Nav.Item>
                <Nav.Link onClick={() => navigate('/role-management')}>Role Management</Nav.Link>
              </Nav.Item> : permissionsRole?.add || permissionsRole?.view || permissionsRole?.edit || permissionsRole?.delete ? <Nav.Item>
                <Nav.Link onClick={() => navigate('/role-management')}>Role Management</Nav.Link>
              </Nav.Item> : null}
            </Nav>
          </div>
          {/* } */}
          <Row>
            <Col lg={6}>
              <div className="profile-details">
                <CustomHeading text={"Profile Details"} level={4} />
                <button
                  onClick={() => setDisable(false)}
                  className="custom-btn border-btn"
                >
                  Edit
                </button>
              </div>
              <div className="profile-inner">
                <CustomHeading text={"Profile Pic"} level={5} />
                <div className="upload-inner">
                  <div className="edit-photo">
                    <EditPhoto />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    placeholder="upload your image here"
                    onInput={handleImageUpload}
                  />
                  {selectedImage ? (
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Selected"
                    />
                  ) : getProfileDetails?.data?.profile_pic ? (
                    <img
                      src={getProfileDetails?.data.profile_pic}
                      alt="Uploaded"
                    />
                  ) : (
                    <UserPhoto />
                  )}
                </div>
                <h4>
                  <a href="#" onClick={removeImageUpload}>
                    Remove Picture
                  </a>
                </h4>
                <div className="form-inner">
                  <div className="label-outer">
                    <NamePicon />
                    <Customlabel text={"Name"} />
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={name}
                    maxLength={20}
                    onChange={(e) => setName(e.target.value)}
                    disabled={disable}
                    size="lg"
                  />
                  <p className="text-danger">{formErr.name}</p>
                </div>
                <div className="form-inner">
                  <div className="label-outer">
                    <EmailPicon />
                    <Customlabel text={"Email"} />
                  </div>
                  <Form.Control
                    type="text"
                    disabled
                    value={getProfileDetails?.data?.email}
                    placeholder="Enter Email"
                    size="lg"
                  />
                </div>
                <div className="save-btn">
                  <button
                    onClick={handleClick}
                    className={`custom-btn custom-primary  ${disable ? "disabled" : ""
                      }`}
                    disabled={disable}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="profile-details">
                <CustomHeading text={"Change Password"} level={4} />
              </div>
              <div className="profile-inner">
                <div className="form-inner">
                  <div className="label-outer">
                    <PasswordPicon />
                    <Customlabel text={"Old Password*"} />
                  </div>
                  <Form.Control
                    type="password"
                    name="oldpassword"
                    value={oldpassword}
                    onChange={(e) => setoldpassword(e.target.value)}
                    placeholder="****************"
                    size="lg"
                  />
                  <p className="text-danger">{formErr1?.oldpassword}</p>
                </div>
                <div className="form-inner">
                  <div className="label-outer">
                    <PasswordPicon />
                    <Customlabel text={"New Password*"} />
                  </div>
                  <Form.Control
                    type="password"
                    placeholder="****************"
                    name="password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    size="lg"
                  />
                  <p className="text-danger">{formErr1?.password}</p>
                </div>
                <div className="form-inner">
                  <div className="label-outer">
                    <PasswordPicon />
                    <Customlabel text={"Repeat Password*"} />
                  </div>
                  <Form.Control
                    type="password"
                    value={repeat}
                    onChange={(e) => setRepeat(e.target.value)}
                    placeholder="****************"
                    name="repeatPassword"
                    size="lg"
                  />
                  <p className="text-danger">{formErr1?.repeat}</p>
                </div>
                <div className="save-btn">
                  <button
                    onClick={handleChangePassword}
                    className="custom-btn custom-primary"
                  >
                    {" "}
                    Change Password
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <div className="peofile-details">
              <h3>Profile Detail</h3>
              <div className="name-box">
                <p>
                  <b>Name : </b>
                  {getProfileDetails?.data?.name}
                </p>
                <p>
                  <b>Email : </b>
                  {getProfileDetails?.data?.email}
                </p>
                <p>
                  <b>Email Verified : </b>
                  {getProfileDetails?.data?.isEmailVerified == "0"
                    ? "No"
                    : "Yes"}
                </p>
                <p>
                  <b>Registered Type : </b>
                  {getProfileDetails?.data?.registeredmethod}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profiledetails;