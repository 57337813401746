import { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../hook"
import { getParticularAsync, updateRoleAsync, reset } from "../store/Slices/RoleManagementSlice"
import DataTable from "react-data-table-component"
import '../pages/scss/addRole.scss'

interface Role {
    name: string,
    view: boolean,
    edit: boolean,
    delete: boolean,
    create: boolean
}


const ViewRole = () => {
    const Admin = localStorage.getItem('is_admin')
    const { state } = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { particularRole, message, statusCode } = useAppSelector((state): any => state?.RoleManagement)
    const [role, setRole] = useState("")
    const [description, setDescription] = useState("")
    const [roleData, setRoleData] = useState<Role[]>([])
    const [roleId, setRoleId] = useState(null)

    useEffect(() => {
        dispatch(getParticularAsync(state?.id))
    }, [state])


    useEffect(() => {
        setRole(particularRole?.name)
        setDescription(particularRole?.description)
        setRoleData(particularRole?.authority ? JSON.parse(particularRole?.authority) : particularRole?.authority)
    }, [particularRole])

    const handleUpdate = async (e) => {
        e.preventDefault()
        await dispatch(updateRoleAsync({
            id: state?.id,
            name: role,
            description: description,
            data: roleData
        }))
    }

    useEffect(() => {
        if (message.trim() == "Successfully updated role" && statusCode == 200) {
            dispatch(reset())
            navigate('/role-management')
        }
    }, [message, statusCode])

    const columns = [
        {
            name: 'Module',
            selector: row => row.name
        },
        {
            name: 'Add',
            selector: (row) => <input type="checkbox" name="add" checked={row?.add}
                onChange={() => {
                    let obj
                    setRoleData(roleData.map((elem: any) => {
                        if (elem?.name == row?.name) {
                            return obj = { ...elem, add: !row?.add }
                        } else {
                            return obj = { ...elem }
                        }
                    }))
                }}
                placeholder="checkbox" />
        },
        {
            name: 'Edit',
            selector: (row) => <input type="checkbox" name="edit" checked={row?.edit}
                onChange={() => {
                    let obj
                    setRoleData(roleData.map((elem: any) => {
                        if (elem.name == row?.name) {
                            return obj = { ...elem, edit: !row?.edit }
                        } else {
                            return obj = { ...elem }
                        }
                    }))
                }}
                placeholder="checkbox" />
        },
        {
            name: 'View',
            selector: (row) => <input type="checkbox" name="view" checked={row?.view}
                onChange={() => {
                    let obj
                    setRoleData(roleData.map((elem: any) => {
                        if (elem.name == row?.name) {
                            return obj = { ...elem, view: !row?.view }
                        } else {
                            return obj = { ...elem }
                        }
                    }))
                }}
                placeholder="checkbox" />
        },
        {
            name: 'Delete',
            selector: (row) => <input type="checkbox" name="del" checked={row?.delete}
                onChange={() => {
                    let obj
                    setRoleData(roleData.map((elem: any) => {
                        if (elem.name == row?.name) {
                            return obj = { ...elem, delete: !row?.delete }
                        } else {
                            return obj = { ...elem }
                        }
                    }))
                }}
                placeholder="checkbox" />
        },
    ]

    return (
        <>
            <div className="dashboard-main">
                <Container>
                    <div className="d-flex w-100 justify-content-between my-5 ">
                        <h3><b>{state.action == 'view' ? 'View' : 'Edit'} Role</b></h3>
                        <button
                            className="custom-btn custom-primary"
                            onClick={() => navigate('/role-management')}
                        >
                            Back
                        </button>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-48 d-flex flex-column mb-3">
                            <label className="text-dark mb-2">Role Name</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" type="text" placeholder="Enter Role..." onChange={(e) => setRole(e.target.value)} value={role} disabled={state?.action == "view" ? true : false} />
                        </div>
                        <div className="w-48 d-flex flex-column mb-5">
                            <label className="text-dark mb-2">Description</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" type="text" placeholder="Enter Role Description" onChange={(e) => setDescription(e.target.value)} value={description} disabled={state?.action == "view" ? true : false} />
                        </div>
                    </div>
                    <div className="data-table custom-datatable">
                        {roleData ?
                            <DataTable
                                columns={columns}
                                data={roleData}
                            />
                            : "No data available"}
                    </div>
                    {state?.action == "edit" ? <div className="d-flex w-100 justify-content-center mt-5">
                        <button className="custom-btn custom-primary me-3" onClick={(e) => handleUpdate(e)}>Save</button>
                        <button className="custom-btn custom-primary me-3" onClick={() => navigate('/role-management')}>Back</button>
                    </div> : null}
                </Container >
            </div >
        </>
    )
}

export default ViewRole