import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginApi, adminloginApi, domainCheckApi } from "../service";

const initialState = {
  user: "",
  loginSync: null,
  status: constants.IDLE,
  success: "",
  createUserMsg: "",
  loading: constants.FALSE,
  error: "",
  message: "",
  statusCode: constants.NULL,
}

// login
export const loginAsync = createAsyncThunk("auth/login", async (data: any) => {
  const response = await loginApi(data);
  return response
});

// login
export const adminloginAsync = createAsyncThunk("auth/login", async (data: any) => {
  const response = await adminloginApi(data);
  return response
});

export const isValidDomainAsync = createAsyncThunk("auth/domain", async (data: any) => {
  const response = await domainCheckApi(data);
  return response
});


// auth slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearloginState: (state) => {
      state.loginSync = null;
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = constants.TRUE;
        state.status = constants.IDLE;
      })
      .addCase(loginAsync.fulfilled, (state, action: any) => {
        state.status = constants.FULLFILLED;
        state.loading = constants.FALSE;
        state.loginSync = action.payload;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.status = constants.REJECTED;
        state.loading = constants.FALSE;
        state.error = constants.INVALID_CREDENTIAL;
      })

      .addCase(isValidDomainAsync.pending, (state) => {
        state.loading = constants.TRUE;
        state.status = constants.IDLE;
      })
      .addCase(isValidDomainAsync.fulfilled, (state, action: any) => {
        state.status = constants.FULLFILLED;
        state.loading = constants.FALSE;
        state.message = action.payload.message;
        state.statusCode = action.payload.statusCode
      })
      .addCase(isValidDomainAsync.rejected, (state, action: any) => {
        state.status = constants.REJECTED;
        state.loading = constants.FALSE;
        state.message = action.error.message;
        state.error = constants.INVALID_CREDENTIAL;
      })
  },
});

export default authSlice.reducer;
export const { clearloginState } = authSlice.actions;
