import { configureStore } from '@reduxjs/toolkit'
import authSlice from './Slices/authSlice'
import contractHubSlice from './Slices/contractHubSlice'
import profileSlice from './Slices/profileSlice'
import aggrementSlice from './Slices/AggrementSlice'
import postContractSlice from './Slices/PostContentSlice'
import ForgotPasswordSlice from './Slices/ForgotPasswordSlice'
import clauseDetailSlice from './Slices/clauseSlice'
import editContentSlice from './Slices/editContentSlice'
import changePasswordSlice from './Slices/changePasswordSlice'
import RoleManagementSlice from './Slices/RoleManagementSlice'
import UserManagermentSlice from './Slices/UserManagermentSlice'
import AdminManagement from './Slices/AdminManagementSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    getAllContracts: contractHubSlice,
    profile: profileSlice,
    getAggrement: aggrementSlice,
    addContract: postContractSlice,
    forgot: ForgotPasswordSlice,
    clauseDetails: clauseDetailSlice,
    editContract: editContentSlice,
    changePassword: changePasswordSlice,
    RoleManagement: RoleManagementSlice,
    userManagement: UserManagermentSlice,
    AdminManagement: AdminManagement
  },
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// export type AppDispatch = ReturnType<typeof store>["dispatch"];