import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CustomButton from './button/button';

interface Confirm {
    message: string;
    confirm: () => void;
    cancel: () => void;
    show: boolean;
}


const ConfirmModal = ({ message, confirm, cancel, show }: Confirm): JSX.Element => {

    return (
        <>
            <Modal className='delete-modal' show={show} onHide={() => cancel} centered={true}>

                <Modal.Body><div className='d-flex flex-column'><h3>{message}</h3>
                    <div className='d-flex justify-content-center  align-items-end'>
                        <Button className='custom-btn custom-primary backg' onClick={() => cancel()} >Cancel</Button>
                        <Button className=' ms-2 custom-btn custom-primary' onClick={() => confirm()}>Confirm</Button></div>
                </div></Modal.Body>

            </Modal>
        </>
    );
}

export default ConfirmModal;