import React from 'react'
import { Navigate, useLocation } from "react-router-dom"
import { Outlet } from 'react-router-dom';
import Header from '../components/header/header';

const ProtectedRoute = ({ children }: any) => {
    const domain = window.location.hostname

    const Token = localStorage.getItem("auth")
    let location = useLocation();

    if (!Token) {
        return domain.split('.')[0] == "sa" || domain.split('.')[1] == "sa" ? <Navigate to="/" state={{ from: location }} replace /> : <Navigate to="/" state={{ from: location }} replace />
    }
    return <div><Header /><Outlet />{children}</div>

};

export default ProtectedRoute;