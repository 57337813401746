import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postContentApi } from "../service";

const initialState = {
  postContentData: constants.NULL,
  status: constants.IDLE,
  signErr: constants.NULL,
  manageAdminloading: constants.FALSE,
  error1: "",
};

export const postContentAsync = createAsyncThunk("postData/postContract", async (data: any) => {
  const response = await postContentApi(data);
  return response
}
);

export const postContractSlice = createSlice({
  name: "Add Contract",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postContentAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(postContentAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.postContentData = action.payload;
      })
      .addCase(postContentAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      });
  },
});

export default postContractSlice.reducer;
