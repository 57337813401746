import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAggrementApi, postCommentApi, getCommentsApi, getOriginalAgreementApi, deleteSectionApi, getAgreementClauseApi, getOriginalClauseApi, getClauseOnlyApi, putCommentApi, deleteCommentApi, getSectionDataApi, changeClauseNumberApi } from "../service";


const initialState = {
  getAggrementDetails: constants.NULL,
  getIndex: "",
  status: constants.IDLE,
  signErr: constants.NULL,
  manageAdminloading: constants.FALSE,
  error1: "",
  comments: "",
  statusMessage: "",
  getAggrementClauseDetails: [],
  clauseOnlyData: {},
  sectionData: []
};

export const getAggrementAsync = createAsyncThunk("aggrement/aggrementContract", async (data: any) => {
  const response: any = await getAggrementApi(data);
  return response
});

export const getAggrementClauseAsync = createAsyncThunk("get/agreementClause", async (data: any) => {
  const response: any = await getAgreementClauseApi(data);
  return response
}
);

export const getSectionDataAsync = createAsyncThunk("get/sectionData", async (data: any) => {
  const response: any = await getSectionDataApi(data);
  return response
})

export const changeNumberAsync = createAsyncThunk("put/clauseNumber", async (data: any) => {
  const response: any = await changeClauseNumberApi(data);
  return response
})

export const getClauseOnlyAsync = createAsyncThunk("get/clauseOnly", async (data: any) => {
  const response: any = await getClauseOnlyApi(data);
  return response
}
);

export const getOriginalClauseAsync = createAsyncThunk("get/OriginalClause", async (data: any) => {
  const response: any = await getOriginalClauseApi(data);
  return response
}
);

export const getOriginalAggrementAsync = createAsyncThunk("aggrement/originalContract", async (data: any) => {
  const response: any = await getOriginalAgreementApi(data);
  return response
}
);

export const getIndexAsync = createAsyncThunk("index/aggrementIndex", async (data: any) => {
  const response: any = await getAggrementApi(data);
  return response
}
);

export const postCommentAsync = createAsyncThunk("add/Comment", async (data: any) => {
  const response: any = await postCommentApi(data);
  return response
}
);

export const putCommentAsync = createAsyncThunk("put/Comment", async (data: any) => {
  const response: any = await putCommentApi(data);
  return response
}
);

export const deleteCommentAsync = createAsyncThunk("delete/Comment", async (data: any) => {
  const response: any = await deleteCommentApi(data);
  return response
}
);

export const getCommentsAsync = createAsyncThunk("get/Comments", async (data: any) => {
  const response: any = await getCommentsApi(data);
  return response
}
);

export const deleteSectionAsync = createAsyncThunk('delete/section', async (data: any) => {
  const response: any = await deleteSectionApi(data);
  return response
})

export const aggrementSlice = createSlice({
  name: "Index Data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAggrementAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getAggrementAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.getAggrementDetails = action.payload;
      })
      .addCase(getAggrementAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // Index data
      .addCase(getIndexAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getIndexAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.getIndex = action.payload;
      })
      .addCase(getIndexAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // post Comment
      .addCase(postCommentAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(postCommentAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.statusMessage = action.payload.message
      })
      .addCase(postCommentAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // put Comment
      .addCase(putCommentAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(putCommentAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.statusMessage = action.payload.message
      })
      .addCase(putCommentAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // delete Comment
      .addCase(deleteCommentAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(deleteCommentAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.statusMessage = action.payload.message
      })
      .addCase(deleteCommentAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // get Comments
      .addCase(getCommentsAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getCommentsAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.comments = action.payload
      })
      .addCase(getCommentsAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // get Original Async
      .addCase(getOriginalAggrementAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getOriginalAggrementAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.getAggrementDetails = action.payload
      })
      .addCase(getOriginalAggrementAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // get Agreement Clause Async
      .addCase(getAggrementClauseAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getAggrementClauseAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.getAggrementClauseDetails = action.payload
      })
      .addCase(getAggrementClauseAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.getAggrementClauseDetails = []
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // get Agreement Clause Async
      .addCase(getSectionDataAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getSectionDataAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.sectionData = action.payload
      })
      .addCase(getSectionDataAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.sectionData = []
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      .addCase(getClauseOnlyAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      // get Agreement Clause Async
      .addCase(getOriginalClauseAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(getOriginalClauseAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.getAggrementClauseDetails = action.payload
      })
      .addCase(getOriginalClauseAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.getAggrementClauseDetails = []
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
  },
});

export default aggrementSlice.reducer;
