import * as constants from "../../Constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { changeAdminPasswordApi, changePasswordApi } from "../service";

const initialState = {
  postchangepassword: null,
  status: constants.IDLE,
  signErr: null,
  manageAdminloading: constants.FALSE,
  error1: "",
  message: ""
};

export const changePasswordAsync = createAsyncThunk("changeData/change", async (data: any) => {
  const response = await changePasswordApi(data);
  return response
}
);

export const changeAdminPasswordAsync = createAsyncThunk("changePasswordData/change", async (data: any) => {
  const response = await changeAdminPasswordApi(data);
  return response
}
);

export const changePasswordSlice = createSlice({
  name: "changePasswordData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(changePasswordAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.message = action.payload;
      })
      .addCase(changePasswordAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      })
      .addCase(changeAdminPasswordAsync.pending, (state) => {
        state.manageAdminloading = constants.TRUE;
      })
      .addCase(changeAdminPasswordAsync.fulfilled, (state, action: any) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.message = action.payload;
      })
      .addCase(changeAdminPasswordAsync.rejected, (state, action) => {
        state.status = constants.IDLE;
        state.manageAdminloading = constants.FALSE;
        state.error1 = constants.INVALID_CREDENTIAL;
      });
  },
});

export default changePasswordSlice.reducer;