import { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../hook"
import { getRolesAsync } from "../store/Slices/RoleManagementSlice"
import '../pages/scss/addRole.scss'
import { createUserAsync, reset } from "../store/Slices/UserManagermentSlice"
import { useLocation } from "react-router-dom"
import Select from 'react-select';



const AddUser = () => {
    const dispatch = useAppDispatch()
    const { roles } = useAppSelector((state): any => state?.RoleManagement)
    const { message, statusCode } = useAppSelector((state): any => state?.userManagement)
    const navigate = useNavigate()
    const [currentRole, setCurrentRole] = useState({
        value: null,
        label: ""
    })
    const { state } = useLocation()
    const domain = window.location.hostname
    const [userAuthority, setUserAuthority] = useState([])
    const [roleOption, setRoleOption] = useState([])
    const [first_name, setFirst_name] = useState("")
    const [last_name, setLast_name] = useState("")
    const [email, setEmail] = useState("")
    const [roleId, setRoleId] = useState(null)
    const [password, setPassWord] = useState("")
    const [userError, setUserError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        select: "",
    })

    useEffect(() => {
        dispatch(getRolesAsync())
    }, [state])

    useEffect(() => {
        roles?.map((elem) => {
            if (elem.id == currentRole?.value) {
                setUserAuthority(JSON.parse(elem?.authority))
            }
            else { setUserAuthority([]) }
        })
    }, [currentRole])


    useEffect(() => {
        setRoleOption(roles?.map((elem) => {
            return {
                value: elem.id,
                label: elem.name
            }
        }))
    }, [roles])



    const handleSelect = (e) => {
        setCurrentRole({ label: e.label, value: e.value })
        setRoleId(e.value)
    }

    const validate = () => {
        let valid = true
        let Validations = {
            first_name: "",
            last_name: "",
            email: "",
            select: ""
        }
        if (first_name == "") {
            Validations.first_name = "Field can not be empty"
            valid = false
        }
        if (last_name == "") {
            Validations.last_name = "Field can not be empty"
            valid = false
        }
        if (email == "") {
            Validations.email = "Field can not be empty"
            valid = false
        }
        if (!roleId) {
            Validations.select = "Select any role"
            valid = false
        }
        setUserError(Validations)
        return valid
    }

    const handleSubmit = async () => {
        if (validate()) {
            await dispatch(createUserAsync({
                firstname: first_name,
                lastname: last_name,
                email: email,
                role: roleId,
                password: password,
                host: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0]
            }))
        }
    }

    useEffect(() => {
        if (message.trim() == "User created successfully") {
            dispatch(reset())
            navigate('/user-management')
        }
    }, [message, statusCode])

    return (
        <>
            <div className="dashboard-main">
                <Container>
                    <div className="my-5 ">
                        <div className="">
                            <h3><b>Add User</b></h3>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-48 d-flex flex-column ">
                            <label className="text-dark mb-2">First Name</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="first_name" type="text" placeholder="Enter first Name" value={first_name} onChange={(e) => setFirst_name(e.target.value)} />
                            <p className="text-danger">{userError?.first_name}</p>
                        </div>
                        <div className="w-48 d-flex flex-column ">
                            <label className="text-dark mb-2">Last Name</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="last_name" type="text" placeholder="Enter last Name" value={last_name} onChange={(e) => setLast_name(e.target.value)} />
                            <p className="text-danger">{userError?.last_name}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-48 d-flex flex-column mb-3">
                            <label className="text-dark mb-2">Email</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" name="email" type="text" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <p className="text-danger">{userError?.email}</p>
                        </div>
                        <div className={"d-flex flex-column w-48 "} >
                            <label >User Role</label>
                            <Select options={roleOption} onChange={handleSelect} value={currentRole} />
                            <p className="text-danger">{userError?.select}</p>
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-center mt-5">
                        <button className="custom-btn custom-primary me-3" onClick={handleSubmit}>Create</button>
                        <button className="custom-btn custom-primary me-3" onClick={() => navigate('/user-management')}>Back</button>
                    </div>
                </Container >
            </div >
        </>
    )
}

export default AddUser