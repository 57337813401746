import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../src/pages/scss/addClauses.scss";
import { Add, Delete } from "../assets/icons";
// import CustomQuill from "../components/reactQuill/ReactQuill";
// import __html from '../components/reactQuill/ckEditor'
// var template = { __html: __html };
// import CKEditorComponent from '../components/reactQuill/ckEditor'
import {
  clearAllState,
  postClauseAsync,
  addClauseUptoDateAsync
} from "../store/Slices/editContentSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hook";
import CustomHeading from "../components/heading/heading";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { deleteFromML, postPdfToMLAsync, reset } from "../store/Slices/contractHubSlice";
import CustomSpinner from "../components/spinner/spinner";
import TestCkEditorComponent from "../components/reactQuill/ckEditorComp";

const AddClauses = () => {
  const domain = window.location.hostname
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const [html, setHtml] = useState("")
  const [position, setPosition] = useState("")
  const [message, setMessage] = useState("");
  const [checkedValue, setCheckedValue] = useState(
    state?.status === "Edit" ? "Amendment" : "Addition"
  );
  const [inputFields, setInputFields] = useState([{ type: "", value: "" }]);
  const [inputLinkFields, setInputLinkFields] = useState([
    { type: "", value: "" },
  ]);
  const [quillError, setQuillError] = useState("");
  const [errors, setErrors] = useState<(string | never)[]>([]);
  const [linkErrors, setLinkErrors] = useState<(string | never)[]>([]);
  const [date, setDate] = useState("")
  const [reason, setReason] = useState("")
  const [reasonErr, setReasonErr] = useState("")
  const [title, setTitle] = useState("")
  const [loading, setLoading] = useState(false)
  const { clauseDetail, manageAdminloading } = useAppSelector((state: any) => state?.clauseDetails);
  const { dataDeletedMesage } = useAppSelector((state: any) => state?.getAllContracts);

  useEffect(() => {
    dispatch(clearAllState())
  }, [])

  // useEffect(() => {
  //   // Load HTML content from a file
  //   fetch('./ckeditor.html')
  //     .then(response => response.text())
  //     .then(html => {
  //       console.log(html, 'html');
  //       setHtml(html)

  //       // setEditorData(html);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching HTML file:', error);
  //     });
  // }, []); // Run only once on component mount


  const { editContentData } = useAppSelector((state): any => state?.editContract);
  const arr = inputFields?.map((e) => {
    return {
      word: e.type,
      defination: e.value,
      clause_id: editContentData?.clause_id
    };
  });

  const linkArr = inputLinkFields?.map((e) => {
    return {
      word: e.type,
      Link: e.value,
      clause_id: editContentData?.clause_id
    };
  });

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index].value = event.target.value;
    setInputFields(values);
    validateField(index);
  };

  const handleTypeChange = (index, event) => {
    const values = [...inputFields];
    values[index].type = event.target.value;
    setInputFields(values);
    validateField(index);
  };

  const validateField = (index) => {
    const newErrors = [...errors];
    newErrors[index] = "";
    setErrors(newErrors);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ type: "", value: "" });
    setInputFields(values);
    setErrors([...errors, ""]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };


  const handleAddLinkFields = () => {
    const values = [...inputLinkFields];
    values.push({ type: "", value: "" });
    setInputLinkFields(values);
  };

  const handleInputLinkChange = (index, event) => {
    const values = [...inputLinkFields];
    values[index].value = event.target.value;
    setInputLinkFields(values);
    validateLinkField(index);
  };

  const handleTypeLinkChange = (index, event) => {
    const values = [...inputLinkFields];
    values[index].type = event.target.value;
    setInputLinkFields(values);
    validateLinkField(index);
  };

  const validateLinkField = (index) => {
    const newLinkErrors = [...linkErrors];
    newLinkErrors[index] = "";
    setLinkErrors(newLinkErrors);
  };

  const handleRemoveLinkFields = (index) => {
    const values = [...inputLinkFields];
    values.splice(index, 1);
    setInputLinkFields(values);
  };

  const definitionsAreFilled = () => {
    return inputFields.some(
      (field) => field.type.trim() !== "" && field.value.trim() !== ""
    );
  };



  useEffect(() => {
    if (clauseDetail) {
      clauseDetail?.map((elem) => {
        return setTitle(elem?.title);
      });
    }
  }, [clauseDetail]);


  const addClause = async () => {
    await dispatch(deleteFromML({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
    // await dispatch(postPdfToMLAsync({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
    await dispatch(clearAllState())
  }

  useEffect(() => {
    if (checkedValue === "Addition" && editContentData?.statusCode == "201") {
      addClause()
    }
  }, [editContentData])

  const AddClauseToMLDB = async () => {
    dispatch(clearAllState());
    dispatch(reset())
    await dispatch(postPdfToMLAsync({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
    await toast.success("Clause added successfully")
    setLoading(false)
    setTimeout(() => {
      setInputLinkFields([{ type: "", value: "" }]);
      setInputFields([{ type: "", value: "" }]);
      navigate(-1)
    }, 1000);

  }

  useEffect(() => {
    if (dataDeletedMesage == "Contract deleted successfully." || dataDeletedMesage == "Contract does not exist.") {
      AddClauseToMLDB()
    }
  }, [dataDeletedMesage])



  const handleEditClick = async () => {
    const isFormValid = await validateForm()
    if (isFormValid && state?.status == 'Add') {
      setLoading(true)
      let text = message
      await dispatch(postClauseAsync({
        data: [
          {
            clause: text.replaceAll("  ", "&nbsp;&nbsp;"),
            section_id: state?.Cid,
            status: 0,
            defination: arr,
            links: linkArr,
            number: position

          }
        ]
      }))
      // setLoading(false)

    } else if (isFormValid && state?.status == 'Add Up To Date') {
      setLoading(true)
      await dispatch(addClauseUptoDateAsync({
        update: true,
        data: [
          {
            clause: message.replaceAll("  ", "&nbsp;&nbsp;"),
            section_id: state?.section_id,
            status: 2,
            defination: arr,
            links: linkArr,
            reason: reason,
            date: date,
            number: position
          }
        ]
      }))
      // setLoading(false)
    }
  }



  const validateForm = () => {
    let isValid = true;
    const newErrors = [...errors];
    let quillError = "";
    if (state.status === "Amendment" ? message.replace(/<(.|\n)*?>/g, '').trim().length === 0 : message.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      quillError = "Please fill the clause";
      isValid = false;
    }
    setErrors(newErrors);
    setQuillError(quillError);
    return isValid;
  };

  const handleChangeStatus = (e) => {
    setCheckedValue(e.target.value);
  };

  const handleBack = () => {
    setMessage("");
    navigate(-1);
  };


  return (
    <>
      {loading && (
        <div className="custom-spinner-container">
          <CustomSpinner />
        </div>
      )}
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <div className="add-clause">
              <h3>{state.status} Clause</h3>
              <div className="heading-outer mb-4">

                <div className="">
                  <CustomHeading text={state?.title} level={4} />
                  {/* <CKEditorComponent /> */}
                  <TestCkEditorComponent data={message} change={(e, editor) => setMessage(editor.getData())} />
                  {/* <iframe src="http://127.0.0.1:5500/src/pages/ckeditor.html" width="800" height="800"></iframe> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: html }} style={{ width: "500px", height: "800px" }} className="danger" /> */}

                  {/* <CustomQuill data={message} change={(e) => setMessage(e)} /> */}
                  {quillError && (
                    <div className="text-danger">{quillError}</div>
                  )}
                </div>
                <div className="d-flex flex-column mt-3"><label htmlFor="">Number</label>
                  <input type="text" className="rounded border border-1 py-2 ms-0 w-5 mt-2 ps-2" value={position ? position : ""} onChange={(e) => setPosition(e.target.value)} /></div>
                <div className="custom-checkbox">
                  <form action="">
                    <input
                      placeholder="check"
                      type="checkbox"
                      id="Addition"
                      name="Addition"
                      value="Addition"
                      onClick={handleChangeStatus}
                      checked={checkedValue == "Addition" ? true : false}
                    />
                    <label>Addition</label>
                    <input
                      placeholder="Amendment"
                      type="checkbox"
                      id="Amendment"
                      name="Amendment"
                      value="Amendment"
                      onClick={() => toast.error("Invalid action")}
                      checked={checkedValue == "Amendment" ? true : false}
                    />
                    <label>Amendment</label>
                    <input
                      placeholder="Deletion"
                      type="checkbox"
                      id="Deletion"
                      name="Deletion"
                      value="Deletion"
                      onClick={() => toast.error("Invalid action")}
                      checked={checkedValue == "Deletion" ? true : false}
                    />
                    <label>Deletion</label>
                  </form>
                </div>
                {state?.status == 'Add Up To Date' ? <Form className="d-flex justify-content-between">
                  <Form.Group
                    className="mb-3 w-65"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{`Reason for ${checkedValue == "Permanent_Delete" ? "Permanent Delete" : checkedValue}`}</Form.Label>
                    <Form.Control
                      name=""
                      type="text"
                      placeholder={`Write reason... `}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                    <Form.Text id="passwordHelpBlock" className="text-danger">{reasonErr}</Form.Text>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 w-auto"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      name="date"
                      type="date"
                      placeholder="Select Date"
                      value={moment(date).format("YYYY-MM-DD")}
                      onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                    />
                  </Form.Group>
                </Form> : null}

                <div className="custom-definition">
                  <h5>Definition</h5>

                  {inputFields?.map((inputField, index) => (
                    <>
                      <div className="defe-input mb-5">
                        <p>Word</p>
                        <input
                          className="mt-2"
                          type="text"
                          placeholder="Enter word"
                          value={inputField.type}
                          onChange={(event) => handleTypeChange(index, event)}
                        />
                      </div>
                      <div className="defe-input">
                        <p>Definition</p>
                        <textarea
                          className=""
                          placeholder={`Enter Definition`}
                          value={inputField.value}
                          rows={4}
                          cols={50}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                        <div
                          title="Delete"
                          style={{ width: "53px" }}
                          onClick={() => handleRemoveFields(index)}
                          className={inputFields?.length == 1 ? "d-none" : ""}
                        >
                          {Delete}
                        </div>
                      </div>
                      <div className="defe-input-btn" onClick={handleAddFields}>
                        <button className="border border-0 bg-transparent">{Add}</button>
                      </div>
                      {errors[index] && (
                        <div className="text-danger">{errors[index]}</div>
                      )}
                    </>
                  ))}
                </div>

                <div className="custom-definition">
                  <h5>Links To</h5>

                  {inputLinkFields?.map((inputField, index) => (
                    <>
                      <div className="defe-input mb-5">
                        <p>Word</p>
                        <input
                          type="text"
                          placeholder="Enter word"
                          value={inputField.type}
                          onChange={(event) =>
                            handleTypeLinkChange(index, event)
                          }
                        />
                      </div>
                      <div className="defe-input">
                        <p>Link</p>
                        <textarea
                          className="mt-3"
                          placeholder={"Enter link"}
                          value={inputField.value}
                          rows={4}
                          cols={50}
                          onChange={(event) =>
                            handleInputLinkChange(index, event)
                          }
                        />
                        <div
                          title="Delete"
                          style={{ width: "53px" }}
                          onClick={() => handleRemoveLinkFields(index)}
                          className={
                            inputLinkFields?.length == 1 ? "d-none" : ""
                          }
                        >
                          {Delete}
                        </div>
                      </div>

                      <div
                        className="defe-input-btn mb-3"
                        onClick={handleAddLinkFields}
                      >
                        <button className="border border-0 bg-transparent">{Add}</button>
                      </div>
                      {linkErrors[index] && (
                        <div className="text-danger">{linkErrors[index]}</div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-between w-25 mx-auto">
                <button
                  className="submit_btn  me-1"
                  onClick={() => {
                    handleEditClick();
                  }}
                >
                  Submit
                </button>

                <button
                  className="submit_btn  ms-1"
                  onClick={() => {
                    handleBack();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddClauses;