import { Container } from "react-bootstrap"
import Header from "../components/header/header"
import { SearchIcon, ArrowIcon } from "../components/svgicon/svg"
import { useNavigate, Link, useLocation } from "react-router-dom"
import DataTable from "react-data-table-component"
import { Add, View, Edit, Remove } from "../assets/icons"
import { useAppDispatch, useAppSelector } from "../hook"
import { useEffect, useState } from "react"
import { getRolesAsync, deleteRoleAsync } from "../store/Slices/RoleManagementSlice"
import ConfirmModal from "../components/confirmModal"
import '../pages/scss/addRole.scss'
import { toast } from "react-toastify";


interface Roles {
    id: number,
    name: string,
    description: string | null,
    authority: string,
    createdAt: string,
    updatedAt: string,
    particularRole(pin): string | string
}

interface Permission {
    add: boolean,
    create: boolean,
    delete: boolean,
    edit: boolean,
    name: "string",
    view: boolean
}



const RoleManagement = () => {
    const Admin = localStorage.getItem('is_admin')
    const { state } = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { roles } = useAppSelector((state): any => state.RoleManagement)
    const [delRoleId, setDelRoleId] = useState(null)
    const [permissions, setPermissions] = useState<Permission>()
    useEffect(() => {
        dispatch(getRolesAsync())
    }, [])

    useEffect(() => {
        if (Admin && Admin !== "true") {
            JSON.parse(localStorage.getItem('roleAuth') || "")?.map((elem, ind) => {
                if (elem.name == "Role Management") {
                    setPermissions(elem)
                }
            })
        }
    }, [Admin])


    const columns = [
        {
            name: 'Role',
            selector: row => row.name,
        },
        {
            name: 'Description',
            selector: row => row?.description ? row?.description : "This role defines..",
        },
        {
            name: '',
            selector: row => <span>
                <button className="bg-transparent border border-0" onClick={() =>
                    permissions?.edit ? navigate('/edit-role', {
                        state: {
                            id: row?.id,
                            action: "edit"
                        }
                    })
                        : Admin == 'true' ? navigate('/edit-role', {
                            state: {
                                id: row?.id,
                                action: "edit"
                            }
                        }) : permissions?.edit == true ? navigate('/edit-role', {
                            state: {
                                id: row?.id,
                                action: "edit"
                            }
                        }) : toast.error("User doesn't have permission")
                }>{Edit}</button>
                <button className="bg-transparent border border-0" onClick={() =>
                    Admin == 'true' ? navigate('/view-role', {
                        state: {
                            id: row?.id,
                            action: "view"
                        }
                    }) : permissions?.view == true ? navigate('/view-role', {
                        state: {
                            id: row?.id,
                            action: "view"
                        }
                    }) : toast.error("User doesn't have permission")}>{View}</button>
                <button className="bg-transparent border border-0" onClick={() => Admin == "true" ? setDelRoleId(row?.id) : permissions?.delete ? setDelRoleId(row?.id) : toast.error("User doesn't have permission")}>{Remove}</button>
            </span >,
        },

    ];


    const handleConfirm = async () => {
        await dispatch(deleteRoleAsync(delRoleId))
        await setDelRoleId(null)
        await dispatch(getRolesAsync())
    }
    const handleCancel = () => {
        setDelRoleId(null)
    }

    return (
        <>
            <ConfirmModal
                message="Are you sure you want to delete this role"
                confirm={handleConfirm}
                cancel={handleCancel}
                show={delRoleId ? true : false}
            />
            <div className="dashboard-main">
                <Container>
                    <div className="search-outer contrast-list ">
                        <div className="my-5 d-flex w-100 justify-content-between ">
                            <div className="">
                                <h3><b>Role Management</b></h3>
                            </div>
                            {permissions?.add ? <button
                                className="custom-btn custom-primary"
                                onClick={() => navigate('/add-role')}
                            >
                                Add Role
                            </button> : Admin == "true" ? <button
                                className="custom-btn custom-primary"
                                onClick={() => navigate('/add-role')}
                            >
                                Add Role
                            </button> : null}
                        </div>
                    </div>
                    <div className="data-table custom-datatable">
                        {roles ?
                            <DataTable
                                columns={columns}
                                data={roles}
                            />
                            : "No data available"}
                    </div>
                    <div className="d-flex w-100 justify-content-center mt-5">
                    </div>
                    <button className="custom-btn custom-primary mx-auto" onClick={() => navigate('/profile-detail')}>Back</button>
                </Container >
            </div>
        </>
    )

}

export default RoleManagement