import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import Header from "../../components/header/header"
import { SearchIcon, ArrowIcon } from "../../components/svgicon/svg"
import { useNavigate, Link } from "react-router-dom"
import DataTable from "react-data-table-component"
import {
    Add, View
    , Edit, Remove
} from "../../assets/icons"
import { useAppDispatch, useAppSelector } from "../../hook"
import { deleteUserAsync } from "../../store/Slices/UserManagermentSlice"
import { SelectContainer } from "react-select/dist/declarations/src/components/containers"
import CustomSpinner from "../../components/spinner/spinner"
import ConfirmModal from "../../components/confirmModal"
import '../../pages/UserManagement'
import { deleteAdminAsync, getAdminDetailAsync } from "../../store/Slices/AdminManagementSlice"


interface Permission {
    add: boolean,
    create: boolean,
    delete: boolean,
    edit: boolean,
    name: "string",
    view: boolean
}



const AdminUserList = () => {
    const { userList, manageAdminloading } = useAppSelector((state): any => state?.userManagement)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [search, setSearch] = useState("")
    const [permissions, setPermissions] = useState<Permission>()
    const [deleteId, setDeleteId] = useState(null)
    const [searchIn, setSearchIn] = useState("")
    const [userData, setUserData] = useState([])

    useEffect(() => {
        dispatch(getAdminDetailAsync(searchIn))
    }, [searchIn])




    useEffect(() => {
        setUserData(userList)
    }, [userList])
    const columns = [{
        name: "UserName",
        selector: (row: any) => row?.name ? row?.name : "data unavailable"
    },
    {
        name: "Email",
        selector: (row: any) => row?.email ? row?.email : "data unavailable"
    },
    {
        name: "Host",
        selector: (row: any) => row?.host ? row?.host : "Unassigned"
    },
    {
        name: "",
        selector: (row: any) => <span>
            <button className="bg-transparent border border-0" onClick={() => navigate('/admin/edit-user', {
                state: {
                    id: row?.id,
                    action: 'edit'
                }
            })}>{Edit}</button>
            <button className="bg-transparent border border-0" onClick={() => navigate('/admin/view-user', {
                state: {
                    id: row?.id,
                    action: 'view'
                }
            })}>{View}</button>
            <button className="bg-transparent border border-0" onClick={() => setDeleteId(row?.id)}>{Remove}</button>
        </span>
    }
    ];

    const handleConfirm = async () => {
        await dispatch(deleteAdminAsync(deleteId))
        await setDeleteId(null)
        dispatch(getAdminDetailAsync(searchIn))
    }

    return (
        <>
            <div className="dashboard-main">
                <ConfirmModal message="Are you sure you want to delete this user" show={deleteId ? true : false} cancel={() => setDeleteId(null)} confirm={handleConfirm} />
                <Container>
                    <div className="my-5 d-flex w-100 justify-content-between">
                        <div className="">
                            <h3><b>User Management</b></h3>
                        </div>
                        <div className="w-50 d-flex align-items-center searchIn">
                            <SearchIcon />
                            <input className="w-100" type="text" placeholder="search User" value={searchIn} onChange={(e) => setSearchIn(e.target.value)} />
                        </div>
                        <button
                            className="custom-btn custom-primary"
                            onClick={() => navigate('/admin/add-user')}
                        >
                            Add User
                        </button>
                    </div>
                    <div className="data-table custom-datatable">
                        {userData ?
                            <DataTable
                                columns={columns}
                                data={userData}
                                pagination
                            />
                            : "No data available"}
                    </div>
                </Container >
            </div >
        </>
    )
}

export default AdminUserList