import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../images/logo.png";
import "../header/header.scss";
import User from "../../images/user.png";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../confirmModal";
import { useAppDispatch, useAppSelector } from "../../hook";
import { getProfileAsync } from "../../store/Slices/profileSlice";
import CustomHeading from "../heading/heading";
import { getAdminProfileAsync } from "../../store/Slices/AdminManagementSlice";


const Header = () => {
  const domain = window.location.hostname
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  // const [userChat, setUserChat] = useState(sampleChat)
  const [chat, setChat] = useState({ message: "" });
  const {
    getProfileDetails,
  } = useAppSelector((state: any) => state?.profile);
  const {
    profileDetail,
  } = useAppSelector((state: any) => state?.AdminManagement);


  useEffect(() => {
    domain.split('.')[0] == "sa" || domain.split('.')[1] == "sa"
      ? dispatch(getAdminProfileAsync())
      : dispatch(getProfileAsync())
  }, []);

  const handleLogo = () => {
    navigate(domain.split('.')[0] == "sa" || domain.split('.')[1] == "sa" ? "/admin/user-management" : "/dashboard");
  };


  const handleLogout = () => {
    localStorage.removeItem('auth')
    localStorage.removeItem('roleAuth')
    localStorage.removeItem('is_admin')
    navigate('/')
    // domain.split('.')[0] == "sa" || domain.split('.')[1] == "sa" ? navigate('/') : navigate('/')
    setShow(false)
  }
  window.addEventListener('storage', function (event) {
    if (event.key === 'yourKey') {
      localStorage.clear();
    }
  });





  let UserRole: any;
  let role: boolean = (localStorage.getItem("role") === "true");
  if (role === true) {
    UserRole = "Admin"
  }
  else {
    UserRole = "User"
  }



  const handleChange = (e: any) => {
    setChat({ ...chat, message: e.target.value });
  };
  const submit = () => { };
  return (
    <header>
      <ConfirmModal
        message={"Are you sure you want to logout"}
        show={show}
        cancel={() => setShow(false)}
        confirm={handleLogout}
      />
      <Navbar expand="lg" className="">
        <Container fluid>
          <Navbar.Brand style={{ cursor: "pointer" }} onClick={handleLogo}>
            <img src={Logo} alt="" />
          </Navbar.Brand>
          <CustomHeading text={getProfileDetails?.Company_name != "" ? getProfileDetails?.Company_name : domain.split('.')[0] == "www" && domain.split('.')[1] != "sa" ? domain.split('.')[1] : domain.split('.')[0] == "sa" ? "Super Admin" : domain.split('.')[0]} level={2} />
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link>
                <img src={User} alt="" />
              </Nav.Link>
              <div className="d-flex flex-column text-center ms-2">
                <div
                  className="adminBtn"
                  onClick={() => {
                    navigate(domain.split('.')[1] == "sa" || domain.split('.')[0] == "sa" ? "/admin/profile-detail" : "/profile-detail");
                  }}
                >
                  {" "}
                  {domain.split('.')[1] == "sa" || domain.split('.')[0] == "sa" ? profileDetail?.name : getProfileDetails?.data?.name}
                </div>
                <div className="logoutBtn" onClick={() => setShow(true)}>
                  Logout
                </div>
              </div>
              {/* <NavDropdown title="Admin" id="basic-nav-dropdown">
                <NavDropdown.Item className="" onClick={handleProfile}>
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item className="mt-1" >
                  Logout
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
