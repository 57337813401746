import React, { useEffect, useState } from "react";
import "../../pages/scss/login.scss";
import Logo from "../../images/logo.png";
import Customlabel from "../../components/label/label";
import CustomHeading from "../../components/heading/heading";
import CustomP from "../../components/paragraph/paragraph";
import { EmailIcon, PasswordIcon } from "../../components/svgicon/svg";
import { adminloginAsync, isValidDomainAsync, loginAsync } from "../../store/Slices/authSlice";
import Form from "react-bootstrap/Form";
import CustomSubmitButton from "../../components/button/btnSubmit";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hook";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";


interface FormErrors {
    email: string;
    password: string;
}

interface UserData {
    email: string;
    password: string;
}

const AdminLogin = () => {
    const domain = window.location.hostname
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [formErr, setFormErr] = useState<FormErrors>({ email: "", password: "" });
    const [password, setPassword] = useState<string>("");
    const [checkState, setCheckState] = useState<boolean>(false);
    const { status, success } = useAppSelector((state) => state?.auth);

    const validations = (val: UserData): FormErrors => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const err: FormErrors = { email: "", password: "" };
        if (val.email.length === 0) {
            err.email = "Email is required!";
        }
        if (val.email.length > 0 && !regex.test(val.email)) {
            err.email = "This is not a valid email format!";
        }
        if (!val.password) {
            err.password = "Password is required!";
        }
        return err;
    };

    const userData: UserData = {
        email: email ? email : "",
        password: password ? password : "",
    };

    const handleCheckboxChange = () => {
        setCheckState(!checkState);
    };

    const handleClick = async (e: React.FormEvent) => {
        e.preventDefault();
        const errors = validations(userData);
        setFormErr(errors);
        if (Object.values(errors).every(error => !error)) {
            await dispatch(adminloginAsync(userData));
            if (checkState) {
                setCookie();
            } else {
                Cookies.remove("legallinkcookie");
            }
        }
    };

    useEffect(() => {
        dispatch(isValidDomainAsync({ host: domain }));
        getCookie();
    }, []);

    const Token: any = localStorage.getItem("auth");
    const Role: any = localStorage.getItem("role");

    const setCookie = () => {
        const myObject = { email: email, password: password };
        const jsonString = JSON.stringify(myObject);
        const encryptedData = CryptoJS.AES.encrypt(jsonString, 'your-secret-key').toString();
        Cookies.set("legallinkcookie", encryptedData, { expires: 7 });
    };

    const getCookie = () => {
        const encryptedData = Cookies.get("legallinkcookie");
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, 'your-secret-key').toString(CryptoJS.enc.Utf8);
            const storedObject = JSON.parse(decryptedData);

            if (Object.keys(storedObject).length !== 0) {
                setCheckState(true);
                setEmail(storedObject?.email);
                setPassword(storedObject?.password);
            }
        }
    };

    useEffect(() => {
        if (Token) {
            navigate("/admin/user-management");
        }
        if (status === "200" && Token) {
            navigate("/admin/user-management");
        }
    }, [status, Token]);


    return (
        <div className="main-login">
            <div className="login-inner">
                <div className="logo-outer">
                    <div className="logo">
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="text-inner">
                        <CustomHeading text={"AI "} level={1} />
                        <CustomHeading text={"At Your Service"} level={3} />
                        <CustomHeading text={"Navigate. Simplify. Understand."} level={4} />
                    </div>
                </div>
                <div className="welcome-outer">
                    <CustomHeading text={"Welcome Admin"} level={3} />
                    <Form onSubmit={handleClick}>
                        <div className="input-outer">
                            <Customlabel text={"Email*"} />

                            <div className="input-inner">
                                <EmailIcon />

                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <p className="text-danger">{formErr?.email}</p>
                        </div>
                        <div className="input-outer">
                            <Customlabel text={"Password*"} />
                            <div className="input-inner">
                                <PasswordIcon />

                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <p className="text-danger">{formErr?.password}</p>
                        </div>
                        <div className="remeber-inner">
                            <Form.Group
                                className="forgot-inner"
                                controlId="formBasicCheckbox"
                            >
                                <Form.Check
                                    type="checkbox"
                                    label="Remember me"
                                    onChange={handleCheckboxChange}
                                    checked={checkState}
                                />
                            </Form.Group>
                            <Link style={{ textDecoration: "none" }} to="/admin/forgot-password">
                                <CustomP text="Forgot password " />
                            </Link>
                        </div>
                        <div className="submit-btn">
                            <CustomSubmitButton
                                typ="submit"
                                classNam="btn custom-primary"
                                text="Sign in"
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};
export default AdminLogin;
