import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomSpinner from "../components/spinner/spinner";
import { Container, Nav, Tab } from "react-bootstrap";
import CustomButton from "../components/button/button";
import CustomHeading from "../components/heading/heading";
import { useAppDispatch, useAppSelector } from "../hook"
import { changeNumberAsync, getSectionDataAsync } from "../store/Slices/AggrementSlice";
import { changeSectionPositionAsync } from "../store/Slices/editContentSlice";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "skyblue" : "grey",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
});

const ClausePositionPage = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const { state } = useLocation();
    const { manageAdminloading } = useAppSelector((state): any => state?.editContract)
    const { sectionData } = useAppSelector((state: any) => state?.getAggrement);
    const [tasks, setTasks] = useState(Array);
    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const updatedItems: any = reorder(
            tasks,
            result.source.index,
            result.destination.index
        );
        setTasks(updatedItems);
        if (result.source.index !== result.destination.index) {
            dispatch(changeSectionPositionAsync({
                "section_id": state?.section_id,
                "new_position": result.destination.index + 1,
                "clause_id": result?.draggableId,
                "old_position": result.source.index + 1
            }))
        }
    };

    useEffect(() => {
        if (!manageAdminloading) { dispatch(getSectionDataAsync(state?.section_id)) }
    }, [manageAdminloading, state])

    useEffect(() => {
        setTasks(sectionData?.data?.map((elem) => { return elem })
        )
    }, [sectionData])

    return (
        <div className="custom-model">
            {manageAdminloading && (
                <div className="custom-spinner-container">
                    <CustomSpinner />
                </div>
            )}
            <div className="dashboard-main agreement-mains">
                <Container style={{ width: "78%" }} >
                    <div className="my-5 d-flex w-100 justify-content-between "
                        onClick={() => navigate(-1)}
                    >
                        <CustomButton
                            classNam="custom-btn custom-primary"
                            text="Go Back"
                        />
                    </div>
                    <div className="contract-pdf">
                        <CustomHeading text="Contract" level={2} />
                        <div className="right-pdficon">
                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="custom-row">
                            <div className=" w-100">
                                <div className="leftScroll">
                                    <Nav
                                        variant="pills"
                                        className="flex-column agreement-main trigger pe-auto"
                                    >
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="tasks">
                                                {(provided, snapshot) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                    >
                                                        {tasks?.length >= 1 ? tasks.map((task: any, index: number) => (
                                                            < Draggable key={task.id} draggableId={task.id.toString()} index={index} >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                    >
                                                                        <div
                                                                            key={index}
                                                                            className="d-flex w-100 justify-content-between py-auto doted-border "
                                                                        >
                                                                            <p
                                                                                className="py-2 my-auto cursoor position"
                                                                                dangerouslySetInnerHTML={{ __html: task?.clause }}
                                                                            >
                                                                            </p>
                                                                            <input placeholder="Please enter number" className="border border 0 rounded ps-3 my-auto py-2" value={task?.number} onChange={
                                                                                (e) => {
                                                                                    dispatch(changeNumberAsync({ "clauseId": task?.id, "clauseNumber": e.target.value }))
                                                                                    setTasks(tasks.map((elem: any, ind) => {
                                                                                        return ind === index ? { ...elem, number: e.target.value } : elem
                                                                                    }))
                                                                                }} />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )) : "No data available"}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Nav >
                                </div >
                            </div >
                        </div >
                    </Tab.Container >
                </Container >
            </div >
        </div >
    );
};


export default ClausePositionPage