import "./App.scss";
import Login from "./pages/Login";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import ContractList from "./pages/ContractList";
import AgreementDetails from "./pages/AgreementDetails";
import ClauseDetails from "./pages/ClauseDetails";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/ForgotPassword";
import CustomSearch from "./pages/SearchTop";
import ProfileDetails from "./pages/ProfileDetails";
import ProtectedRoute from "./Routing/ProtectedRoute";
import ChangePassword from "./pages/ChangePassword";
import PageNotFound from "./pages/pageNotFound";
import AddClauses from "./pages/AddClauses";
import EditClauses from "./pages/EditClause";
import ClausePositionPage from './pages/ClausePositionPage'
import UserList from "./pages/UserManagement";
import AddUser from "./pages/AddUser";
import RoleManagement from './pages/RoleManagement'
import AddRole from "./pages/AddRole";
import ViewRole from "./pages/ViewRole";
import EditUserPage from "./pages/EditUser";
import AdminLogin from "./pages/AdminPages/AdminLogin";
import AdminUserList from "./pages/AdminPages/AdminUserManagement";
import AdminEditUserPage from "./pages/AdminPages/AdminEditUser";
import AdminProfiledetails from "./pages/AdminPages/AdminProfileDetail";
import AdminChangePassword from "./pages/AdminPages/AdminChangePassword";
import AdminAddUser from "./pages/AdminPages/AdminAddUser";
import AdminForgotPassword from "./pages/AdminPages/AdminForgotPassword";
import { useAppDispatch } from "./hook";
import { isValidDomainAsync } from "./store/Slices/authSlice";
import { useEffect } from "react";


function App() {
  const domain = window.location.hostname

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter basename="/">
        {domain.split('.')[0] == "sa" ? <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" element={<AdminLogin />} />
          <Route path="/admin/add-user" element={<ProtectedRoute> <AdminAddUser /></ProtectedRoute>} />
          <Route path="/admin/resetpassword" element={<AdminChangePassword />} />
          <Route path="/admin/forgot-password" element={<AdminForgotPassword />} />
          <Route path="/admin/view-user" element={<ProtectedRoute> <AdminEditUserPage /></ProtectedRoute>} />
          <Route path="/admin/edit-user" element={<ProtectedRoute> <AdminEditUserPage /></ProtectedRoute>} />
          <Route path="/admin/user-management" element={<ProtectedRoute> <AdminUserList /></ProtectedRoute>} />
          <Route path="/admin/profile-detail" element={<ProtectedRoute> <AdminProfiledetails /></ProtectedRoute>} />
        </Routes> :
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/dashboard" element={<ProtectedRoute> <Dashboard /></ProtectedRoute>} />
            <Route path="/contract-list" element={
              <ProtectedRoute>
                <ContractList />
              </ProtectedRoute>
            }
            />
            <Route path="/user-management" element={
              <ProtectedRoute>
                <UserList />
              </ProtectedRoute>
            }
            />
            <Route path="/add-user" element={
              <ProtectedRoute>
                <AddUser />
              </ProtectedRoute>
            }
            />
            <Route path="/edit-user" element={
              <ProtectedRoute>
                <EditUserPage />
              </ProtectedRoute>
            }
            />
            <Route path="/view-user" element={
              <ProtectedRoute>
                <EditUserPage />
              </ProtectedRoute>
            }
            />
            <Route path="/add-role" element={
              <ProtectedRoute>
                <AddRole />
              </ProtectedRoute>
            }
            />
            <Route path="/edit-role" element={
              <ProtectedRoute>
                <ViewRole />
              </ProtectedRoute>
            }
            />
            <Route path="/view-role" element={
              <ProtectedRoute>
                <ViewRole />
              </ProtectedRoute>
            }
            />
            <Route path="/role-management" element={
              <ProtectedRoute>
                <RoleManagement />
              </ProtectedRoute>
            }
            />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ChangePassword />} />
            <Route
              path="/agreement-details/:id"
              element={
                <ProtectedRoute>
                  <AgreementDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/clause-details/:id"
              element={
                <ProtectedRoute>
                  <ClauseDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/clause-position-page/:id"
              element={
                <ProtectedRoute>
                  <ClausePositionPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/addClauses/:id"
              element={
                <ProtectedRoute>
                  <AddClauses />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editClauses/:id"
              element={
                <ProtectedRoute>
                  <EditClauses />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile-detail"
              element={
                <ProtectedRoute>
                  <ProfileDetails />
                </ProtectedRoute>
              }
            />
            <Route path="/search" element={<ProtectedRoute><CustomSearch /></ProtectedRoute>} />
          </Routes>}
      </BrowserRouter>
    </div>
  );
}

export default App;
