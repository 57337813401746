export const FALSE = false;
export const TRUE = true;
export const NULL = null;
export const ACTIVE = "active";
export const FULLFILLED = "fullfilled";
export const REJECTED = "rejected";
export const PENDING = "pending";
export const IDLE = "idle";
export const ERROR = "error";
export const INVALID_CREDENTIAL = "invalid credential";

