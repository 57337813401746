import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import "../../src/pages/scss/search.scss";
import { SearchIcon, ArrowIcon } from "../components/svgicon/svg";
import Chatbot from "../components/chatbox/chatBot/chatBot";
import ChatButton from "../components/chatbox/chatIcon";
import { highLightAsync } from "../store/Slices/clauseSlice";
import { useAppDispatch, useAppSelector } from "../hook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SearchToggleIcon from "../assets/ArrowIconBlue.png";

const CustomSearch = () => {
  const domain = window.location.hostname;
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [data, setData] = useState([]);
  const [index, setIndex] = useState([null]);
  const dispatch = useAppDispatch();
  const [chatShow, setChatShow] = useState(false);
  const { highLightData } = useAppSelector((state): any => state.clauseDetails);
  const handleChatShow = () => setChatShow(!chatShow);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  // useEffect(() => {
  //   dispatch(
  //     highLightAsync({
  //       word: search,
  //       database_name:
  //         domain.split(".")[0] == "www"
  //           ? domain.split(".")[1]
  //           : domain.split(".")[0],
  //     })
  //   );
  // }, [search]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500); // 500ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    // if (debouncedSearch) {
    dispatch(
      highLightAsync({
        word: debouncedSearch,
        database_name:
          domain.split(".")[0] === "www"
            ? domain.split(".")[1]
            : domain.split(".")[0],
      })
    );
    // }
  }, [debouncedSearch, domain, dispatch]);

  const handleNavigate = () => {
    navigate("/contract-list");
  };


  const handleOpenCard = (id: any) => {
    if (index.length >= 1 && index?.includes(id)) {
      setIndex(index.filter((ind) => { return ind != id }))
    } else {
      setIndex([...index, id])
    }
  }


  return (
    <div>
      <div className="dashboard-main custom-search">
        <Container>
          <div className="search-outer contrast-list">
            <div className="common-search">
              <span className="left-icon">
                <SearchIcon />
              </span>
              <span className="right-icon">{/* <ArrowIcon /> */}</span>
              <input
                type="text"
                placeholder="Search your keyword here"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span onClick={handleNavigate} className="right-icon">
                <img
                  src={SearchToggleIcon}
                  alt="searchArrowIcon"
                  className="searchArrow"
                />
              </span>
            </div>
          </div>

          <div className="clause-outer">
            <div className="interipted-outer">
              {highLightData && (
                highLightData?.highlight_text?.map((ele: any, ind: any) => {
                  return (
                    <div
                      key={ind}
                      className={`card expandParagraph shadow px-3 pt-3 mb-5 wrapBox border border-2 rounded-4 position-relative h-100 ${ind == index ? "h-100 transitionHeight" : ""
                        }`}
                    >
                      <div className="accordion" id={`accordionExample ${ind}`}>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button pb-0 bg-transparent">
                              <div>
                                <div
                                  className="pdf-inner"
                                  onClick={() =>
                                    navigate(
                                      `/agreement-details/${ele.contract_id}`,
                                      {
                                        state: {
                                          contract_id: ele.contract_id,
                                        },
                                      }
                                    )
                                  }
                                >
                                  <b className="title">{ele?.contract_name}</b>

                                  {ele?.sections?.map((elem, index) => {
                                    if (index == 0) {
                                      return (<div><h3>{elem?.section_title}</h3>
                                        <div >{elem?.clauses?.map((clause, indx) => {
                                          if (indx < 3) {
                                            return (<div className="d-flex gap-3">
                                              <span>{clause?.number}</span>
                                              <span
                                                // className="py-3"
                                                dangerouslySetInnerHTML={{
                                                  __html: clause?.clause,
                                                }}
                                              ></span>
                                            </div>)
                                          }
                                        })}</div>
                                      </div>)
                                    }
                                  })}
                                </div>
                                <div>

                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${ind}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body py-0">
                              {ele?.sections?.map((elem, index) => {
                                return (<div><h3>{index >= 1 ? elem?.section_title : null}</h3>
                                  <div>{elem?.clauses?.map((clause, indx) => {
                                    if ((index == 0 && indx >= 2) || index >= 1) {
                                      return (<div className="d-flex gap-3">
                                        <span>{clause?.number}</span>
                                        <span
                                          // className="py-3"
                                          dangerouslySetInnerHTML={{
                                            __html: clause?.clause,
                                          }}
                                        ></span>
                                      </div>)
                                    }
                                  })}</div>
                                </div>)
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="openAccordion"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${ind}`}
                          aria-expanded="true"
                          aria-controls={`collapseOne${ind}`}
                          onClick={() => handleOpenCard(ind)}
                        >
                          {index.length >= 1 && index.includes(ind) ? "Show less" : "Show more"}
                        </button>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </Container>
        {chatShow == true ? <Chatbot /> : ""}
        <ChatButton Click={handleChatShow} show={chatShow} />
      </div>
    </div>
  );
};

export default CustomSearch;

// <p
//   className="py-3"
//   dangerouslySetInnerHTML={{
//     __html: elem,
//   }}
// ></p>