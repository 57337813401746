import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomHeading from "../components/heading/heading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Delete,
  DateIcon,
} from "../components/svgicon/svg";
import CustomButton from "../components/button/button";
import "../pages/scss/clausedetails.scss";
import { useAppDispatch, useAppSelector } from "../hook";
import { contractHistoryAsync } from "../store/Slices/contractHubSlice";
import Chatbot from "../components/chatbox/chatBot/chatBot";
import ChatButton from "../components/chatbox/chatIcon";
import { postSimplifiedAsync, reset } from "../store/Slices/clauseSlice";
import { getContractHubAsync } from "../store/Slices/contractHubSlice";
import CustomSpinner from "../components/spinner/spinner";
import { clearAllState, getDefinationAsync, getLinksAsync } from "../store/Slices/editContentSlice";
import { getAggrementClauseAsync } from "../store/Slices/AggrementSlice";
import moment from 'moment'



const ClauseDetails = () => {
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [history, setHistory] = useState([])
  const [chatShow, setChatShow] = useState(false)
  const [textToCopy, setTextToCopy] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [currentClause, setCurrentClause] = useState({
    clause: "",
    clause_id: null,
    createdAt: "",
    position: null,
    status: null
  })
  const back = () => {
    setTextToCopy("")
    dispatch(reset())
    navigate(-1);
  };
  const { isLoading } = useAppSelector((state): any => state.clauseDetails)
  const { getDefinationData, getLinksData } = useAppSelector((state: any) => state.editContract)
  const { simplifiedData } = useAppSelector((state): any => state.clauseDetails)
  const { postContractDetails, manageAdminloading, getContractDetails } = useAppSelector((state): any => state.getAllContracts)
  const { getAggrementClauseDetails } = useAppSelector((state): any => state?.getAggrement)
  const handleChatShow = () => setChatShow(!chatShow)
  const handleCopy = () => {
    setIsCopied(true);
  };


  useEffect(() => {
    if (state && getAggrementClauseDetails) {
      getAggrementClauseDetails?.data?.map((ele) => {
        if (ele?.id == state?.section_id) {
          ele?.clauses?.map((elem) => {
            if (elem?.clause_id == state?.clause_id) {
              setCurrentClause(elem)
            }
          })
        }
      })
    }

  }, [getAggrementClauseDetails])



  const handlePhrase = () => {
    dispatch(postSimplifiedAsync([state?.text]))
  }
  useEffect(() => {
    if (simplifiedData != null) {
      setTextToCopy(simplifiedData)
    }
  }, [simplifiedData])

  useEffect(() => {
    setHistory(postContractDetails?.data)
  }, [postContractDetails])

  useEffect(() => {
    dispatch(contractHistoryAsync({ contract_id: state?.contract_id, clause_id: state?.clause_id }))
    dispatch(getDefinationAsync(state?.clause_id))
    dispatch(getAggrementClauseAsync(state?.contract_id))
  }, [state])

  useEffect(() => {
    dispatch(
      getContractHubAsync({
        isFavorite: 1 ? 1 : 0,
        limit: 10,
        page: 1,
        searchstring: "",
      })
    );
    dispatch(getLinksAsync(state?.clause_id))
  }, []);

  const handleRedirect = (link: string) => {
    window.open(link, '_blank');
  }

  const getTime = (time: any) => {
    if (
      moment(time)?.format("DD MMMM YYYY") ==
      moment(new Date())?.format("MMMM DD YYYY")
    ) {
      return "Today";
    } else {
      return moment(time).format("DD MMM YYYY");
    }
  };

  const dangerFun = (obj) => {
    console.log(obj, "_____________________obj")
    return <span dangerouslySetInnerHTML={{ __html: obj }}></span>
  }


  return (
    <>
      {manageAdminloading || isLoading && (
        <div className="custom-spinner-container">
          <CustomSpinner />
        </div>
      )}
      <div className="dashboard-main clause-details">

        <Container >
          <div className="search-outer agreement-search d-flex align-items-center justify-content-between">
            <div className="blue_text" onClick={back}><span>{state?.contract_name}</span> / <span>{state?.section_name}</span> / <span>{state?.clause_id}</span></div>
            <div className="">
              <p className="mb-0">
                <span onClick={back} className="pointer">
                  <svg
                    width="22"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.804335 7.9918L4.35223 11.7181C4.43745 11.8074 4.53885 11.8783 4.65056 11.9267C4.76228 11.9751 4.88211 12 5.00313 12C5.12415 12 5.24398 11.9751 5.3557 11.9267C5.46741 11.8783 5.56881 11.8074 5.65404 11.7181C5.73996 11.6295 5.80816 11.5241 5.85471 11.408C5.90125 11.2918 5.92521 11.1673 5.92521 11.0415C5.92521 10.9156 5.90125 10.7911 5.85471 10.6749C5.80816 10.5588 5.73996 10.4534 5.65404 10.3648L2.39034 6.95302H21.0832C21.3264 6.95302 21.5596 6.85261 21.7315 6.67388C21.9034 6.49516 22 6.25276 22 6C22 5.74724 21.9034 5.50484 21.7315 5.32612C21.5596 5.14739 21.3264 5.04698 21.0832 5.04698H2.33534L5.65404 1.59706C5.81657 1.41982 5.90724 1.18435 5.90724 0.939482C5.90724 0.694613 5.81657 0.459139 5.65404 0.281901C5.56881 0.192576 5.46741 0.121677 5.3557 0.0732933C5.24398 0.0249099 5.12415 0 5.00313 0C4.88211 0 4.76228 0.0249099 4.65056 0.0732933C4.53885 0.121677 4.43745 0.192576 4.35223 0.281901L0.804335 3.95101C0.289294 4.48709 0 5.21376 0 5.97141C0 6.72906 0.289294 7.45573 0.804335 7.9918Z"
                      fill="#00AEEF"
                    />
                  </svg>
                  <span className="ms-1">Back to Page</span>
                </span>
              </p>
            </div>
          </div>
          <Row >
            <Col lg={6} md={12}>
              <div className="origin-outer">
                <CustomHeading text="Original" level={3} />
                <p className="m-0">{dangerFun(state?.text)}</p>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="origin-outer version-outer">
                <div className="main-title">
                  <CustomHeading text="Simplified Version" level={3} />
                  <div className="copy-outer">
                    <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
                      <svg
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.324 2.72667L13.4968 0.760833C13.2724 0.520805 13.0042 0.329867 12.7081 0.199218C12.4119 0.0685687 12.0936 0.000838693 11.772 0L8 0C7.07823 0.0012121 6.18505 0.333408 5.471 0.940598C4.75694 1.54779 4.26568 2.39284 4.08 3.33333H4C2.93952 3.33466 1.92285 3.77407 1.17298 4.55518C0.423105 5.3363 0.00127029 6.39534 0 7.5V15.8333C0.00127029 16.938 0.423105 17.997 1.17298 18.7782C1.92285 19.5593 2.93952 19.9987 4 20H8.8C9.86047 19.9987 10.8772 19.5593 11.627 18.7782C12.3769 17.997 12.7987 16.938 12.8 15.8333V15.75C13.7029 15.5566 14.5141 15.0449 15.097 14.301C15.6799 13.5572 15.9988 12.6268 16 11.6667V4.46667C16.0012 3.81701 15.7586 3.1926 15.324 2.72667ZM8.8 18.3333H4C3.36348 18.3333 2.75303 18.0699 2.30294 17.6011C1.85286 17.1323 1.6 16.4964 1.6 15.8333V7.5C1.6 6.83696 1.85286 6.20107 2.30294 5.73223C2.75303 5.26339 3.36348 5 4 5V11.6667C4.00127 12.7713 4.4231 13.8304 5.17297 14.6115C5.92284 15.3926 6.93952 15.832 8 15.8333H11.2C11.2 16.4964 10.9471 17.1323 10.4971 17.6011C10.047 18.0699 9.43652 18.3333 8.8 18.3333ZM12 14.1667H8C7.36348 14.1667 6.75303 13.9033 6.30294 13.4344C5.85285 12.9656 5.6 12.3297 5.6 11.6667V4.16667C5.6 3.50363 5.85285 2.86774 6.30294 2.3989C6.75303 1.93006 7.36348 1.66667 8 1.66667H11.2V3.33333C11.2 3.77536 11.3686 4.19928 11.6686 4.51184C11.9687 4.82441 12.3757 5 12.8 5H14.4V11.6667C14.4 12.3297 14.1471 12.9656 13.6971 13.4344C13.247 13.9033 12.6365 14.1667 12 14.1667Z"
                          fill="#00AEEF"
                        />
                      </svg>
                    </CopyToClipboard>

                    {isCopied ? (
                      <span className="mx-2" style={{ color: "#00AEEF" }}>Copied!</span>
                    ) : null}
                    <span onClick={() => setTextToCopy("")}><Delete /></span>
                  </div>
                </div>
                <p onChange={(e: any) => setTextToCopy(e.target.value)}>
                  {textToCopy != null ? textToCopy : ""}
                </p>

                <div className="hub-outer1" onClick={handlePhrase}>
                  <CustomButton
                    classNam="custom-btn custom-primary mb-3"
                    text="Paraphrase"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12} lg={6}>
              <div className="terms-outer">
                <h5>Defined Terms</h5>
                <div className="terms-innertext">
                  {getDefinationData ? getDefinationData?.map((ele, ind) => {
                    return (
                      <p><b>{ele.word}:</b>
                        {ele.defination}</p>
                    )
                  }) : <p>No data available</p>}

                </div>
                <CustomHeading text="Links to" level={5} />
                <div className="terms-innertext terms-link">
                  {getLinksData ? getLinksData?.map((ele, ind) => {
                    return (
                      <div
                        className="cursoor"
                      >
                        <a className="custom-text" onClick={() => window.open(ele?.Link)}><b>{ele.word}</b></a>
                      </div>
                    )
                  }) : <p>No data Available</p>}
                </div>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="terms-outer righ">
                <CustomHeading text="History" level={5} />
                <div className="terms-innertext history-outer">
                  {postContractDetails?.data?.map((elem, ind) => {
                    return (
                      <div className="main-history">
                        <div className="flex_text w-100 justify-content-between">
                          <span className="blue_text text-start">{elem?.reason ? elem?.reason : state?.contract_name}</span>
                          <p>
                            <DateIcon />
                            <span>
                              {ind == postContractDetails?.data.length - 1 ? moment(postContractDetails?.contract?.date)?.format("DD MMM YYYY") : moment(elem?.date)?.format("DD MMM YYYY")}
                            </span>
                          </p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: JSON.parse(elem?.current).clause }}></p>
                      </div>

                    )
                  })}

                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {
          chatShow == true
            ?
            <Chatbot /> : ""
        }
        <ChatButton Click={handleChatShow} show={chatShow} />
      </div>
    </>
  );
};
export default ClauseDetails;
