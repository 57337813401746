import { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../hook"
import { createRoleAsync, getAuthoritiesAsync, reset } from "../store/Slices/RoleManagementSlice"
import DataTable from "react-data-table-component"
import '../pages/scss/addRole.scss'
import { Role, Auth } from "../Interface"



const AddRole = () => {
    const { state } = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { authoritiesData, message, statusCode } = useAppSelector((state): any => state?.RoleManagement)
    const [role, setRole] = useState("")
    const [description, setDescription] = useState("")
    const [authority, setAuthority] = useState<Auth[]>([])

    const [roleError, setRoleError] = useState({
        role: "",
        description: ""
    })

    const validate = () => {
        let valid = true
        let obj = {
            role: "",
            description: ""
        }
        if (!role) {
            valid = false
            obj.role = "Enter role"
        }
        if (!description) {
            valid = false
            obj.description = "Enter description"
        }
        setRoleError(obj)
        return valid
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        if (validate()) {
            await dispatch(createRoleAsync({
                name: role,
                description: description,
                data: authority
            }))
        }
    }

    useEffect(() => {
        if (message.trim() == "successfully added role" && statusCode == 201) {
            dispatch(reset())
            navigate('/role-management')
        }

    }, [message, statusCode])


    const columns = [
        {
            name: 'Module',
            selector: row => row.name
        },
        {
            name: 'Add',
            selector: (row) => <input type="checkbox" name="add" checked={row?.add}
                onChange={() => {
                    let obj
                    setAuthority(authority?.map((elem: any) => {
                        if (elem?.name == row?.name) {
                            return obj = { ...elem, add: !row?.add }
                        } else {
                            return obj = { ...elem }
                        }
                    }))
                }}
                placeholder="checkbox" />
        },
        {
            name: 'Edit',
            selector: (row) => <input type="checkbox" name="edit" checked={row?.edit}
                onChange={() => {
                    let obj
                    setAuthority(authority?.map((elem: any) => {
                        if (elem.name == row?.name) {
                            return obj = { ...elem, edit: !row?.edit }
                        } else {
                            return obj = { ...elem }
                        }
                    }))
                }}
                placeholder="checkbox" />
        },
        {
            name: 'View',
            selector: (row) => <input type="checkbox" name="view" checked={row?.view}
                onChange={() => {
                    let obj
                    setAuthority(authority?.map((elem: any) => {
                        if (elem.name == row?.name) {
                            return obj = { ...elem, view: !row?.view }
                        } else {
                            return obj = { ...elem }
                        }
                    }))
                }}
                placeholder="checkbox" />
        },
        {
            name: 'Delete',
            selector: (row) => <input type="checkbox" name="del" checked={row?.delete}
                onChange={() => {
                    let obj
                    setAuthority(authority?.map((elem: any) => {
                        if (elem.name == row?.name) {
                            return obj = { ...elem, delete: !row?.delete }
                        } else {
                            return obj = { ...elem }
                        }
                    }))
                }}
                placeholder="checkbox" />
        }
    ]

    useEffect(() => {
        dispatch(getAuthoritiesAsync())
    }, [])
    useEffect(() => {
        setAuthority(authoritiesData?.map((elem) => {
            return {
                name: elem?.authority,
                add: true,
                view: true,
                edit: true,
                delete: true
            }
        }))
    }, [authoritiesData])

    return (
        <>

            <div className="dashboard-main">
                <Container>
                    <div className="d-flex justify-content-between  py-3 mt-5 w-100 mb-3">
                        <h3 className=""><b>Add Role</b></h3>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="w-48 d-flex flex-column mb-3">
                            <label className="text-dark mb-2">Role Name</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" type="text" placeholder="Enter Role..." onChange={(e) => setRole(e.target.value)} value={role} />
                            <p className="text-danger">{roleError?.role}</p>
                        </div>
                        <div className="w-48 d-flex flex-column mb-5">
                            <label className="text-dark mb-2">Description</label>
                            <input className="border border-2 rounded mb-2 py-2 ps-2" type="text" placeholder="Enter Role Description" onChange={(e) => setDescription(e.target.value)} value={description} />
                            <p className="text-danger">{roleError?.description}</p>
                        </div>
                    </div>
                    <div className="data-table custom-datatable">
                        {authority?.length >= 1 ?
                            <DataTable
                                columns={columns}
                                data={authority}
                            />
                            : "No data available"}
                    </div>
                    <div className="d-flex w-100 justify-content-center mt-5">
                        <button className="custom-btn custom-primary me-3" onClick={(e) => handleUpdate(e)}>Create</button>
                        <button className="custom-btn custom-primary me-3" onClick={() => navigate('/role-management')}>Back</button>
                    </div>
                </Container >
            </div >
        </>
    )
}

export default AddRole