import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../src/pages/scss/addClauses.scss";
import { Add, Delete } from "../assets/icons";
import CustomQuill from "../components/reactQuill/ReactQuill";
import TestCkEditorComponent from "../components/reactQuill/ckEditorComp";
import {
    clearAllState,
    editContentAsync,
    postLinksAsync,
    deleteClauseAsync
} from "../store/Slices/editContentSlice";
import { getClauseAsync, getOriginalContentAsync } from "../store/Slices/clauseSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hook";
import CustomHeading from "../components/heading/heading";
import { toast } from "react-toastify";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { deleteFromML, postPdfToMLAsync, reset } from "../store/Slices/contractHubSlice";
import CustomSpinner from "../components/spinner/spinner";


const EditClauses = () => {
    const domain = window.location.hostname
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const [message, setMessage] = useState("");
    const [sectionClauseId, setSectionClauseId] = useState(null)
    const [checkedValue, setCheckedValue] = useState("Amendment");
    const [date, setDate] = useState("")
    const [inputFields, setInputFields] = useState([{ type: "", value: "" }]);
    const [inputLinkFields, setInputLinkFields] = useState([
        { type: "", value: "" },
    ]);
    const [quillError, setQuillError] = useState("");
    const [errors, setErrors] = useState<(string | never)[]>([]);
    const [linkErrors, setLinkErrors] = useState<(string | never)[]>([]);
    const [loading, setLoading] = useState(false)
    const [reason, setReason] = useState("")
    const [reasonErr, setReasonErr] = useState("")
    const [data, setData] = useState({
        title: "",
        description: "",
    });

    const { clauseDetail, linkData, definitionData } = useAppSelector((state: any) => state?.clauseDetails);
    const { editContentData, error1 } = useAppSelector((state): any => state?.editContract)

    const arr = inputFields?.map((e) => {
        return {
            word: e.type,
            defination: e.value,
            clause_id: state?.clause_id
        };
    });

    const linkArr = inputLinkFields?.map((e) => {
        return {
            word: e.type,
            Link: e.value,
            clause_id: state?.clause_id
        };
    });

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        values[index].value = event.target.value;
        setInputFields(values);
        validateField(index);
    };

    const handleTypeChange = (index, event) => {
        const values = [...inputFields];
        values[index].type = event.target.value;
        setInputFields(values);
        validateField(index);
    };

    const validateField = (index) => {
        const newErrors = [...errors];
        newErrors[index] = "";
        setErrors(newErrors);
    };

    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ type: "", value: "" });
        setInputFields(values);
        setErrors([...errors, ""]);
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
        const newErrors = [...errors];
        newErrors.splice(index, 1);
        setErrors(newErrors);
    };

    const handleAddLinkFields = () => {
        const values = [...inputLinkFields];
        values.push({ type: "", value: "" });
        setInputLinkFields(values);
    };

    const handleInputLinkChange = (index, event) => {
        const values = [...inputLinkFields];
        values[index].value = event.target.value;
        setInputLinkFields(values);
        validateLinkField(index);
    };

    const handleTypeLinkChange = (index, event) => {
        const values = [...inputLinkFields];
        values[index].type = event.target.value;
        setInputLinkFields(values);
        validateLinkField(index);
    };

    const validateLinkField = (index) => {
        const newLinkErrors = [...linkErrors];
        newLinkErrors[index] = "";
        setLinkErrors(newLinkErrors);
    };

    const handleRemoveLinkFields = (index) => {
        const values = [...inputLinkFields];
        values.splice(index, 1);
        setInputLinkFields(values);
    };

    const handleLinksSubmit = () => {
        dispatch(
            postLinksAsync({
                data: linkArr,
            })
        ).then(() => {
            setInputLinkFields([{ type: "", value: "" }]);
        });
    };

    const definitionsAreFilled = () => {
        return inputFields.some(
            (field) => field.type.trim() !== "" && field.value.trim() !== ""
        );
    };


    useEffect(() => {
        if (state?.checkedValue == "uptodatePdf") {
            dispatch(getClauseAsync({
                clause_id: state?.clause_id,
            }))
        }
        else {
            dispatch(getClauseAsync({
                clause_id: state?.clause_id,
            }))
        }
        setInputFields([{ type: "", value: "" }])
        setInputLinkFields([{ type: "", value: "" }])
    }, [state]);


    useEffect(() => {
        if (clauseDetail) {
            clauseDetail?.map((elem) => {
                setDate(elem?.createdAt.split('T')[0])
                setSectionClauseId(elem?.section_clause_id)
                return setData({ title: elem?.title, description: elem?.clause });
            });
        }
        if (linkData?.length >= 1) {
            setInputLinkFields(linkData.map((elem) => {
                return { type: elem?.word, value: elem?.Link }
            }))
        } else {
            setInputLinkFields([{ type: "", value: "" }])
        }
        if (definitionData?.length >= 1) {
            setInputFields(definitionData.map((ele) => {
                return { type: ele?.word, value: ele?.defination }
            }))
        } else {
            setInputFields([{ type: "", value: "" }])
        }
    }, [clauseDetail, linkData, definitionData]);


    useEffect(() => {
        if (data) {
            setMessage(data?.description);
        }
    }, [data]);

    useEffect(() => {
        if (checkedValue === "Addition" && editContentData == "201") {
            setTimeout(async () => {
                await dispatch(deleteFromML({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
                await dispatch(postPdfToMLAsync(state?.contract_id))
                toast.success("Data added successfully")
                setLoading(false)
                dispatch(clearAllState())
                setInputFields([{ type: "", value: "" }])
                setInputLinkFields([{ type: "", value: "" }])
                navigate(-1)
            }, 700)
        }
        if (checkedValue === "Amendment" && editContentData == "201") {
            setTimeout(async () => {
                await dispatch(deleteFromML({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
                await dispatch(postPdfToMLAsync({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
                toast.success("Data edited successfully")
                setLoading(false)
                dispatch(clearAllState())
                dispatch(reset())
                setInputFields([{ type: "", value: "" }])
                setInputLinkFields([{ type: "", value: "" }])
                navigate(-1)
            }, 300)

        }
        if (checkedValue === "Deletion" && editContentData == "201") {
            setTimeout(async () => {
                await dispatch(deleteFromML({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
                await dispatch(postPdfToMLAsync({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
                toast.success("Data deleted successfully")
                setLoading(false)
                dispatch(clearAllState())
                dispatch(reset())
                setInputFields([{ type: "", value: "" }])
                setInputLinkFields([{ type: "", value: "" }])
                navigate(-1)
            }, 300)

        }
        if (checkedValue === "Permanent_Delete" && editContentData == "200") {
            setTimeout(async () => {
                await dispatch(deleteFromML({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
                await dispatch(postPdfToMLAsync({ id: state?.contract_id, database_name: domain.split('.')[0] == "www" ? domain.split('.')[1] : domain.split('.')[0] }))
                toast.success("Data deleted Permanently")
                setLoading(false)
                dispatch(clearAllState())
                dispatch(reset())
                setInputFields([{ type: "", value: "" }])
                setInputLinkFields([{ type: "", value: "" }])
                navigate(-1)
            }, 300)
        }
    }, [editContentData])


    const handleEditClick = () => {
        const isDefinitionDataFilled = definitionsAreFilled();
        const isLinkDataFilled = inputLinkFields.some(
            (field) => field.type.trim() !== "" && field.value.trim() !== ""
        );
        const isFormValid = validateForm();
        if (isFormValid) {
            setLoading(true)
            let text = message
            // arr.forEach((ele) => {
            //     if (ele?.word.trim() != "") {
            //         if (text.includes(`<span class="ABC021212">${ele?.word}</span>`)) {
            //             const link = `<span class="ABC021212">${ele?.word}</span>`
            //             text = text.split(ele.word).join(link)
            //         }
            //         else {
            //             const link = `<span class="ABC021212">${ele?.word}</span>`
            //             text = text.split(ele.word).join(link)
            //         }
            //     }
            // });
            checkedValue === "Addition"
                ? dispatch(
                    editContentAsync({
                        clause: {
                            clause_id: state.clause_id,
                            clause: text.replaceAll("  ", "&nbsp;&nbsp;"),
                            section_id: state?.section_id,
                            contract_id: state?.contract_id,
                            status: 2,
                            date: date,
                            reason: reason
                        },
                        defination: arr,
                        links: linkArr
                    })
                )
                : checkedValue === "Amendment" ? dispatch(
                    editContentAsync({
                        clause: {
                            clause_id: state.clause_id,
                            clause: text.replaceAll("  ", "&nbsp;&nbsp;"),
                            section_id: state?.section_id,
                            contract_id: state?.contract_id,
                            status: 1,
                            date: date,
                            reason: reason
                        },
                        defination: arr,
                        links: linkArr
                    })
                )
                    : checkedValue === "Deletion" ?
                        dispatch(
                            editContentAsync({
                                clause: {
                                    clause_id: state.clause_id,
                                    clause: text.replaceAll("  ", "&nbsp;&nbsp;"),
                                    section_id: state?.section_id,
                                    contract_id: state?.contract_id,
                                    status: 3,
                                    date: date,
                                    reason: reason
                                },
                                defination: arr,
                                links: linkArr
                            })
                        ) : checkedValue === "Permanent_Delete" ?
                            dispatch(deleteClauseAsync(state?.clause_id))
                            : toast.error("Please select any field")
        }
    };

    useEffect(() => {
        if (error1 == "invalid credential") {
            setLoading(false)
            toast.error("Invalid Credential")
        }
    }, [error1])

    const validateForm = () => {
        let isValid = true;
        const newErrors = [...errors];
        const newLinkErrors = [...linkErrors];
        let quillError = "";
        if (state.status === "Amendment" ? message.replace(/<(.|\n)*?>/g, '').trim().length === 0 : message.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            quillError = "Please fill the clause";
            isValid = false;
        }
        setErrors(newErrors);
        setLinkErrors(newLinkErrors);
        setQuillError(quillError);

        return isValid;
    };
    const isValidURL = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$', 'i');
        return !!pattern.test(url);
    };

    const handleChangeStatus = (e) => {
        setCheckedValue(e.target.value);
    };

    const handleBack = () => {
        setInputFields([{ type: "", value: "" }])
        setMessage("");
        navigate(-1);
    };

    const handleChange = (e) => {
        setMessage(e)
    }
    return (
        <>
            {loading && (
                <div className="custom-spinner-container">
                    <CustomSpinner />
                </div>
            )}
            <Container>
                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                    >
                        <div className="add-clause">
                            <h3>Edit Clause</h3>
                            <div className="heading-outer mb-4">
                                <div className="">
                                    <CustomHeading text={data?.title} level={4} />

                                    {/* <CustomQuill
                                        data={message}
                                        change={(e) =>
                                            handleChange(e)
                                        }
                                    /> */}
                                    <TestCkEditorComponent data={message} change={(e, editor) => setMessage(editor.getData())} />
                                    {quillError && (
                                        <div className="text-danger">{quillError}</div>
                                    )}
                                </div>
                                <div className="custom-checkbox d-flex justify-content-between flex-column">
                                    <form action="" >
                                        <input
                                            placeholder="Addition"
                                            type="checkbox"
                                            id="Addition"
                                            name="Addition"
                                            value="Addition"
                                            onClick={handleChangeStatus}
                                            checked={checkedValue == "Addition" ? true : false}
                                        />
                                        <label>Addition</label>
                                        <input
                                            placeholder="Amendment"
                                            type="checkbox"
                                            id="Amendment"
                                            name="Amendment"
                                            value="Amendment"
                                            onClick={() => setCheckedValue("Amendment")}
                                            checked={checkedValue == "Amendment" ? true : false}
                                        />
                                        <label>Amendment</label>
                                        <input
                                            placeholder="Deletion"
                                            type="checkbox"
                                            id="Deletion"
                                            name="Deletion"
                                            value="Deletion"
                                            onClick={() => setCheckedValue("Deletion")}
                                            checked={checkedValue == "Deletion" ? true : false}
                                        />
                                        <label>Deletion</label>
                                        <input
                                            placeholder="Permanent Delete"
                                            type="checkbox"
                                            id="Permanent_Delete"
                                            name="Permanent_Delete"
                                            value="Permanent_Delete"
                                            onClick={() => setCheckedValue("Permanent_Delete")}
                                            checked={checkedValue == "Permanent_Delete" ? true : false}
                                        />
                                        <label>Permanent Delete</label>
                                    </form>
                                    <div className="mt-3">
                                        {checkedValue ? <Form className="d-flex justify-content-between">
                                            <Form.Group
                                                className="mb-3 w-65"
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <Form.Label>{`Reason for ${checkedValue == "Permanent_Delete" ? "Permanent Delete" : checkedValue}`}</Form.Label>
                                                <Form.Control
                                                    name=""
                                                    type="text"
                                                    placeholder={`Write reason... `}
                                                    value={reason}
                                                    onChange={(e) => setReason(e.target.value)}
                                                />
                                                <Form.Text id="passwordHelpBlock" className="text-danger">{reasonErr}</Form.Text>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-3 w-auto"
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <Form.Label>Date</Form.Label>
                                                <Form.Control
                                                    name="date"
                                                    type="date"
                                                    placeholder="Select Date"
                                                    value={moment(date).format("YYYY-MM-DD")}
                                                    onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                                />
                                            </Form.Group>
                                        </Form> : null}
                                    </div>
                                </div>

                                <div className="custom-definition">
                                    <h5>Definition</h5>

                                    {inputFields?.length > 0 ? inputFields?.map((inputField, index) => (
                                        <>
                                            <div className="defe-input mb-5">
                                                <p>Word</p>
                                                <input
                                                    className="mt-2"
                                                    type="text"
                                                    placeholder="Enter word"
                                                    value={inputField.type}
                                                    onChange={(event) => handleTypeChange(index, event)}
                                                />
                                            </div>
                                            <div className="defe-input">
                                                <p>Definition</p>
                                                <textarea
                                                    className=""
                                                    placeholder={`Enter Definition`}
                                                    value={inputField.value}
                                                    rows={4}
                                                    cols={50}
                                                    onChange={(event) => handleInputChange(index, event)}
                                                />
                                                <div
                                                    title="Delete"
                                                    style={{ width: "53px" }}
                                                    onClick={() => handleRemoveFields(index)}
                                                    className={inputFields?.length == 1 ? "d-none" : ""}
                                                >
                                                    <button className="border border-0 bg-transparent">{Delete}</button>
                                                </div>
                                            </div>
                                            <div className="defe-input-btn" onClick={handleAddFields}>
                                                <button className="border border-0 bg-transparent">{Add}</button>
                                            </div>
                                            {errors[index] && (
                                                <div className="text-danger">{errors[index]}</div>
                                            )}
                                        </>
                                    )) : null}
                                </div>

                                <div className="custom-definition">
                                    <h5>Links To</h5>
                                    {inputLinkFields?.map((inputField, index) => (
                                        <>
                                            <div className="defe-input mb-5">
                                                <p>Word</p>
                                                <input
                                                    type="text"
                                                    placeholder="Enter word"
                                                    value={inputField.type}
                                                    onChange={(event) =>
                                                        handleTypeLinkChange(index, event)
                                                    }
                                                />
                                            </div>
                                            <div className="defe-input">
                                                <p>Link</p>
                                                <textarea
                                                    className="mt-3"
                                                    placeholder={"Enter link"}
                                                    value={inputField.value}
                                                    rows={4}
                                                    cols={50}
                                                    onChange={(event) =>
                                                        handleInputLinkChange(index, event)
                                                    }
                                                />
                                                <div
                                                    title="Delete"
                                                    style={{ width: "53px" }}
                                                    onClick={() => handleRemoveLinkFields(index)}
                                                    className={
                                                        inputLinkFields?.length == 1 ? "d-none" : ""
                                                    }
                                                >
                                                    <button className="border border-0 bg-transparent">{Delete}</button>
                                                </div>
                                            </div>

                                            <div
                                                className="defe-input-btn mb-3"
                                                onClick={handleAddLinkFields}
                                            >
                                                <button className="border border-0 bg-transparent">{Add}</button>
                                            </div>
                                            {linkErrors[index] && (
                                                <div className="text-danger">{linkErrors[index]}</div>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button
                                    className="submit_btn me-2"
                                    onClick={() => {
                                        handleEditClick();
                                    }}
                                >
                                    Submit
                                </button>

                                <button
                                    className="submit_btn ms-2"
                                    onClick={() => {
                                        handleBack();
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >
        </>
    );
};

export default EditClauses;