import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import './ckeditor.scss'

const TestCkEditorComponent = (props: any) => {
    return (
        <div className="ckeditor">
            <CKEditor
                editor={DecoupledEditor}
                data={props.data}
                onReady={(editor: any) => {
                    if (
                        editor.ui.getEditableElement().parentElement.firstChild
                            .classList[1] != "ck-toolbar"
                    ) {
                        editor.ui
                            .getEditableElement()
                            .parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                    }
                }}
                config={{
                    toolbar: ['heading', '|',
                        'bold', 'italic', 'Underline', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', 'Strikethrough', 'blockQuote', 'alignment', '| ',
                        'bulletedList', 'numberedList', '|',
                        'link', '|',
                        'undo', 'redo']
                }}
                onChange={props.change}
            // onBlur={(event, editor) => {
            //     console.log("Blur.", editor);
            // }}
            // onFocus={(event, editor) => {
            //     console.log("Focus.", editor);
            // }}
            />
        </div>
    );
};

export default TestCkEditorComponent;
